import React from 'react';

class Checkbox extends React.Component {
    render() {
        const {label, checked, onChange, checkedHelpText, uncheckedHelpText, error, onText, offText} = this.props;

        return (
            <div className={`c-input checkbox-container${error ? ' error': ''}`}>
                <label>
                    <input type="checkbox" checked={checked} onChange={onChange}/>
                    {label}&nbsp;{checked ? (onText ? onText : '') : (offText ? offText : '')}
                </label>
                <div className="help-text">
                    {checked ? checkedHelpText : uncheckedHelpText}
                </div>
            </div>
        )
    }
}

export default Checkbox;
