import React from 'react';
import {generatePath, Link} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {ROUTE_PUBLICATIONS_DIRECT_ITEM} from "../../../config/routes";

const NewPublicationButton = ({companyId, itemId, className = ''}) => {
  return companyId ? (
    <Link to={generatePath(ROUTE_PUBLICATIONS_DIRECT_ITEM, {companyId: companyId, publicationItemId: itemId })} className={`NewPublicationButton ${className}`}>
      <span className="d-lg-none">
        <Translate id="meeting.newPublication.button"/>
      </span>
      <span className="d-none d-lg-inline">
        <Translate id="meeting.newPublication.buttonLong"/>
      </span>
    </Link>
  ) : null
}

export default NewPublicationButton;
