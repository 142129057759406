import authorizationReducer from "./authorization/authorizationReducer";
import eventsReducer from "./events/eventsReducer";
import {combineReducers} from "redux";
import profileReducer from "./profile/profileReducer";
import publicationsReducer from "./publications/publicationsReducer";
import publicationsTickerReducer from "./publications/publicationsTickerReducer";
import {localizeReducer} from "react-localize-redux";

const montegaConnect = combineReducers({
    authorization: authorizationReducer,
    events: eventsReducer,
    profile: profileReducer,
    publications: publicationsReducer,
    publicationsTicker: publicationsTickerReducer,
    localize: localizeReducer
});

export default function (state, action) {
    return montegaConnect(state, action);
}
