import montegaConnect from './store/rootReducer';
import persistState from 'redux-localstorage';
import {compose, createStore} from "redux";

const enhancer = compose(
    persistState(['authorization', 'localize'], {key: 'montega-connect'})
);

const store = createStore(montegaConnect, enhancer);
export {store};
