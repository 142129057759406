import React, {Fragment} from 'react';
import moment from 'moment';
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import iconTeilnehmer from "../../../gfx/teilnehmer.svg";
import iconTeilnehmerWhite from "../../../gfx/teilnehmer-white.svg";
import iconTeilnehmerTurkuoise from "../../../gfx/teilnehmer-turquoise.svg";
import iconUhrzeit from "../../../gfx/uhrzeit.svg";
import iconUhrzeitWhite from "../../../gfx/uhrzeit-white.svg";
import iconUhrzeitTurkuoise from "../../../gfx/uhrzeit-turquoise.svg";
import iconPosition from "../../../gfx/position.svg";
import iconPositionWhite from "../../../gfx/position-white.svg";
import iconPositionTurkuoise from "../../../gfx/position-turquoise.svg";
import iconGruppentermin from "../../../gfx/gruppentermin.svg";
import iconGruppenterminWhite from "../../../gfx/gruppentermin-white.svg";
import iconGruppenterminTurkuoise from "../../../gfx/gruppentermin-turquoise.svg";
import iconEinzeltermin from "../../../gfx/einzeltermin.svg";
import iconEinzelterminWhite from "../../../gfx/einzeltermin-white.svg";
import iconEinzelterminTurkuoise from "../../../gfx/einzeltermin-turquoise.svg";
import iconWebWhite from "../../../gfx/web_icon_white.svg";
import iconArrow from "../../../gfx/arrow-white.svg";
import iconAuswahlTurquoise from "../../../gfx/auswahl-turquoise.svg";
import iconAuswahlWhite from "../../../gfx/auswahl-white.svg";
import ParticipateButton from "./ParticipateButton";
import CancelButton from "./CancelButton";
import JoinButton from "./JoinButton";
import fileDownload from "js-file-download";
import ReactTooltip from "react-tooltip";
import InfoButton from "./InfoButton";
import FilesButton from "./FilesButton";
import EventsApi from "../../../api/EventsApi";
import NewPublicationButton from "./NewPublicationButton";
import QuestionButton from "./QuestionButton";
import Calendar from "../../../gfx/Calendar";

class Meeting extends React.Component {
    state = {
        toggleDetails: false,
        toggleAlternatives: false,
        activeAlternatives: 0,
        icsDownloadLoading: false
    }

    downloadIcs = () => {
        const {event} = this.props;
        this.setState({icsDownloadLoading: true});
        EventsApi.getIcs(event.appId, event.itemId)
            .then(response => {
                this.setState({icsDownloadLoading: false});
                if(response.status === 200) {
                    fileDownload(response.data, "event_" + event.itemId + ".ics", 'text/calendar');
                }
            });
    }

    toggleAlternatives = (aSI) => {
        this.setState({
            toggleAlternatives: !this.state.toggleAlternatives,
            activeAlternatives: aSI
        })
    }

    render() {
        const {hideDay, event} = this.props;
        let setBtnParticipate = true;

        const needsRequest = false;
        const isActive = (moment(event.date.start) <= moment() && moment(event.date.end) > moment());

        if (event.userIsParticipating || event.userIsApplying || (event.closed && !['roadshow', 'roundtable', 'fieldTrip', 'earningsCall'].includes(event.type))) setBtnParticipate = false;

        let setBtnEdit = event.type === 'conference' && event.userIsApplying;

        return (
            <div
                className={`Meeting ${isActive ? 'is-active' : ''} ${event.userIsParticipating ? 'is-participating' : ''} 
                 ${event.userIsDenied ? 'got-canceled' : ''} ${event.userIsApplying ? 'is-requested' : ''}
                 ${this.state.toggleDetails ? 'open' : ''}`}>
                <div className="container">
                    <div className="row flex-nowrap">
                        <div className="col-left col-2 col-md-1">
                            {event && event.recentPublication ? (
                              <NewPublicationButton companyId={event.recentPublication.companyId} itemId={event.recentPublication.itemId} className="d-lg-none"/>
                            ) : null}
                            <div className="day">
                                {!hideDay &&
                                <Fragment>
                                    <div className="top">
                                        {moment(event.date.start).format('dddd')}
                                    </div>
                                    <div className="middle">{moment(event.date.start).format('DD')}
                                        <BorderWithDot borderClass="border-with-dot-left border-with-dot-black"/>
                                    </div>
                                    <div className="bottom">
                                        {moment(event.date.start).format('MMMM')}
                                    </div>
                                </Fragment>
                                }
                            </div>
                            <div className="info-box">
                                {isActive &&
                                <Fragment>
                                    <span className="text-is-active text-uppercase d-flex">
                                        <img src={iconUhrzeitWhite} alt=""/>
                                        <Translate id="meeting.status.isActive"/>
                                    </span>
                                </Fragment>
                                }
                                {event.userIsParticipating &&
                                <Fragment>
                                    <span>
                                        <Translate id="meeting.status.userIsParticipating"/>
                                    </span>
                                    <JoinButton className="btn-infobox btn-dial d-none d-lg-flex" event={event}/>
                                    {!isActive &&
                                    <CancelButton className="btn-infobox btn-cancel" event={event}>
                                        <Translate id="meeting.action.cancel"/>
                                    </CancelButton>
                                    }
                                </Fragment>
                                }
                                {event.userIsApplying &&
                                <Fragment>
                                    <span>
                                        <Translate id="meeting.status.userIsApplying"/>
                                    </span>
                                    <CancelButton className="btn-infobox btn-refuse" event={event}>
                                        <Translate id="meeting.action.refuse"/>
                                    </CancelButton>
                                </Fragment>
                                }
                                {(event.userIsDenied && event.type !== 'roadshow') &&
                                <Fragment>
                                    <span>
                                        <Translate id="meeting.status.slotDenied"/>
                                    </span>
                                </Fragment>
                                }
                            </div>
                        </div>
                        <div className="col-container col-4 col-md-5 col-lg-4 pl-0">
                            <div className="meeting-start d-md-none">
                                {event && event.date && event.date.start ? (
                                    <span>{moment(event.date.start).format('LT')}</span>
                                ) : null}
                            </div>
                            <div className="container-card">
                                <div className="col-middle col-middle-width">
                                    {event && event.recentPublication ? (
                                      <NewPublicationButton companyId={event.recentPublication.companyId} itemId={event.recentPublication.itemId} className="d-none d-lg-flex"/>
                                    ) : null}
                                    <div className="type">
                                        {event.online ? (
                                            <img alt="World-Icon" src={iconWebWhite}/>
                                        ) : null}
                                        <h3><Translate id={`meeting.type.${event.type}`}/></h3>
                                    </div>
                                    <div className="logo-container d-flex d-md-flex">
                                        {event.company ? (
                                            event.company.logo && event.company.logo.file && event.company.logo.file.id ? (
                                                <img
                                                    src={`${process.env.REACT_APP_API_URL}image/${event.company.logo.file.id}`}
                                                    alt=""/>
                                            ) : (
                                                <div className="companyName">
                                                    {event.company.name}
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-right d-flex flex-wrap">
                                    <BorderWithDot borderClass="border-with-dot-turkuoiseborder d-none d-md-flex"/>
                                    <div className="title-container">
                                        <div className="title">
                                            <h3>{event.title}</h3>
                                        </div>
                                        {event.allowQuestions && (
                                            <QuestionButton event={event} className="d-none d-md-inline-block"/>
                                        )}
                                        {event.teaser && (
                                            <InfoButton event={event}/>
                                        )}
                                    </div>
                                    <div className={`details`}>
                                        <div className="description text-uppercase d-flex flex-column flex-lg-row">
                                            {event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? (
                                                <span className="mySelection">
                                                    <img className="icon d-md-none" src={iconAuswahlTurquoise} alt=""/>
                                                    {isActive ? (
                                                        <img className="icon d-none d-md-inline-block"
                                                             src={iconAuswahlWhite} alt=""/>
                                                    ) : (
                                                        <img className="icon d-none d-md-inline-block"
                                                             src={iconAuswahlTurquoise} alt=""/>
                                                    )}
                                                    <Translate id={`meeting.mySelection.${event?.slots?.find(slot => (slot.userIsParticipating || slot.userIsApplying))?.groupMeeting ? 'group' : 'single'}`}/>
                                                </span>
                                            ) : (
                                                <Fragment>
                                                    {event.hasGroupmeetings ? (
                                                        <span className="d-flex align-items-center mr-lg-4">
                                                    <img className="icon d-md-none" src={iconGruppentermin} alt=""/>
                                                            {isActive ? (
                                                                <img className="icon d-none d-md-inline-block"
                                                                     src={iconGruppenterminWhite} alt=""/>
                                                            ) : event.closed || event.userIsParticipating ? (
                                                                <img className="icon d-none d-md-inline-block"
                                                                     src={iconGruppenterminTurkuoise} alt=""/>
                                                            ) : (
                                                                <img className="icon d-none d-md-inline-block"
                                                                     src={iconGruppentermin} alt=""/>
                                                            )}
                                                            <Translate id="meeting.groupMeetings"/>
                                                </span>
                                                    ) : null}
                                                    {event.hasOneOnOne ? (
                                                        <span>
                                                    <img className="icon d-md-none" src={iconEinzeltermin} alt=""/>
                                                            {isActive ? (
                                                                <img className="icon d-none d-md-inline-block"
                                                                     src={iconEinzelterminWhite} alt=""/>
                                                            ) : event.closed || event.userIsParticipating ? (
                                                                <img className="icon d-none d-md-inline-block"
                                                                     src={iconEinzelterminTurkuoise} alt=""/>
                                                            ) : (
                                                                <img className="icon d-none d-md-inline-block"
                                                                     src={iconEinzeltermin} alt=""/>
                                                            )}
                                                            <Translate id="meeting.oneOnOns"/>
                                                </span>
                                                    ) : null}
                                                </Fragment>
                                            )}
                                        </div>
                                        <div className="box d-flex flex-column flex-md-row flex-wrap">
                                            <span className="date mr-md-4"
                                                  data-tip={event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? 'React-tooltip' : false}
                                                  data-for={event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? `meeting-date-tooltip-${event.itemId}` : null}>
                                                <img className="icon d-md-none" src={iconUhrzeit} alt=""/>
                                                {isActive ? (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconUhrzeitWhite} alt=""/>
                                                ) : event.closed || event.userIsParticipating ? (
                                                    <img className="icon d-none d-md-inline-block"
                                                         src={iconUhrzeitTurkuoise} alt=""/>
                                                ) : (
                                                    <img className="icon d-none d-md-inline-block" src={iconUhrzeit}
                                                         alt=""/>
                                                )}
                                                <Translate
                                                    id="meeting.from"/> {moment(event.date.start).format('LT')} <br/>
                                                <Translate id="meeting.until"/> {moment(event.date.end).format('LT')}
                                                {event.type === 'roadshow' && (event.userIsApplying || event.userIsParticipating) ? (
                                                    <ReactTooltip id={`meeting-date-tooltip-${event.itemId}`}>
                                                        <Translate id="meeting.date.tooltip.roadshow"/>
                                                    </ReactTooltip>
                                                ) : null}
                                                {event.language ? (
                                                    <span className={`ml-auto d-flex d-md-none event-language fi fi-${event.language === 'en' ? 'gb' : 'de'} fis`}/>
                                                ) : null}
                                            </span>
                                            {(event.location && event.location.formatted) || event.online ? (
                                                <span className="location">
                                                <img className="icon d-md-none" src={iconPosition} alt=""/>
                                                    {isActive ? (
                                                        <img className="icon d-none d-md-inline-block"
                                                             src={iconPositionWhite} alt=""/>
                                                    ) : event.closed || event.userIsParticipating ? (
                                                        <img className="icon d-none d-md-inline-block"
                                                             src={iconPositionTurkuoise} alt=""/>
                                                    ) : (
                                                        <img className="icon d-none d-md-inline-block"
                                                             src={iconPosition}
                                                             alt=""/>
                                                    )}
                                                    {event.online ? (
                                                        <Translate id="meeting.location.online"/>
                                                    ) : event.location && event.location.formatted ? (
                                                        event.location.formatted
                                                    ) : null}
                                                    {event.language ? (
                                                        <span className={`ml-4 ml-xl-0 d-none d-lg-flex event-language fi fi-${event.language === 'en' ? 'gb' : 'de'} fis`}/>
                                                    ) : null}
                                            </span>
                                            ) : null}
                                            <span className="representatives">
                                                {event.representatives ? (
                                                    <Fragment>
                                                        <img className="icon d-md-none" src={iconTeilnehmer}
                                                             alt=""/>
                                                        {isActive ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconTeilnehmerWhite} alt=""/>
                                                        ) : event.closed || event.userIsParticipating ? (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconTeilnehmerTurkuoise} alt=""/>
                                                        ) : (
                                                            <img className="icon d-none d-md-inline-block"
                                                                 src={iconTeilnehmer} alt=""/>
                                                        )}
                                                        {event.representatives}
                                                    </Fragment>
                                                ) : null}
                                                {event.language ? (
                                                    <span className={`ml-4 d-none d-md-flex d-lg-none event-language fi fi-${event.language === 'en' ? 'gb' : 'de'} fis`}/>
                                                ) : null}
                                            </span>
                                            {event.userIsParticipating || event.allowQuestions || event.teaser ? (
                                                <span className="mobile-button-icons d-md-none">
                                                    {event.userIsParticipating && (
                                                        <button onClick={this.downloadIcs} className={`btn-calendar-sm d-inline-block d-md-none`} disabled={this.state.icsDownloadLoading}>
                                                            <Calendar/>
                                                        </button>
                                                    )}
                                                    {event.allowQuestions && (
                                                        <QuestionButton event={event}/>
                                                    )}
                                                    {event.teaser && (
                                                        <InfoButton event={event}/>
                                                    )}
                                                </span>
                                            ) : null}
                                            {((event.files && event.files.length > 0) || event.teaser) && (
                                                <div className="d-md-none mobile-details-bottom">
                                                    <div className="files">
                                                        {event.files && event.files.length > 0 && <FilesButton event={event}/>}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <BorderWithDot borderClass="border-with-dot-turkuoise bwd-bottom d-none d-md-flex"/>
                                    <div className="d-none d-md-block d-lg-none files-container-md">
                                        {event.files && event.files.length > 0 && <FilesButton event={event}/>}
                                    </div>
                                </div>
                                <button onClick={this.downloadIcs} className={`btn-connect btn-calendar d-none ${event.userIsParticipating ? 'd-md-inline-block' : 'd-none'}${this.state.icsDownloadLoading ? ' loading': ''}`} disabled={this.state.icsDownloadLoading}>
                                    <div className="d-none d-lg-inline"><Translate id="meeting.calendarLong"/></div>
                                    <div className="d-inline d-lg-none"><Translate id="meeting.calendar"/></div>
                                </button>
                                <ParticipateButton event={event}
                                                   className={`btn-connect btn-participate d-none ${(setBtnParticipate || setBtnEdit) ? 'd-lg-inline-block' : ''}`}>
                                    {setBtnEdit ? (
                                        <Translate id="meeting.action.edit"/>
                                    ) : (
                                        needsRequest ?
                                            <Translate id="meeting.action.askParticipateLong"/>
                                            :
                                            <Translate id="meeting.action.participate"/>
                                    )}

                                </ParticipateButton>
                                <div className="mobileButtons-container col-middle-width d-lg-none">
                                    {(setBtnParticipate || setBtnEdit) &&
                                    <ParticipateButton className="btn-participate" event={event}>
                                        {setBtnEdit ? (
                                            <Translate id="meeting.action.edit"/>
                                        ) : (
                                            needsRequest ?
                                                <Translate id="meeting.action.askParticipate"/>
                                                :
                                                <Translate id="meeting.action.participate"/>
                                        )}
                                    </ParticipateButton>
                                    }
                                    {event.userIsParticipating ? (
                                        <Fragment>
                                            <JoinButton className="btn-dial" event={event}/>
                                            {!isActive ? (
                                                <CancelButton className="btn-cancel d-none d-md-inline-block d-lg-none"
                                                              event={event}>
                                                    <Translate id="meeting.action.cancel"/>
                                                </CancelButton>
                                            ) : null}
                                        </Fragment>
                                    ) : null}
                                    {event.userIsApplying &&
                                    <CancelButton className="btn-refuse d-none d-md-inline-block d-lg-none"
                                                  event={event}>
                                        <Translate id="meeting.action.refuse"/>
                                    </CancelButton>
                                    }
                                    <button onClick={() => this.setState({toggleDetails: !this.state.toggleDetails})}
                                            className="btn-details d-md-none">
                                        <img src={iconArrow} className={this.state.toggleDetails ? 'rotate' : ''}
                                             alt=""/>
                                        <Translate id="meeting.details"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 d-none d-lg-flex pl-0 files-container-lg">
                            <div>
                                {event.files && event.files.length > 0 && <FilesButton event={event}/>}
                            </div>
                        </div>
                    </div>
                </div>
                {event.userIsDenied ? (
                    <div className="container">
                        <div className="row row-userIsDenied">
                            <div
                                className={`col-6 ${!event.alternativeSlots || event.alternativeSlots.length === 0 ? `` : `col-alternatives`}`}>
                                {!event.alternativeSlots || event.alternativeSlots.length === 0 ? (
                                    <h3><Translate id="meeting.userIsDenied.noAlternativeSlotsYet"/></h3>
                                ) : (
                                    <Fragment>
                                        <h3><Translate id="meeting.userIsDenied.selectAlternativeSlot"/></h3>
                                        <div className={`alternatives ${this.state.toggleAlternatives ? `open` : ``}`}>
                                            {event.alternativeSlots.sort((a, b) => moment(a.date.start).isBefore(moment(b.date.start)) ? -1 : 1).map((alternativeSlot, aSI) => (
                                                <div
                                                    className={`slot ${this.state.activeAlternatives === aSI ? `active` : ``}`}
                                                    key={aSI}>
                                                    <h5 className="type">
                                                        <Translate
                                                            id={`meeting.${alternativeSlot.groupMeeting ? 'groupMeeting' : 'oneOnOn'}`}/>
                                                    </h5>
                                                    <div className="time" onClick={() => this.toggleAlternatives(aSI)}>
                                                        <img className="icon"
                                                             src={iconUhrzeitTurkuoise} alt=""/>
                                                        <div className="timetext">
                                                            <div className="left"><Translate id="meeting.from"/></div>
                                                            <div
                                                                className="right">{moment(alternativeSlot.date.start).format('LT')}</div>
                                                            <div className="left"><Translate id="meeting.until"/></div>
                                                            <div
                                                                className="right">{moment(alternativeSlot.date.end).format('LT')}</div>
                                                        </div>
                                                    </div>
                                                    <ParticipateButton className="btn-participate" event={event}
                                                                       isCounterProposal appId={alternativeSlot.appId}
                                                                       itemId={alternativeSlot.itemId}>
                                                        <Translate id="meeting.userIsDenied.confirmAlternative"/>
                                                    </ParticipateButton>
                                                </div>
                                            ))}
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default Meeting;
