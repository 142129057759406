import React, {useState} from 'react';
import BorderWithDot from "../../../elements/border/borderWithDot";
import {Collapse} from "react-collapse/lib/Collapse";
import gfx_arrow from '../../../gfx/publications/arrow.svg';
import {Translate} from "react-localize-redux";
import PublicationItemsButton from "./PublicationItemsButton";
import moment from "moment";
import AnalystButton from "./AnalystButton";
import UpcomingEventButton from "./UpcomingEventButton";

const Publication = ({publication, hideChar = false}) => {

  const [showDetails, setShowDetails] = useState(false);
  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  return (
    <div className={`Publication${showDetails ? ' showDetails' : ''} company-${publication.company?.id}`} id={!hideChar ? `char-${numbers.includes(publication.company?.name?.substr(0, 1)) ? '#' : publication.company?.name?.substr(0, 1)?.toUpperCase()}` : ''}>
      <div className="container">
        <div className="row">
          <div className="col-2 col-md-1 col-char">
            {!hideChar && <div className="char">
              <h2>
                {numbers.includes(publication.company?.name?.substr(0, 1)) ? '#' : publication.company?.name?.substr(0, 1)?.toUpperCase()}
              </h2>
              <BorderWithDot borderClass="border-with-dot-left"/>
            </div>}
            <UpcomingEventButton publication={publication} className="d-lg-none"/>
          </div>
          <div className="col-4 col-md-5 col-lg-4 pl-0 d-flex">
            <div className="col-left">
              <UpcomingEventButton publication={publication} className="d-none d-lg-flex"/>
              <div className={`sector d-flex align-items-center justify-content-center${publication.company?.sector?.map(s => s.name)?.join(', ')?.length > 20 ? ' very-small' : ''}`}>
                {publication.company?.sector?.map(s => s.name)?.join(', ')}
              </div>
              <Collapse isOpened={true}>
                <div className={`company-publication-info-container ${showDetails ? 'd-none' : 'd-flex'} d-md-flex`}>
                  <div className="company-publication-info-container-inner flex-grow-1">
                    {publication.company ? (
                      publication.company.logo && publication.company.logo.file && publication.company.logo.file.id ? (
                        <img
                          src={`${process.env.REACT_APP_API_URL}image/${publication.company.logo.file.id}`}
                          alt="" className="company-logo"/>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className={`description ${showDetails ? 'd-block' : 'd-none'} d-md-none`}>
                  {publication.company?.name}
                  <div className="company-info">
                    <p><Translate id="publication.marketCap"/>: {publication.company?.marketCap?.formatted}</p>
                    <p><Translate id="publication.latestPublication"/>: {moment(publication.latestPublication)?.format('LLL')}</p>
                  </div>
                  <div className="content-buttons">
                    <div className="button-container">
                      <PublicationItemsButton publication={publication}/>
                    </div>
                  </div>
                </div>
              </Collapse>
              <div className="bottom-buttons d-lg-none">
                {publication.analysts && (
                  <AnalystButton analysts={publication.analysts} extraClassName="d-md-none"/>
                )}
                <PublicationItemsButton publication={publication} extraClassName="d-none d-md-block"/>
                <button className="details-toggle d-md-none" onClick={() => setShowDetails(!showDetails)}>
                  <img src={gfx_arrow} alt="" className={showDetails ? 'rotate-180' : ''}/>
                  <Translate id={`publication.${showDetails ? 'hideDetails' : 'showDetails'}`}/>
                </button>
              </div>
            </div>
            <div className="d-none col-description-right d-md-block">
              <div className="description d-flex flex-column">
                {/*<UpcomingEventButton publication={publication} className="d-none d-md-flex d-lg-none"/>*/}
                <div className="description-border-bottom d-none d-lg-block"/>
                <PublicationItemsButton publication={publication} className="PublicationItemsButton PublicationItemsButton-bottom-right d-none d-lg-flex" isMain={true}/>
                <BorderWithDot borderClass="border-with-dot-darkturkuoise border-with-dot-top"/>
                <div className="title flex-grow-1">
                  {publication.company?.name}
                </div>
                <div className="company-info">
                  <p><Translate id="publication.marketCap"/>: {publication.company?.marketCap?.formatted}</p>
                  <p><Translate id="publication.latestPublication"/>: {moment(publication.latestPublication)?.format('LLL')}</p>
                </div>
                <div className="content-buttons d-flex">
                  {publication.analysts && (
                    <AnalystButton analysts={publication.analysts} withIcon={true}/>
                  )}
                </div>
                <BorderWithDot borderClass="border-with-dot-darkturkuoise d-lg-none"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Publication;
