import axios from 'axios';

class PublicationsApi {
  static list = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}publication/list`)
  };

  static ticker = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}publication/ticker`)
  };

  static getItems = (companyId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}publication/list/${companyId}`)
  }

  static getWebView = (token, fileLanguage) => {
    return axios.get(`${process.env.REACT_APP_API_URL}publication/web/${token}?fileLanguage=${fileLanguage}`)
  }
}

export default PublicationsApi;
