import React from 'react';
import {Link} from "react-router-dom";
import {ROUTE_FORGOT_PASSWORD} from "../../../config/routes";
import {Translate, withLocalize} from "react-localize-redux";
import TextInput from "../../../elements/form/TextInput";
import SubmitButton from "../../../elements/form/SubmitButton";
import GeneralError from "../../../elements/form/GeneralError";
import logoConnect from "../../../gfx/logo_connect_white.svg";
import AuthorizationApi from "../../../api/AuthorizationApi";
import {connect} from "react-redux";
import {authorizationLoginSuccess} from "../../../store/authorization/authorizationAction";
import Checkbox from "../../../elements/form/Checkbox";

class Login extends React.Component {

    state = {
        username: '',
        password: '',
        rememberMe: false,
        loginError: false
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        AuthorizationApi.login(this.state.username, this.state.password)
            .then((response) => {
                if (response.status === 200) {
                    let {access_token, username, roles, refresh_token, lang} = response.data;
                    if (roles?.includes('ROLE_API')) {
                        if (lang) this.props.setActiveLanguage(lang);
                        this.props.authorizationLoginSuccess(access_token, username, roles, this.state.rememberMe ? refresh_token : null);
                    } else {
                        this.setState({loginError: true});
                    }
                } else {
                    this.setState({loginError: true});
                }
            })
            .catch(() => {
                this.setState({loginError: true});
            });
    };

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <div className="Login">
                        <img alt="" className="logo" src={logoConnect}/>
                        <Link to={ROUTE_FORGOT_PASSWORD} className="forgot-password-link d-inline-block">
                            <Translate id="login.forgotPassword"/>
                        </Link>
                        <form onSubmit={this.handleFormSubmit}>
                            <TextInput placeholder={translate("login.username")} value={this.state.username}
                                       autoFocus
                                       onChange={(e) => this.setState({username: e.target.value})}/>
                            <TextInput type="password" placeholder={translate("login.password")}
                                       value={this.state.password}
                                       onChange={(e) => this.setState({password: e.target.value})}/>
                            <div className="btn-container d-flex">
                                <div className="login-checkbox-container">
                                    <input type="checkbox" name="rememberMe" id="cb-rememberMe" checked={this.state.rememberMe} onChange={(e) => this.setState({rememberMe: e.target.checked})}/>
                                    <label htmlFor="cb-rememberMe">
                                        <Translate id="login.rememberMe"/>
                                    </label>
                                </div>
                                <SubmitButton>
                                    <Translate id="login.submit"/>
                                </SubmitButton>
                            </div>
                            {this.state.loginError ? (
                              <GeneralError
                                errorMessage={translate("login.error")}/>
                            ) : null}
                        </form>
                    </div>
                )}
            </Translate>
        )
    }
}

export default connect(null, {
    authorizationLoginSuccess
})(withLocalize(Login));
