import React, {Fragment} from 'react';
import Unauthorized from "./unauthorized";
import Legals from "./legals";
import FooterMenu from "../elements/footerMenu";
import {
    ROUTE_DATA_PRIVACY,
    ROUTE_DEPRECATED_LANDING_PAGE, ROUTE_ERROR,
    ROUTE_IMPRINT,
    ROUTE_INVITATION_LOGIN,
    ROUTE_LANDING_PAGE,
    ROUTE_PUBLICATION_LOGIN,
    ROUTE_PUBLICATION_WEB_VIEW,
    ROUTE_TOKEN_LOGIN,
    ROUTE_UNSUBSCRIBE_LOGIN
} from "../config/routes";
import {matchPath, Route, Switch, withRouter} from "react-router";
import globalTranslations from "../translations/global";
import {withLocalize} from "react-localize-redux";
import {renderToStaticMarkup} from "react-dom/server";
import '../theme/theme.scss';
import TopBar from "../elements/topBar";
import {connect} from "react-redux";
import Authorized from "./authorized";
import AxiosHelper from "../config/axiosHelper";
import moment from 'moment';
import 'moment/locale/de';
import Landing from "./landing";
import TokenLogin from "./unauthorized/tokenLogin";
import InvitationLogin from "./unauthorized/invitationLogin";
import PublicationLogin from "./unauthorized/publicationLogin";
import UnsubscribeLogin from "./unauthorized/unsubscribeLogin";
import {IntlProvider} from "react-intl";
import Error from "./error";

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

const longDateFormats = {
    de: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        LL: 'DD.MM.YY',
        LLL: 'DD.MM.YYYY'
    },
    en: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        LL: 'DD/MM/YY',
        LLL: 'DD/MM/YYYY'
    }
};

class App extends React.Component {

    constructor(props) {
        super(props);

        let languageFromStore = this.props.localize?.languages?.find(l => l.active)?.code;
        let userLang = navigator.language || navigator.userLanguage;
        let defaultLanguage = languageFromStore ? languageFromStore : (userLang === 'de-DE' ? 'de' : 'en');

        this.props.initialize({
            languages: [
                {name: "German", code: "de"},
                {name: "English", code: "en"}
            ],
            translations: globalTranslations,
            options: {renderToStaticMarkup, onMissingTranslation, defaultLanguage: defaultLanguage},
        });
        this.props.addTranslation(globalTranslations);
        AxiosHelper.init();
        moment.locale(defaultLanguage, {
            longDateFormat: longDateFormats[defaultLanguage]
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeLanguage?.code !== this.props.activeLanguage?.code) {
            moment.locale(this.props.activeLanguage?.code, {
                longDateFormat: longDateFormats[this.props.activeLanguage?.code]
            });
        }
    }

    isLegalsPage = () => {
        return [ROUTE_DATA_PRIVACY, ROUTE_IMPRINT].includes(this.props.location.pathname);
    };

    isLandingPage = () => {
        return [ROUTE_LANDING_PAGE].find(el => this.props.location.pathname.startsWith(el.replace(':token', ''))) || [ROUTE_DEPRECATED_LANDING_PAGE].find(el => this.props.location.pathname.startsWith(el.replace(':token', '')));
    }

    isPublicationWebView = () => {
        return matchPath(this.props.location.pathname, {
            path: ROUTE_PUBLICATION_WEB_VIEW,
            exact: true,
            strict: false
        })
    }

    render() {

        const {authorization} = this.props;

        const authorized = !!authorization.bearerToken;
        const legalsPage = this.isLegalsPage();
        const publicationWebView = this.isPublicationWebView();
        const landingPage = this.isLandingPage();

        return (
            <IntlProvider locale={this.props.activeLanguage?.code ?? 'en'}>
                <div className="App">
                    <Switch>
                        <Route exact path={ROUTE_ERROR} component={Error}/>
                        <Route exact path={ROUTE_TOKEN_LOGIN} component={TokenLogin}/>
                        <Route exact path={ROUTE_INVITATION_LOGIN} component={InvitationLogin}/>
                        <Route exact path={ROUTE_PUBLICATION_LOGIN} component={PublicationLogin}/>
                        <Route exact path={ROUTE_UNSUBSCRIBE_LOGIN} component={UnsubscribeLogin}/>
                        <Route render={() => (
                            <Fragment>
                                {!publicationWebView && (legalsPage || authorized) ? (
                                    <TopBar/>
                                ) : null}
                                {legalsPage ? (
                                    <Legals/>
                                ) : landingPage ? (
                                    <Landing/>
                                ) : (
                                    authorized ? (
                                        <Authorized/>
                                    ) : (
                                        <Unauthorized/>
                                    )
                                )}
                            </Fragment>
                        )}/>
                    </Switch>
                    <FooterMenu/>
                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization,
    localize: state.localize
});

export default withLocalize(connect(mapStateToProps, null)(withRouter(App)));
