import React from 'react';
import {withLocalize} from "react-localize-redux";

const HeaderBar = ({ logo, extraClassName, languages, activeLanguage, setActiveLanguage }) => {
    return (
        <div className={`HeaderBar ${extraClassName}`}>
            <div className="row">
                <div className="col mb-3">
                    <img src={`${process.env.REACT_APP_API_URL}landingPage/logo?uuid=${logo}`} alt="" className="logo"/>
                </div>
                <div className="col-auto">
                    <div className="language-toggle">
                        {languages.map((lang, lI) => (
                            <button key={lI} className={`${lang.code === activeLanguage.code ? ' active' : ''}`} onClick={() => setActiveLanguage(lang.code)}>
                                {lang.code}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withLocalize(HeaderBar);