import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {store} from '../store';
import {authorizationLogout, authorizationSetAccessToken} from "../store/authorization/authorizationAction";
import history from "../history";
import AuthorizationApi from "../api/AuthorizationApi";
import {ROUTE_ERROR} from "./routes";

class AxiosHelper {
    static init() {
        axios.interceptors.request.use((config) => {
            let token = store.getState().authorization.bearerToken;
            let activeLanguageCode = store.getState().localize?.languages?.find(l => l.active)?.code;

            if (token) {
                config.headers.common['Authorization'] = `Bearer ${token}`
            }
            if (activeLanguageCode) {
                if (!config.params?.lang) config.params = {...config.params, lang: activeLanguageCode}
                if (!config.data?.lang) config.data = {...config.data, lang: activeLanguageCode}
            }
            config.validateStatus = function (status) {
                return (status >= 200 && status < 300) || status === 404
            };
            return config;
        });

        axios.interceptors.response.use(null, function (error) {
            if (error.response.status !== 401) {
                history.push(ROUTE_ERROR);
            }
            return Promise.reject(error);
        });

        const refreshAuthLogic = failedRequest => {
            let refreshToken = store.getState().authorization.refreshToken;
            if (refreshToken && failedRequest.response.config.headers) {
                return AuthorizationApi.refreshToken(refreshToken).then(result => {
                    if (result.status === 200) {
                        let bearerToken = result.data.access_token;
                        if (bearerToken) {
                            store.dispatch(authorizationSetAccessToken(bearerToken));
                            failedRequest.response.config.headers['Authorization'] = `Bearer ${bearerToken}`;
                            return Promise.resolve();
                        }
                    } else {
                        store.dispatch(authorizationLogout());
                        history.push('/');
                        return Promise.reject();
                    }
                }).catch(e => {
                    console.log('could not refresh access token');
                    store.dispatch(authorizationLogout());
                    history.push('/');
                    return Promise.reject();
                });
            } else {
                store.dispatch(authorizationLogout());
                history.push('/');
                return Promise.reject();
            }

        }

        createAuthRefreshInterceptor(axios, refreshAuthLogic);
    }
}

export default AxiosHelper;
