import React, {Fragment, useState} from 'react';
import {Translate} from "react-localize-redux";
import gfx_analyst from "../../../gfx/publications/analyst.svg";
import BorderWithDot from "../../../elements/border/borderWithDot";
import gfx_dummy from "../../../gfx/Dummy.jpg"
import {Overlay} from "react-portal-overlay";

const AnalystButton = ({analysts, extraClassName, withIcon = false}) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Fragment>
      <button type="button" className={`AnalystButton-button ${extraClassName}`} onClick={handleOpen}>
        {withIcon ? (<img src={gfx_analyst} alt=""/>) : null}
        <Translate id="publication.analyst.button"/>
      </button>
      <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
        <div className="AnalystButton-dialog-outer">
          <div className="AnalystButton-dialog">
            <button onClick={handleClose}>
              <Translate id="publication.analyst.back"/>
            </button>
            <div className="AnalystButton-dialog-content">
              <div className="title-bar">
                <div className="subtitle">
                  <Translate id="publication.analyst.title"/>
                </div>
                <BorderWithDot/>
              </div>
              {analysts.map((analyst, aI) => (
                <div className="main-bar" key={aI}>
                  <div className="image">
                    <img
                        src={analyst.image?.file ? `${process.env.REACT_APP_API_URL}image/${analyst.image.file.id}` : gfx_dummy}
                        alt=""/>
                  </div>
                  <div className="contact">
                    <div className="entry">
                      <div className="label">
                        <Translate id="publication.analyst.name"/>
                      </div>
                      <div className="content">
                        {analyst.name}
                      </div>
                    </div>
                    <div className="entry">
                      <div className="label">
                        <Translate id="publication.analyst.phone"/>
                      </div>
                      <div className="content breaker">
                        {analyst.phone}
                      </div>
                    </div>
                    <div className="entry">
                      <div className="label">
                        <Translate id="publication.analyst.email"/>
                      </div>
                      <div className="content breaker ">
                        {analyst.mail}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Overlay>
    </Fragment>
  )
}

export default AnalystButton;