import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Hand from "../../../gfx/Hand";
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {Overlay} from "react-portal-overlay";
import EventsApi from "../../../api/EventsApi";
import {useSelector} from "react-redux";
import EventBus from "../../../elements/EventBus";

const QuestionButton = ({event, className}) => {

    const profileState = useSelector(state => state.profile);

    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleOpen = () => {
        setLoading(false);
        setShowSuccess(false);
        setText('');
        setOpen(true);
    };

    const handleClose = useCallback(() => {
        setOpen(false);
        setShowSuccess(false);
    }, []);

    useEffect(() => {
        EventBus.on('brandClick', handleClose);

        return () => {
            EventBus.remove('brandClick', handleClose);
        }
    })

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        setLoading(true);
        EventsApi.askQuestion(event.appId, event.itemId, profileState.itemId, text)
            .then(response => {
                if (response.data.success) {
                    setShowSuccess(true);
                    setLoading(false);
                    setTimeout(handleClose, 5000);
                }
            })
    }

    return (
        <Fragment>
            <button type="button" className={`QuestionButton-btn ${className}`} onClick={handleOpen}>
                <Hand/>
            </button>
            <Overlay open={open} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} closeOnClick={true} onClose={handleClose} className="overlay-container">
                {showSuccess ? (
                    <div className="default-success-dialog-outer">
                        <div className="default-success-dialog">
                            <Translate id={`meeting.question.dialog.success`}
                                       options={{renderInnerHtml: true}}/>
                        </div>
                    </div>
                ) : (
                    <div className="default-dialog-outer">
                        <div className="default-dialog question-dialog">
                            <button onClick={handleClose}>
                                <Translate id="meeting.question.dialog.cancel"/>
                            </button>
                            <div className="info-content">
                                <h4>
                                    {event.title}
                                </h4>
                                <BorderWithDot borderClass="border-with-dot-turkuoiseborder"/>
                                <div className="infoText always-margin-top w-100">
                                    <Translate id="meeting.question.dialog.intro"/>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <textarea className="form-control" minRows={3} value={text} onChange={(e) => setText(e.target.value)} rows={5}/>
                                    <button type="submit" className={`submit-btn${loading ? ' loading' : ''}`} disabled={!text || text.length < 1} onClick={handleSubmit}>
                                        <Translate id="meeting.question.dialog.submit"/>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </Overlay>
        </Fragment>
    )
}

export default QuestionButton;