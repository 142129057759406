import React from 'react';
import {authorizationLoginSuccess, authorizationLogout} from "../../../store/authorization/authorizationAction";
import {connect} from "react-redux";
import AuthorizationApi from "../../../api/AuthorizationApi";
import history from "../../../history";
import EventsApi from "../../../api/EventsApi";
import {eventsSetInvitation} from "../../../store/events/eventsAction";
import {ROUTE_MEETINGS} from "../../../config/routes";
import {withLocalize} from "react-localize-redux";

class InvitationLogin extends React.Component {

  componentDidMount() {
    const {invitationId, token} = this.props.match.params;
    this.props.authorizationLogout();
    AuthorizationApi.login(token, 'tokenLogin')
      .then((response) => {
        if (response.status === 200) {
          let {access_token, username, roles, lang} = response.data;
          this.props.authorizationLoginSuccess(access_token, username, roles);
          if (lang) this.props.setActiveLanguage(lang);
          EventsApi.getInvitation(invitationId)
            .then(response => {
              if (response.status === 200) {
                this.props.eventsSetInvitation(response.data.data);
                history.push(ROUTE_MEETINGS);
              } else {
                history.push("/");
              }
            })
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        history.push("/");
      });
  }

  render() {
    return (
      <div className="InvitationLogin"/>
    )
  }
}

export default connect(null, {
  authorizationLoginSuccess, authorizationLogout, eventsSetInvitation
})(withLocalize(InvitationLogin));