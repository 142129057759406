import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {generatePath, Link} from "react-router-dom";
import {ROUTE_MEETINGS_DIRECT} from "../../../config/routes";
import {Translate} from "react-localize-redux";

const UpcomingEventButton = ({publication, className}) => {
  const eventState = useSelector(state => state.events);
  const [evnt, setEvnt] = useState(null);

  useEffect(() => {
    if (publication?.upcomingEvent?.itemId) {
      setEvnt(eventState?.items?.find(i => i.itemId === publication.upcomingEvent.itemId));
    }
  }, [publication?.upcomingEvent?.itemId, eventState?.items]);

  return evnt ? (
    <Link to={generatePath(ROUTE_MEETINGS_DIRECT, {itemId: evnt.itemId})} className={`UpcomingEventButton ${className}`}>
      <span className="d-lg-none">
        <Translate id="publication.upcomingEvent.button"/>
      </span>
      <span className="d-none d-lg-inline">
        <Translate id="publication.upcomingEvent.buttonLong"/>
      </span>
    </Link>
  ) : null;
}

export default UpcomingEventButton;