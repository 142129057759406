import React from 'react';
import {Translate} from "react-localize-redux";
import iconSearchMobile from "../../../gfx/arrow.svg";
import {withRouter} from "react-router";
import EventBus from "../../../elements/EventBus";

class MeetingsNavigation extends React.Component {

    availableEntries = ['all', 'roadshow', 'conference', 'roundtable', 'fieldTrip', 'earningsCall'];

    state = {
        active: this.availableEntries[0],
        navigationIsOpen: false
    };

    componentDidMount() {
        EventBus.on('brandClick', () => {
            this.onRouteChanged();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged();
        }
    }

    onRouteChanged = () => {
        this.setState({
            active: this.availableEntries[0],
            navigationIsOpen: false
        })
    }

    toggleNavigation = () => this.setState({ navigationIsOpen: !this.state.navigationIsOpen });


    setActive = key => {
        this.props.onChange(key);
        this.setState({
            active: key
        })
    };

    render() {
        const menuClass = `${this.state.navigationIsOpen ? " show" : ""}`;
        return (
            <div className="MeetingsNavigation w-100">
                <div className="dropdown d-flex align-items-lg-end" onClick={this.toggleNavigation}>
                    <ul className={`${menuClass} dropdown-menu d-flex flex-column flex-lg-row`}>
                        <img alt="" className="arrow d-lg-none" src={iconSearchMobile}/>
                        {this.availableEntries.map((entry, eI) => (
                            <li key={eI} className={`${this.state.active === entry ? 'active d-inlineblock' : ''}`}>
                                <button onClick={() => this.setActive(entry)}>
                                    <Translate id={`meetings.navigation.${entry}`}/>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(MeetingsNavigation);
