import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";
import PublicationsApi from "../../../api/PublicationsApi";
import {Overlay} from "react-portal-overlay";
import moment from "moment";
import {Collapse} from "react-collapse/lib/Collapse";
import {Link} from "react-router-dom";
import YearArrow from "../../../gfx/publications/YearArrow";
import IconDownload from "../../../gfx/publications/IconDownload";
import IconWeb from "../../../gfx/publications/IconWeb";
import {generatePath, useParams} from "react-router";
import {ROUTE_PUBLICATION_WEB_VIEW, ROUTE_PUBLICATIONS} from "../../../config/routes";
import history from "../../../history";

const PublicationItemsButton = ({publication, isMain = false, extraClassName = '', ...otherProps}) => {

  const [open, setOpen] = useState(false);
  const [publicationInfo, setPublicationInfo] = useState(null);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [years, setYears] = useState([]);
  const [openYears, setOpenYears] = useState([]);
  const [openedByUrl, setOpenedByUrl] = useState(false);
  const [selectedItemByUrl, setSelectedItemByUrl] = useState(false);
  let {companyId, publicationItemId} = useParams();

  useEffect(() => {
    if (!open && !openedByUrl && isMain && companyId && parseInt(companyId) === publication.company?.id) {
      setOpenedByUrl(true);
      setOpen(true);
    }
  }, [open, openedByUrl, companyId, publication.company?.id, isMain])

  useEffect(() => {
    if (open && openedByUrl && !selectedItemByUrl && itemsToShow?.length > 0 && isMain && companyId && parseInt(companyId) === publication.company?.id && publicationItemId) {
      setSelectedItemByUrl(true);
      let item = itemsToShow.find(its => its.itemId === parseInt(publicationItemId));
      if (item) {
        setOpenYears(ys => ([...ys, item.year]))
      }
    }
  })

  useEffect(() => {
    if (open && !publicationInfo) {
      PublicationsApi.getItems(publication.company?.id)
        .then(response => {
          if (response.status === 200) {
            setPublicationInfo(response.data.data);
          }
        })
    }
  }, [open, publicationInfo, publication.company?.id]);

  useEffect(() => {
    if (open && publicationInfo?.publications) {
      let items = publicationInfo?.publications;
      items = items.map(i => ({
        ...i,
        year: parseInt(moment(i.date.start).format('YYYY'))
      }));
      let years = [...new Set(items.map(i => i.year))]?.sort((a, b) => b - a);

      setItemsToShow(items);
      setYears(years);
      if (!openedByUrl) {
        setOpenYears([years[0]]);
      }
    }
  }, [open, publicationInfo]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    history.push("/publications");
  }

  const toggleOpenYear = (year) => {
    if (openYears?.includes(year)) {
      setOpenYears(oy => oy.filter(y => y !== year));
    } else {
      setOpenYears(oy => [...oy, year]);
    }
  }

  const renderFlag = (language) => (
    <span className={`publication-language fi fi-${language === 'en' ? 'gb' : 'de'} fis`}/>
  );

  const downloadButtons = (item) => {
    return (
      <div className="downloadButtons">
        {item.downloads.web ? (
          <Link to={generatePath(ROUTE_PUBLICATION_WEB_VIEW, {token: item.downloads.token, fileLanguage: 'de'})} target="_blank">
            {renderFlag('de')}
            <IconWeb/>
          </Link>
        ) : null}
        {item.downloads.pdf ? (
          <a href={`${process.env.REACT_APP_API_URL}publication/pdf/${item.downloads.token}?lang=de`} target="_blank"
             rel="noopener noreferrer">
            {renderFlag('de')}
            <IconDownload/>
          </a>
        ) : null}
        {item.downloads.de ? (
          <a href={`${process.env.REACT_APP_API_URL}publication/pdf/${item.downloads.token}?lang=de`} target="_blank"
             rel="noopener noreferrer">
            {renderFlag('de')}
            <IconDownload/>
          </a>
        ) : null}
        {item.downloads.en ? (
          <a href={`${process.env.REACT_APP_API_URL}publication/pdf/${item.downloads.token}?lang=en`} target="_blank"
             rel="noopener noreferrer">
            {renderFlag('en')}
            <IconDownload/>
          </a>
        ) : null}
      </div>
    )
  }

  return (
    <Fragment>
      <button onClick={handleOpen} className={`PublicationItemsButton ${extraClassName}`} {...otherProps}>
        <Translate id="publication.items.button"/>&nbsp;
        <span className="amount">
          ({publication.numberOfPublications})
        </span>
      </button>
      <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
        <div className="publication-items-dialog-outer">
          <div className="publication-items-dialog">
            <button onClick={handleClose}>
              <Translate id="publication.items.dialog.back"/>
            </button>
            {publicationInfo && (
              <div className="publication-items-content">
                <h3 className="title">
                  <Translate id="publication.items.dialog.title"/>{" "}
                  {publicationInfo.company.name}
                </h3>
                <div className="publication-items-container">
                  {years.map((year, yI) => (
                    <div className="publication-item" key={yI}>
                      <div className={`year${openYears?.includes(year) ? ' active' : ''}`}
                           onClick={() => toggleOpenYear(year)}>
                        <YearArrow/>
                        <h4>{year}</h4>
                      </div>
                      <Collapse isOpened={openYears?.includes(year)}>
                        <table className="publication-table">
                          <tbody>
                          {itemsToShow.filter(i => i.year === year)?.sort((a, b) => new Date(b.date.start) - new Date(a.date.start))?.map((item, iI) => (
                            <tr key={iI} className={publicationItemId && parseInt(publicationItemId) === item.itemId ? 'highlight' : ''}>
                              <td className="col-date">
                                <div className="d-md-none info-compact">
                                  <div className="top">
                                    {item.title}
                                  </div>
                                  <div className="bottom d-flex">
                                    <div className="date">
                                      {moment(item.date.start).format('LLL')}
                                    </div>
                                    {downloadButtons(item)}
                                  </div>
                                </div>
                                <span className="d-none d-md-inline">
                                  {moment(item.date.start).format('LLL')}
                                </span>
                              </td>
                              <td className="d-none d-md-table-cell col-title">
                                {item.title}
                              </td>
                              <td className="d-none d-md-table-cell col-buttons">
                                {downloadButtons(item)}
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Overlay>
    </Fragment>
  )
}

export default PublicationItemsButton;
