import {EVENTS_LOAD_SUCCESS, EVENTS_SET_INVITATION} from "../../config/actions";

export const eventsLoadSuccess = (items) => {
    return {
        type: EVENTS_LOAD_SUCCESS,
        items
    }
};

export const eventsSetInvitation = (invitation) => {
    return {
        type: EVENTS_SET_INVITATION,
        invitation
    }
};
