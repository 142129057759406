import React from 'react';
import {Route, Switch} from "react-router";
import {
    ROUTE_MEETINGS,
    ROUTE_MEETINGS_DIRECT,
    ROUTE_PROFILE, ROUTE_PROFILE_POPUP,
    ROUTE_PUBLICATION_WEB_VIEW,
    ROUTE_PUBLICATIONS,
    ROUTE_PUBLICATIONS_DIRECT,
    ROUTE_PUBLICATIONS_DIRECT_ITEM,
    ROUTE_SCHEDULE
} from "../../config/routes";
import Profile from "./profile";
import Meetings from "./meetings";
import EventsApi from "../../api/EventsApi";
import {connect} from "react-redux";
import {eventsLoadSuccess} from "../../store/events/eventsAction";
import ProfileApi from "../../api/ProfileApi";
import {profileLoadSuccess} from "../../store/profile/profileAction";
import PublicationsApi from "../../api/PublicationsApi";
import {publicationsLoadSuccess} from "../../store/publications/publicationsAction";
import {publicationsTickerLoadSuccess} from "../../store/publications/publicationsTickerAction";
import Publications from "./publications";
import PublicationWebView from "./publicationWebView";
import LanguageToggle from "../../elements/LanguageToggle";
import {withLocalize} from "react-localize-redux";

class Authorized extends React.Component {

    state = {
        loaded: false
    };

    componentDidMount() {
        if (!this.state.loaded) {
            this.setState({loaded: true});
            this.loadEventsFromApi();
            this.loadProfileFromApi();
            this.loadPublicationsFromApi();
            this.loadPublicationsTickerFromApi();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeLanguage && (prevProps.activeLanguage !== this.props.activeLanguage || !this.state.loaded)) {
            this.setState({loaded: true});
            this.loadEventsFromApi();
            this.loadProfileFromApi();
            this.loadPublicationsFromApi();
            this.loadPublicationsTickerFromApi();
        }
    }

    loadEventsFromApi = () => {
        EventsApi.get()
            .then(response => {
                if (response.status === 200) {
                    this.props.eventsLoadSuccess(response.data.data);
                }
            }).catch(e => null)
    };

    loadProfileFromApi = () => {
        ProfileApi.get()
            .then(response => {
                if (response.status === 200) {
                    this.props.profileLoadSuccess(response.data.data.name, response.data.data.institution, response.data.data.position, response.data.data.email, response.data.data.itemId, response.data.data.mailingLists, response.data.data.companyFilters, response.data.data.companyFiltersEvents, response.data.data.views);
                }
            }).catch(e => null)
    }

    loadPublicationsFromApi = () => {
        PublicationsApi.list()
            .then(response => {
                if (response.status === 200) {
                    this.props.publicationsLoadSuccess(response.data.data);
                }
            }).catch(e => null)
    }

    loadPublicationsTickerFromApi = () => {
        PublicationsApi.ticker()
            .then(response => {
                if (response.status === 200) {
                    this.props.publicationsTickerLoadSuccess(response.data.data);
                }
            }).catch(e => null)
    }

    render() {
        return (
            <div className="Authorized">
                <LanguageToggle className="d-none d-lg-block"/>
                <Switch>
                    <Route exact path={ROUTE_PROFILE} component={Profile}/>
                    <Route exact path={ROUTE_PROFILE_POPUP} component={Profile}/>
                    <Route exact path={ROUTE_PUBLICATION_WEB_VIEW} component={PublicationWebView}/>
                    <Route exact path={ROUTE_PUBLICATIONS} component={Publications}/>
                    <Route exact path={ROUTE_PUBLICATIONS_DIRECT} component={Publications}/>
                    <Route exact path={ROUTE_PUBLICATIONS_DIRECT_ITEM} component={Publications}/>
                    <Route exact path={ROUTE_MEETINGS_DIRECT} component={Meetings}/>
                    <Route exact path={ROUTE_MEETINGS} component={Meetings}/>
                    <Route exact path={ROUTE_SCHEDULE} render={() => (
                        <Meetings schedule/>
                    )}/>
                </Switch>
            </div>
        )
    }
}

export default connect(null, {
    eventsLoadSuccess,
    profileLoadSuccess,
    publicationsLoadSuccess,
    publicationsTickerLoadSuccess
})(withLocalize(Authorized));
