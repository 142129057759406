import React from 'react';
import LandingPageApi from "../../api/LandingPageApi";
import HeaderBar from "./HeaderBar";
import EventInfo from "./EventInfo";
import Issuer from "./Issuer";
import {Collapse} from "react-collapse/lib/Collapse";
import {Translate, withLocalize} from "react-localize-redux";
import gfx_issuerArrow from '../../gfx/issuer-arrow.svg';
import Form from "./Form";

class LandingPage extends React.Component {

    state = {
        status: 'loading',
        data: null,
        showIssuers: false,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeLanguage && (prevProps.activeLanguage !== this.props.activeLanguage)) {
            this.loadData();
        }
    }

    loadData = () => {
        const token = this.props.match.params.token;
        LandingPageApi.get(token)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status: 'found',
                        data: response.data.data
                    });
                } else {
                    this.setState({status: 'notFound'});
                }
            })
    }

    render() {
        switch (this.state.status) {
            case 'loading':
                return (
                    <div className="mt-5 text-center">Loading...</div>
                )
            case 'found':
            case 'success':
            case 'alreadyRegistered':
                return (
                    <div className="LandingPage">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 col-lg-3 order-2 order-lg-1">
                                    <Form data={this.state.data} extraClassName="d-lg-flex" status={this.state.status} setStatus={(status) => this.setState({status: status})}/>
                                </div>
                                <div className="col-6 col-lg-3 pl-xl-5 mh-100 col-right-side order-1 order-lg-2">
                                    <HeaderBar logo={this.state.data.company?.logo?.uuid}/>
                                    <EventInfo data={this.state.data}/>
                                    {this.state.data.issuers && this.state.data.issuers.length > 0 ? (
                                        <div className="issuers-container">
                                            <button className={`issuers-collapse-toggle d-lg-none${this.state.showIssuers ? ' is-open' : ''}`} onClick={() => this.setState({showIssuers: !this.state.showIssuers})}>
                                                {this.state.showIssuers ? (
                                                    <Translate id="landingPage.issuers.hide"/>
                                                ) : (
                                                    <Translate id="landingPage.issuers.show"/>
                                                )}
                                                <img src={gfx_issuerArrow} alt=""/>
                                            </button>
                                            <h3 className="issuers-title d-none d-lg-block">
                                                <Translate id="landingPage.issuers.title"/>
                                            </h3>
                                            <Collapse isOpened={this.state.showIssuers}>
                                                <div className="issuers">
                                                    {this.state.data.issuers.sort((a, b) => a.sort - b.sort).map((issuer, iI) => (
                                                        <Issuer key={iI} issuer={issuer}/>
                                                    ))}
                                                </div>
                                            </Collapse>
                                            <div className="issuers d-none d-lg-block">
                                                {this.state.data.issuers.map((issuer, iI) => (
                                                    <Issuer key={iI} issuer={issuer}/>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="LandingPage-error">
                        <div className="error-box">
                            <h1>
                                <Translate id="landingPage.error.notFound.title" options={{renderInnerHtml: true}}/>
                            </h1>
                            <h3>
                                <Translate id="landingPage.error.notFound.text" options={{renderInnerHtml: true}}/>
                            </h3>
                        </div>
                    </div>
                )
        }
    }
}

export default withLocalize(LandingPage);
