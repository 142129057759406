import React, {Fragment} from 'react';
import infoButtonDefaultGfx from '../../../gfx/info_button.svg';
import infoButtonMouseoverGfx from '../../../gfx/info_button_mouseover.svg';
import {Overlay} from "react-portal-overlay";
import {Translate, withLocalize} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import EventBus from "../../../elements/EventBus";
import Linkify from 'react-linkify';
import moment from "moment";
import BorderWithDotNew from "../../../elements/border/BorderWithDotNew";

class InfoButton extends React.Component {

    wrapperRef = React.createRef();

    state = {
        dialogOpen: false
    }

    scrollToLeft = () => {
        const wrapper = this.wrapperRef.current.getElementsByClassName('table-responsive')[0];
        const scrollLeft = wrapper.scrollLeft;
        if (scrollLeft > 0) {
            const newScrollLeft = Math.max(
              wrapper.scrollLeft - 150,
              0
            );
            wrapper.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth',
            });
            return newScrollLeft > 0;
        }
        return false;
    }

    scrollToRight = () => {
        const wrapper = this.wrapperRef.current.getElementsByClassName('table-responsive')[0];
        const scrollableWidth = wrapper.scrollWidth - wrapper.clientWidth;
        if (scrollableWidth > 0) {
            const newScrollLeft = Math.min(
              wrapper.scrollLeft + 150,
              scrollableWidth
            );
            wrapper.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth',
            });
            return newScrollLeft < scrollableWidth;
        }
        return false;
    };

    updateButtonStatesByEvent = () => {
        this.updateButtonStates(0);
    }

    updateButtonStates = (offset = 0) => {
        const wrapper = this.wrapperRef.current.getElementsByClassName('table-responsive')[0];
        const legendBox = document.getElementById('metrics-table-legend-box');
        const buttonContainer = legendBox?.getElementsByClassName('button-container')[0];
        const button1 = buttonContainer?.getElementsByClassName('buttonLeft')[0];
        const button2 = buttonContainer?.getElementsByClassName('buttonRight')[0];
        const scrollableDistance = wrapper.scrollWidth - wrapper.clientWidth;
        if (scrollableDistance > 0) {
            buttonContainer.classList.remove('d-none');

            if (wrapper.scrollLeft + offset <= 0) {
                button1.disabled = true;
            } else {
                button1.disabled = false;
            }

            if (wrapper.scrollLeft + offset >= scrollableDistance) {
                button2.disabled = true;
            } else {
                button2.disabled = false;
            }
        } else {
            buttonContainer.classList.add('d-none');
        }
    };

    handleButtonLeftClick = () => {
        this.scrollToLeft();
    };

    handleButtonRightClick = () => {
        this.scrollToRight();
    };

    doMarkup = () => {
        const wrapper = this.wrapperRef.current;
        const legendBox = document.getElementById('metrics-table-legend-box');
        legendBox.innerHTML = '';
        if (wrapper && legendBox) {
            const buttonContainer = document.createElement('div');
            buttonContainer.classList += 'button-container';

            const button1 = document.createElement('button');
            button1.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g id="Gruppe_4550" data-name="Gruppe 4550" transform="translate(-128 -626)"><g id="Rechteck_2860" data-name="Rechteck 2860" transform="translate(128 626)" fill="#fff" stroke="currentColor" stroke-width="2"><rect width="40" height="40" rx="20" stroke="none"/><rect x="1" y="1" width="38" height="38" rx="19" fill="none"/></g><path id="Pfad_1418" data-name="Pfad 1418" d="M4.226,20.58,0,16.354,6.063,10.29,0,4.226,4.226,0l10.29,10.29Z" transform="translate(153.258 656.29) rotate(180)" fill="currentColor"/></g></svg>';
            button1.classList += 'buttonLeft';
            button1.disabled = true;
            button1.addEventListener('click', this.handleButtonLeftClick);

            const button2 = document.createElement('button');
            button2.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g id="Gruppe_4549" data-name="Gruppe 4549" transform="translate(-207 -626)"><g id="Rechteck_2859" data-name="Rechteck 2859" transform="translate(207 626)" fill="#fff" stroke="currentColor" stroke-width="2"><rect width="40" height="40" rx="20" stroke="none"/><rect x="1" y="1" width="38" height="38" rx="19" fill="none"/></g><path id="Pfad_1418" data-name="Pfad 1418" d="M4.226,20.58,0,16.354,6.063,10.29,0,4.226,4.226,0l10.29,10.29Z" transform="translate(220.742 635.71)" fill="currentColor"/></g></svg>';
            button2.classList += 'buttonRight';
            button2.addEventListener('click', this.handleButtonRightClick);

            buttonContainer.appendChild(button1);
            buttonContainer.appendChild(button2);
            legendBox.appendChild(buttonContainer);

            if (this.props.event?.info?.legend) {
                const legendContainer = document.createElement('div');
                legendContainer.innerHTML = this.props.event.info.legend;
                legendContainer.classList += 'legend-container';
                legendBox.appendChild(legendContainer)
            }
        }

        const sourceBox = document.getElementById('metrics-table-source-box');
        sourceBox.innerHTML = '';
        if (wrapper && sourceBox && this.props.event?.info?.currentState && this.props.event?.info?.source) {
            const sourceContainer = document.createElement('div');
            sourceContainer.innerHTML = `${this.props.translate('meeting.info.dialog.source')}: ${this.props.event.info.source}`;
            sourceContainer.classList += 'source-container';
            sourceBox.appendChild(sourceContainer);
            const dateContainer = document.createElement('div');
            dateContainer.innerHTML = `${this.props.translate('meeting.info.dialog.date')}: ${moment(this.props.event.info.currentState).format('LLL')}`;
            dateContainer.classList += 'date-container';
            sourceBox.appendChild(dateContainer);
        }

        const tableResponsive = this.wrapperRef.current.getElementsByClassName('table-responsive')[0]
        if (tableResponsive) {
            window.addEventListener('resize', this.updateButtonStatesByEvent);
            tableResponsive.addEventListener('scroll', this.updateButtonStatesByEvent);
            this.updateButtonStatesByEvent();
        }
    };

    componentDidMount() {
        EventBus.on('brandClick', this.closeDialog);
    }

    componentDidUpdate() {
        if (this.wrapperRef.current) {
            this.doMarkup();
        }
    }

    componentWillUnmount() {
        EventBus.remove('brandClick', this.closeDialog);
        if (this.wrapperRef.current) {
            this.wrapperRef.current
              .querySelectorAll('button')
              .forEach((button) => {
                  button.removeEventListener('click', () => {});
              });
            window.removeEventListener('resize', this.updateButtonStatesByEvent);
        }
    }

    openDialog = () => {
        this.setState({dialogOpen: true});
    };

    closeDialog = () => {
        this.setState({dialogOpen: false});
    };



    render() {
        const {event} = this.props;
        return (
            <Fragment>
                <button className="InfoButton" onClick={this.openDialog}>
                    <img src={infoButtonDefaultGfx} alt="" className="img img-default"/>
                    <img src={infoButtonMouseoverGfx} alt="" className="img img-mouseover"/>
                </button>
                <Overlay open={this.state.dialogOpen} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} closeOnClick={false} onClose={this.closeDialog} className="overlay-container">
                    <div className="info-dialog-outer">
                        <div className="info-dialog">
                            <button onClick={this.closeDialog}>
                                <Translate id="meeting.info.dialog.cancel"/>
                            </button>
                            <div className="info-content">
                                <h4>
                                    {event.title}
                                </h4>
                                <BorderWithDotNew/>
                                <div className="infoText always-margin-top">
                                    <Linkify properties={{target: '_blank', rel: 'noopener noreferrer'}}>
                                        <p dangerouslySetInnerHTML={{__html: event.teaser}} className="invitation-content-text" ref={this.wrapperRef}/>
                                    </Linkify>
                                </div>
                            </div>
                        </div>
                    </div>
                </Overlay>
            </Fragment>
        )
    }
}

export default withLocalize(InfoButton);
