import React from 'react';
import {Link} from "react-router-dom";
import {
    ROUTE_DATA_PRIVACY, ROUTE_DEPRECATED_LANDING_PAGE, ROUTE_ERROR,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_IMPRINT,
    ROUTE_LANDING_PAGE,
    ROUTE_LOGIN
} from "../../config/routes";
import {matchPath, withRouter} from "react-router";
import {Translate} from "react-localize-redux";
import BorderWithDot from "../border/borderWithDot";

class FooterMenu extends React.Component {

    isWithoutBackground = () => {
        return [ROUTE_LOGIN, ROUTE_FORGOT_PASSWORD].includes(this.props.location.pathname);
    };

    isWhiteBackground = () => {
        return !![ROUTE_LANDING_PAGE, ROUTE_DEPRECATED_LANDING_PAGE].find(r => matchPath(this.props.location.pathname, {path: r}));
    }

    isPositionRelative = () => {
        return [ROUTE_ERROR].includes(this.props.location.pathname);
    }

    render() {
        return (
            <div
                className={`FooterMenu${this.isWithoutBackground() ? ' without-background' : ''}${this.isWhiteBackground() ? ' white-background' : ''}${this.isPositionRelative() ? ' relative' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div
                            className="col-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                            <BorderWithDot borderClass={`border-with-dot-left ${this.isWhiteBackground() ? 'border-with-dot-black-text' : 'border-with-dot-white'}`}/>
                            <Link to={ROUTE_DATA_PRIVACY}>
                                <Translate id="footerMenu.dataPrivacy"/>
                            </Link>
                            <div className="border-middle"/>
                            <Link to={ROUTE_IMPRINT}>
                                <Translate id="footerMenu.imprint"/>
                            </Link>
                            <BorderWithDot borderClass={`${this.isWhiteBackground() ? 'border-with-dot-black-text' : 'border-with-dot-white'}`}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(FooterMenu);
