import React from 'react';
import AuthorizationApi from "../../../api/AuthorizationApi";
import history from "../../../history";
import {generatePath} from "react-router-dom";
import {ROUTE_PROFILE_POPUP} from "../../../config/routes";
import {connect} from "react-redux";
import {authorizationLoginSuccess, authorizationLogout} from "../../../store/authorization/authorizationAction";
import {withLocalize} from "react-localize-redux";

class UnsubscribeLogin extends React.Component {

  componentDidMount() {
    const {token} = this.props.match.params;
    this.props.authorizationLogout();
    AuthorizationApi.login(token, 'tokenLogin')
      .then((response) => {
        if (response.status === 200) {
          let {access_token, username, roles, lang} = response.data;
          if (lang) this.props.setActiveLanguage(lang);
          this.props.authorizationLoginSuccess(access_token, username, roles);
          history.push(generatePath(ROUTE_PROFILE_POPUP, {popup: 'notificationSettings'}));
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        history.push("/");
      });
  }

  render() {
    return (
      <div className="UnsubscribeLogin"/>
    )
  }
}

export default connect(null, {
  authorizationLoginSuccess, authorizationLogout
})(withLocalize(UnsubscribeLogin));