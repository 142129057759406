import React from 'react';
import {Translate} from "react-localize-redux";
import ChangePasswordForm from "./ChangePasswordForm";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {connect} from "react-redux";
import NotificationSettings from "./NotificationSettings";

class Profile extends React.Component {

    render() {
        const { profile } = this.props;
        return (
            <div className="Profile d-lg-flex align-items-lg-center">
                <div className="container">
                    <div className="row flex-wrap row-notificationSettings">
                        <div className="col-6 col-md-4 offset-md-1 col-lg-2 offset-lg-3">
                            <NotificationSettings/>
                        </div>
                    </div>
                    <div className="row flex-wrap row-profile">
                        <div className="col-6 col-md-4 offset-md-1 col-lg-2 col-changePw">
                            <ChangePasswordForm/>
                        </div>
                        <div className="col-6 col-md-4 offset-md-1 col-lg-2 offset-lg-0 col-user-details">
                            <div className="user-details">
                                <BorderWithDot/>
                                <p>
                                    <strong><Translate id="profile.userDetails.name"/></strong><br/>
                                    {profile.name}
                                </p>
                                <p>
                                    <strong><Translate id="profile.userDetails.institution"/></strong><br/>
                                    {profile.institution}
                                </p>
                                <p>
                                    <strong><Translate id="profile.userDetails.position"/></strong><br/>
                                    {profile.position}
                                </p>
                                <p>
                                    <strong><Translate id="profile.userDetails.email"/></strong><br/>
                                    {profile.email}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, null)(Profile);
