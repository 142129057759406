import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {authorizationLogout} from "../../store/authorization/authorizationAction";
import {Translate} from "react-localize-redux";
import {NavLink} from "react-router-dom";
import {ROUTE_DEFAULT, ROUTE_MEETINGS, ROUTE_PROFILE, ROUTE_SCHEDULE, ROUTE_PUBLICATIONS} from "../../config/routes";
import history from "../../history";
import BorderWithDot from "../border/borderWithDot";

class Menu extends React.Component {

    handleLogout = () => {
        this.props.authorizationLogout();
        history.push(ROUTE_DEFAULT);
    };


    render() {
        return (
            <Fragment>
                <div className="TopBar-Menu" >
                    <NavLink exact to={ROUTE_MEETINGS} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.meetings"/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.meetings"/></span>
                    </NavLink>
                    <NavLink exact to={ROUTE_SCHEDULE} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.schedule"/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.schedule"/></span>
                    </NavLink>
                    <NavLink to={ROUTE_PUBLICATIONS} onClick={this.props.onLinkClick}>
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.publications"/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.publications"/></span>
                    </NavLink>
                    <NavLink to={ROUTE_PROFILE} onClick={this.props.onLinkClick} >
                        <BorderWithDot borderClass="border-with-dot-white d-block d-lg-none"/>
                        <span className="navItem"><Translate id="topBar.menu.profile"/></span>
                        <span className="setActiveWidth"><Translate id="topBar.menu.profile"/></span>
                    </NavLink>
                    <button onClick={this.handleLogout}>
                        <div className="mobileActive-border d-none"/>
                        <Translate id="topBar.menu.logout"/>
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default connect(null, {
    authorizationLogout
})(Menu);
