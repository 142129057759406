export const ROUTE_DEFAULT = '/';

// UNAUTHORIZED
export const ROUTE_LOGIN = '/';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/auth/resetPassword/:token';
export const ROUTE_TOKEN_LOGIN = '/auth/token/:token';
export const ROUTE_INVITATION_LOGIN = '/invitation/:invitationId/:token';
export const ROUTE_PUBLICATION_LOGIN = '/publication/:companyId/:publicationItemId/:token';
export const ROUTE_UNSUBSCRIBE_LOGIN = '/profile/unsubscribe/:token';

// AUTHORIZED
export const ROUTE_MEETINGS = '/';
export const ROUTE_MEETINGS_DIRECT = '/meetings/:itemId';
export const ROUTE_SCHEDULE = '/schedule';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_POPUP = '/profile/:popup';
export const ROUTE_PUBLICATIONS = '/publications';
export const ROUTE_PUBLICATIONS_DIRECT = '/publications/:companyId';
export const ROUTE_PUBLICATIONS_DIRECT_ITEM = '/publications/:companyId/:publicationItemId';
export const ROUTE_PUBLICATION_WEB_VIEW = '/publication/:token/:fileLanguage';

// LEGALS
export const ROUTE_DATA_PRIVACY = '/datenschutz';
export const ROUTE_IMPRINT = '/impressum';

// LANDING PAGE
export const ROUTE_LANDING_PAGE = '/event/:token';
export const ROUTE_DEPRECATED_LANDING_PAGE = '/earningsCall/:token';

// ERROR
export const ROUTE_ERROR = '/error';