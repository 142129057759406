import axios from 'axios';

class EventsApi {

    static get = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}event/get`)
    };

    static participate = (appId, itemId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}event/apply`, {
            appId,
            itemId
        })
    };

    static cancel = (appId, itemId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}event/cancel`, {
            appId,
            itemId
        })
    }

    static getIcs = (appId, itemId) => {
        return axios.get(`${process.env.REACT_APP_API_URL}ics/${appId}/${itemId}`, {
            responseType: 'blob'
        });
    }

    static getInvitation = (invitationId) => {
        return axios.get(`${process.env.REACT_APP_API_URL}invitation/${invitationId}`);
    }

    static askQuestion = (appId, itemId, creatorItemId, text) => {
        return axios.post(`${process.env.REACT_APP_API_URL}question/ask`, {
            creatorItemId: creatorItemId,
            eventAppId: appId,
            eventItemId: itemId,
            text: text
        });
    }
}

export default EventsApi;
