import React from 'react';

const Calendar = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28.503" viewBox="0 0 27 28.503">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rechteck_2447" data-name="Rechteck 2447" width="27" height="28.503" fill="currentColor"/>
            </clipPath>
        </defs>
        <g id="Gruppe_4279" data-name="Gruppe 4279" clipPath="url(#clip-path)">
            <path id="Pfad_1877" data-name="Pfad 1877" d="M52.12,134.254h0a1.967,1.967,0,1,1,1.967-1.967,1.967,1.967,0,0,1-1.967,1.967" transform="translate(-45.503 -118.236)" fill="currentColor"/>
            <path id="Pfad_1878" data-name="Pfad 1878" d="M115.755,134.254h0a1.967,1.967,0,1,1,1.967-1.967,1.967,1.967,0,0,1-1.967,1.967" transform="translate(-103.237 -118.236)" fill="currentColor"/>
            <path id="Pfad_1879" data-name="Pfad 1879" d="M179.391,134.254h0a1.967,1.967,0,1,1,1.967-1.967,1.967,1.967,0,0,1-1.967,1.967" transform="translate(-160.973 -118.236)" fill="currentColor"/>
            <path id="Pfad_1880" data-name="Pfad 1880" d="M52.12,197.142h0a1.967,1.967,0,1,1,1.967-1.967,1.967,1.967,0,0,1-1.967,1.967" transform="translate(-45.503 -175.293)" fill="currentColor"/>
            <path id="Pfad_1881" data-name="Pfad 1881" d="M115.755,197.142h0a1.967,1.967,0,1,1,1.967-1.967,1.967,1.967,0,0,1-1.967,1.967" transform="translate(-103.237 -175.293)" fill="currentColor"/>
            <path id="Pfad_1882" data-name="Pfad 1882" d="M207.785,221.255h-1.344v1.407h-1.407v1.345h1.407v1.407h1.344v-1.408h1.409v-1.344h-1.409Z" transform="translate(-186.022 -200.739)" fill="currentColor"/>
            <path id="Pfad_1883" data-name="Pfad 1883" d="M58.8,5.586A1.284,1.284,0,0,0,60.088,4.3V1.284a1.284,1.284,0,1,0-2.567,0V4.3A1.284,1.284,0,0,0,58.8,5.586" transform="translate(-52.188)" fill="currentColor"/>
            <path id="Pfad_1884" data-name="Pfad 1884" d="M186.077,5.586A1.284,1.284,0,0,0,187.36,4.3V1.284a1.284,1.284,0,0,0-2.567,0V4.3a1.284,1.284,0,0,0,1.284,1.284" transform="translate(-167.659)" fill="currentColor"/>
            <path id="Pfad_1885" data-name="Pfad 1885" d="M26.992,49.618a5.9,5.9,0,0,0-1.957-4.09V32.494a2.374,2.374,0,0,0-2.374-2.374H21.556v1.509a3.138,3.138,0,1,1-6.276,0V30.12H9.755v1.509a3.138,3.138,0,0,1-6.276,0V30.12H2.374A2.374,2.374,0,0,0,0,32.494V50.55A2.552,2.552,0,0,0,2.552,53.1h13.56A5.908,5.908,0,0,0,27,49.921q0-.152-.008-.3m-11.659,1.63H2.552a.7.7,0,0,1-.7-.7V37.339H23.181V44.4a5.9,5.9,0,0,0-2.077-.385h-.014a5.906,5.906,0,0,0-5.756,7.235m10.323-1.326a4.564,4.564,0,0,1-7.838,3.18q-.091-.094-.176-.193l-.011-.012a4.575,4.575,0,0,1-.468-.65l0,0c-.043-.072-.083-.146-.122-.221l-.016-.032q-.053-.1-.1-.21l-.013-.029A4.564,4.564,0,0,1,23.735,46.2h0a4.583,4.583,0,0,1,.5.41l0,0a4.607,4.607,0,0,1,.434.475v0a4.591,4.591,0,0,1,.365.534,4.543,4.543,0,0,1,.621,2.3Z" transform="translate(0 -27.327)" fill="currentColor"/>
        </g>
    </svg>

);

export default Calendar;