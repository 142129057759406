import React from 'react';
import {Overlay} from "react-portal-overlay";
import {Translate} from "react-localize-redux";
import EventBus from "../../../elements/EventBus";
import Linkify from "react-linkify";
import BorderWithDotNew from "../../../elements/border/BorderWithDotNew";

class Invitation extends React.Component {

    state = {
        dialogOpen: false
    }

    componentDidMount() {
        setTimeout(() => this.setState({dialogOpen: true}), 500);
        EventBus.on('brandClick', this.closeDialog);
    }

    componentWillUnmount() {
        EventBus.remove('brandClick', this.closeDialog);
    }

    closeDialog = () => {
        this.setState({dialogOpen: false});
    };

    render() {
        const {invitation} = this.props;
        return (
            <Overlay open={this.state.dialogOpen} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} closeOnClick={false} onClose={this.closeDialog} className="overlay-container">
                <div className="info-dialog-outer">
                    <div className="info-dialog">
                        <button onClick={this.closeDialog}>
                            <Translate id="meeting.invitation.dialog.cancel"/>
                        </button>
                        <div className="info-content">
                            <h4>
                                {invitation.eventFilterText}
                            </h4>
                            <BorderWithDotNew/>
                            <div className="infoText always-margin-top">
                                <Linkify properties={{target: '_blank', rel: 'noopener noreferrer'}}>
                                    <p dangerouslySetInnerHTML={{__html: invitation.intro}} className="invitation-content-text"/>
                                    <p dangerouslySetInnerHTML={{__html: invitation.text}} className="invitation-content-text"/>
                                    <p dangerouslySetInnerHTML={{__html: invitation.outro}} className="invitation-content-text"/>
                                </Linkify>
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
}

export default Invitation;
