import React from 'react';

const IconDownload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30.182" height="17.012" viewBox="0 0 30.182 17.012">
    <g id="Gruppe_4548" data-name="Gruppe 4548" transform="translate(-657.447 -521.567)">
      <path id="Pfad_1670" data-name="Pfad 1670" d="M70.642,7.786,77.26,1a.589.589,0,0,0-.421-1H63.6a.589.589,0,0,0-.421,1L69.8,7.786a.589.589,0,0,0,.843,0" transform="translate(602.317 521.567)" fill="currentColor"/>
      <path id="Pfad_1671" data-name="Pfad 1671" d="M25.43,116.149H2.752A2.756,2.756,0,0,1,0,113.4V106.28a.792.792,0,0,1,1.583,0V113.4a1.17,1.17,0,0,0,1.169,1.169H25.43A1.17,1.17,0,0,0,26.6,113.4V106.28a.792.792,0,0,1,1.583,0V113.4a2.756,2.756,0,0,1-2.752,2.752" transform="translate(658.447 421.431)" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
    </g>
  </svg>
);

export default IconDownload;