import React, {Fragment} from 'react';
import {Overlay} from "react-portal-overlay";
import {Translate} from "react-localize-redux";
import moment from "moment";
import EventBus from "../../../elements/EventBus";

class FilesButton extends React.Component {

    state = {
        dialogOpen: false
    }

    componentDidMount() {
        EventBus.on('brandClick', this.closeDialog);
    }

    componentWillUnmount() {
        EventBus.remove('brandClick', this.closeDialog);
    }

    openDialog = () => {
        this.setState({dialogOpen: true});
    };

    closeDialog = () => {
        this.setState({dialogOpen: false});
    };

    render() {
        const {event} = this.props;
        return (
            <Fragment>
                <button className="FilesButton" onClick={this.openDialog}>
                    <Translate id="meeting.files.button"/>
                </button>
                <Overlay open={this.state.dialogOpen} animation={{duration: 100, easing: 'ease'}} closeOnEsc={true} onClose={this.closeDialog} className="overlay-container">
                    <div className="files-dialog-outer">
                        <div className="files-dialog">
                            <button onClick={this.closeDialog}>
                                <Translate id="meeting.files.dialog.cancel"/>
                            </button>
                            <div className="files-content">
                                {event?.files && event?.files?.map((file, fI) => (
                                    <div className="file" key={fI}>
                                        <h4>{file.name}</h4>
                                        <a href={`${process.env.REACT_APP_API_URL}file/${file.uuid}`} download target="_blank" rel="noopener noreferrer" className="a-btn">
                                            <Translate id="meeting.files.dialog.download"/>
                                        </a>
                                        <div className="lastUpdate">
                                            <b>
                                                <Translate id="meeting.files.dialog.lastUpdateAt"/>
                                            </b><br/>
                                            {moment(file.updatedAt).format('LLL - LT')} <Translate id="meeting.files.dialog.dateH"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Overlay>
            </Fragment>
        )
    }
}

export default FilesButton;
