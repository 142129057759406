import React, {Fragment} from 'react';
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import EventsApi from "../../../api/EventsApi";
import {connect} from "react-redux";
import {eventsLoadSuccess} from "../../../store/events/eventsAction";
import moment from "moment";
import EventBus from "../../../elements/EventBus";

class CancelButton extends React.Component {

    state = {
        dialogOpen: false,
        successLoading: false,
        successDialogOpen: false,
        sentToStateAt: moment(),
        lastState: 'applying'
    };

    componentDidMount() {
        EventBus.on('brandClick', this.handleCloseAll);
    }

    componentWillUnmount() {
        EventBus.remove('brandClick', this.handleCloseAll);
    }

    getFlow = () => {
        const {event} = this.props;
        let flow = 'simple';
        if (event.type === 'roadshow') flow = 'searchSlots';
        return flow;
    }

    startCancel = () => {
        const {event} = this.props;
        this.setState({
            dialogOpen: true,
            successLoading: false,
            sentToStateAt: moment(),
            lastState: event.userIsApplying ? 'applying' : 'participating'
        });
    }

    doCancel = (e) => {
        e.preventDefault();
        const {event} = this.props;
        let flow = this.getFlow();
        this.setState({successLoading: true});
        if (flow === 'simple') {
            EventsApi.cancel(event.appId, event.itemId)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({successLoading: false, successDialogOpen: true, sendToStateAt: moment().add(5, 'seconds')});
                        this.closeDialog();
                        this.loadEventsFromApi();
                        setTimeout(this.closeSuccessDialog, 5000);
                    }
                });
        } else if (flow === 'searchSlots' && event.slots) {
            event.slots.filter(slot => slot.userIsApplying || slot.userIsParticipating).map(slot => {
                EventsApi.cancel(slot.appId, slot.itemId)
                    .then((response) => {
                        if (response.data.success) {
                            // for now it's always only one slot, so this should be okay
                            this.setState({successLoading: false, successDialogOpen: true, sendToStateAt: moment().add(5, 'seconds')});
                            this.closeDialog();
                            this.loadEventsFromApi();
                            setTimeout(this.closeSuccessDialog, 5000);
                        }
                    });
                return slot;
            });
        }
    };

    closeDialog = () => {
        this.setState({dialogOpen: false});
    }

    closeSuccessDialog = () => {
        this.setState({successDialogOpen: false, sendToStateAt: moment()});
    }

    handleCloseAll = () => {
        this.setState({dialogOpen: false, successDialogOpen: false});
    }

    async loadEventsFromApi() {
        EventsApi.get()
            .then(async response => {
                if (response.status === 200) {
                    if(this.state.sendToStateAt) {
                        while (moment().isBefore(this.state.sendToStateAt)) {
                            await new Promise(resolve => setTimeout(resolve, 100));
                        }
                    }
                    this.props.eventsLoadSuccess(response.data.data);
                }
            })
    };

    render() {
        const {event} = this.props;
        return (
            <Fragment>
                <button className={this.props.className} onClick={this.startCancel}>
                    {this.props.children}
                </button>
                <Overlay open={this.state.dialogOpen || this.state.successDialogOpen} animation={{duration: 100, easing: 'ease'}} className="overlay-container" onClick={() => this.state.successDialogOpen ? this.closeSuccessDialog() : null}>
                    {this.state.dialogOpen ? (
                        <div className="cancel-dialog-outer">
                            <div className="cancel-dialog">
                                <button onClick={this.closeDialog}>
                                    <Translate id="meeting.cancel.dialog.back"/>
                                </button>
                                <form onSubmit={this.doCancel}>
                                    {event.userIsApplying ? (
                                        <Translate id={`meeting.cancel.dialog.default.questionApplying`}
                                                   options={{renderInnerHtml: true}}/>
                                    ) : (
                                        <Translate id={`meeting.cancel.dialog.default.question`}
                                                   options={{renderInnerHtml: true}}/>
                                    )}
                                    <h4 className="eventTitle">{event.title}</h4>
                                    <button type="submit" className={this.state.successLoading ? 'loading' : ''}>
                                        {event.userIsApplying ? (
                                            <Translate id="meeting.cancel.dialog.submitApplying"/>
                                        ) : (
                                            <Translate id="meeting.cancel.dialog.submit"/>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    ) : this.state.successDialogOpen ? (
                        <div className="cancel-success-dialog-outer">
                            <div className="cancel-success">
                                <Translate id={`meeting.cancel.dialog.success.${this.state.lastState}`}
                                           options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    ) : null}
                </Overlay>
            </Fragment>
        )
    }
}

export default connect(null, {
    eventsLoadSuccess
})(CancelButton);
