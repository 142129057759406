import {PUBLICATIONS_TICKER_LOAD_SUCCESS} from "../../config/actions";

let defaultState = {
  loaded: false,
  items: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PUBLICATIONS_TICKER_LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        items: action.publicationsTicker
      };
    default:
      return state;
  }
}
