import React, {Fragment, useEffect, useRef, useState} from 'react';
import BorderWithDot from "../../elements/border/borderWithDot";
import {Translate, withLocalize} from "react-localize-redux";
import {Link} from "react-router-dom";
import Select, {components} from 'react-select';
import gfx_search from "../../gfx/search-landing.svg";
import gfx_arrowPosition from "../../gfx/arrow-position.svg";
import countryTelData from 'country-telephone-data';
import {Collapse} from "react-collapse";
import LandingPageApi from "../../api/LandingPageApi";
import {useParams} from "react-router";

const Form = ({data, extraClassName, activeLanguage, status, setStatus}) => {

    let { token } = useParams();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [institution, setInstitution] = useState('');
    const [position, setPosition] = useState('');
    const [email, setEmail] = useState('');
    const [phoneCountry, setPhoneCountry] = useState('de');
    const [phone, setPhone] = useState('');
    const [registerInvitations, setRegisterInvitations] = useState(false);
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailValidated, setEmailValidated] = useState(false);
    const [formError, setFormError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    let enteringEmailTimeoutRef = useRef(null);

    useEffect(() => {
        if (position && !data.positionOptions?.find(po => po.id === position.value && po.hideInstitutionInput )) {
            setInstitution('');
        }
        if (!position || ![240, 273].includes(position.value)) {
            setRegisterInvitations(false);
        }
    }, [position, data.positionOptions]);

    const handleChangeEmail = (val) => {
        if (enteringEmailTimeoutRef.current) clearTimeout(enteringEmailTimeoutRef.current);
        setEmailValidated(false);
        setEmailHasError(false);
        setEmail(val);
        enteringEmailTimeoutRef.current = setTimeout(() => validateEmail(val, false), 2000);
    }

    const validateEmail = (email, triggeredBySubmit = false) => {
        if (emailValidated) {
            if (triggeredBySubmit) handleSubmit();
            return;
        }
        LandingPageApi.validateEmail(email, token)
            .then(response => {
                if (response.data.success) {
                    if (response.data.data.deliverability === 'DELIVERABLE') {
                        setEmailValidated(true);
                        setEmailHasError(false);
                        if (triggeredBySubmit) handleSubmit(null, true);
                    } else {
                        setEmailValidated(true);
                        setEmailHasError(true);
                    }
                } else {
                    setEmailValidated(true);
                    setEmailHasError(true);
                }
            })
    }

    const handleSubmit = (e, skipEmailValidation = false) => {
        if (e) e.preventDefault();
        if (!skipEmailValidation && !emailValidated) {
            validateEmail(email, true);
            return;
        }
        setIsSubmitting(true);
        LandingPageApi.apply(token, firstName, lastName, position ? position.value : '', institution, email, `+${countryTelData.allCountries.find(c => c.iso2 === phoneCountry).dialCode}${phone}`, activeLanguage?.code, registerInvitations)
            .then(response => {
                setIsSubmitting(false);
                if (response.data.success) {
                    if (response.data.data === 'alreadyRegistered') {
                        setStatus('alreadyRegistered');
                    } else {
                        setStatus('success');
                    }
                } else {
                    setFormError(true);
                }
            })
    }

    const randomId = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);

    return (
        <div className={`Form status-${status} ${extraClassName}`}>
            <div className="bg" style={{backgroundColor: data.company.color ?? '#055C60'}}/>
            {status === 'found' ? (
                <div className="content">
                    <div className="row">
                        <div className="col-6 form-container">
                            <h4 className="mb-0 d-none d-lg-block">
                                <Translate id="landingPage.form.intro"/>
                            </h4>
                            <p className="intro-help mb-0 d-none d-lg-block mt-lg-0">
                                <Translate id="landingPage.form.help"/>
                            </p>
                            <BorderWithDot borderClass="border-with-dot-black"/>
                            <h4 className="mb-0 d-lg-none">
                                <Translate id="landingPage.form.intro"/>
                            </h4>
                            <p className="intro-help mb-0 d-lg-none">
                                <Translate id="landingPage.form.help"/>
                            </p>
                            <form onSubmit={handleSubmit}>
                                <div className="input-group">
                                    <label>
                                        <Translate id="landingPage.form.input.firstName.label"/>
                                    </label>
                                    <div className="input-container">
                                        <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <label>
                                        <Translate id="landingPage.form.input.lastName.label"/>
                                    </label>
                                    <div className="input-container">
                                        <input type="text" value={lastName} onChange={e => setLastName(e.target.value)}/>
                                    </div>
                                </div>
                                {data.positionOptions && data.positionOptions.length > 0 ? (
                                    <div className="input-group">
                                        <label>
                                            <Translate id="landingPage.form.input.position.label"/>
                                        </label>
                                        <div className="input-container">
                                            <Select value={position} onChange={val => setPosition(val)} className="position-select" classNamePrefix="select" options={
                                                data.positionOptions.map(po => (
                                                    {
                                                        value: po.id,
                                                        label: activeLanguage?.code === 'en' ? po.textEn : po.text
                                                    }
                                                ))
                                            } placeholder={false} components={{
                                                IndicatorSeparator: null,
                                                DropdownIndicator: (props) => (
                                                    <components.DropdownIndicator {...props}>
                                                        <img src={gfx_arrowPosition} alt="" className="rotate"/>
                                                    </components.DropdownIndicator>
                                                )
                                            }} isClearable={true}/>
                                        </div>
                                    </div>
                                ) : null}
                                {position && !data.positionOptions?.find(po => po.id === position.value && po.hideInstitutionInput) ? (
                                    <div className="input-group">
                                        <label>
                                            <Translate id="landingPage.form.input.institution.label"/>
                                        </label>
                                        <div className="input-container">
                                            <input type="text" value={institution} onChange={e => setInstitution(e.target.value)}/>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="input-group">
                                    <label>
                                        <Translate id="landingPage.form.input.email.label"/>
                                    </label>
                                    <div className="input-container">
                                        <input type="text" value={email} onChange={e => handleChangeEmail(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="input-group mb-0">
                                    <label/>
                                    <div className="input-container">
                                        <Collapse isOpened={true}>
                                            {emailHasError && <div className="error-text">
                                                <div className="pb-3">
                                                    <Translate id="landingPage.error.emailInvalid"/>
                                                </div>
                                            </div>}
                                        </Collapse>
                                    </div>
                                </div>
                                <div className="input-group last-of-type">
                                    <label>
                                        <Translate id="landingPage.form.input.phone.label"/>
                                    </label>
                                    <div className="input-container">
                                        <Select value={countryTelData.allCountries.filter(c => c.iso2 === phoneCountry).map(c => ({
                                            value: c.iso2,
                                            label: `${c.iso2?.toUpperCase()} (+${c.dialCode})`
                                        }))[0]} onChange={val => setPhoneCountry(val.value)} className="phoneCountry-select" classNamePrefix="select" options={
                                            countryTelData.allCountries.sort((a, b) => a.iso2.localeCompare(b.iso2)).map(c => (
                                                {
                                                    value: c.iso2,
                                                    label: `${c.iso2?.toUpperCase()} (+${c.dialCode})`
                                                }
                                            ))
                                        } placeholder={false} components={{
                                            IndicatorSeparator: null,
                                            DropdownIndicator: (props) => (
                                                <components.DropdownIndicator {...props}>
                                                    <img src={gfx_search} alt=""/>
                                                </components.DropdownIndicator>
                                            )
                                        }}/>
                                        <input type="text" value={phone} onChange={e => setPhone(e.target.value)}/>
                                    </div>
                                </div>
                                {position && position.value && ['InstitutionellerInvestor', 'Presse'].includes(data.positionOptions?.find(po => po.id === position?.value)?.text?.replaceAll(' ', '')) ? (
                                    <div className="checkbox-container">
                                        <input type="checkbox" id={`registerInvitations${randomId}`} name="registerInvitations" value={registerInvitations} onChange={e => setRegisterInvitations(e.target.checked)}/>
                                        <label htmlFor={`registerInvitations${randomId}`}>
                                            <Translate id="landingPage.form.input.registerInvitations.label"/>
                                        </label>
                                    </div>
                                ) : null}
                                {formError ? (
                                    <div className="error-text mt-3 mb-2">
                                        <Translate id="landingPage.form.formError"/>
                                    </div>
                                ) : null}
                                <button className={`submit-button${isSubmitting ? ' loading' : ''}`} disabled={isSubmitting}>
                                    <Translate id="landingPage.form.submit"/>
                                </button>
                                <div className="disclaimer-text">
                                    <p>
                                        <Translate id="landingPage.form.disclaimer.text1"/>
                                        &nbsp;<Translate id="landingPage.form.disclaimer.text2"/>
                                        <Link to="/datenschutz"><Translate id="landingPage.form.disclaimer.dataPrivacy"/></Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}
            {['success', 'alreadyRegistered'].includes(status) ? (
                <div className="success">
                    {status === 'success' ? (
                        <Fragment>
                            <h1>
                                <Translate id="landingPage.success.title" options={{renderInnerHtml: true}}/>
                            </h1>
                            <h3>
                                <Translate id="landingPage.success.text"/>
                            </h3>
                            {registerInvitations ? (
                                <h5>
                                    <Translate id="landingPage.success.registerInvitations"/>
                                </h5>
                            ) : null}
                        </Fragment>
                    ) : null}
                    {status === 'alreadyRegistered' ? (
                        <Fragment>
                            <h1>
                                <Translate id="landingPage.success.alreadyRegisteredTitle" options={{renderInnerHtml: true}}/>
                            </h1>
                            <h3>
                                <Translate id="landingPage.success.alreadyRegistered" options={{renderInnerHtml: true}}/>
                            </h3>
                        </Fragment>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}

export default withLocalize(Form);