import React, {Fragment} from 'react';
import {withLocalize} from "react-localize-redux";

const DataPrivacy = ({languages, activeLanguage, setActiveLanguage}) => (
  <div className="DataPrivacy">
    <div className="container">
      <div className="row">
        <div className="offset-lg-1 col-md-6 col-lg-5">
          <div className="content">
            {activeLanguage && activeLanguage?.code === 'de' ? (
              <Fragment>
                <h3>
                  Datenschutz
                </h3>
                <p>
                  Stand: Juli 2024
                </p>
                <p>&nbsp;</p>
                <p>
                  Wir freuen uns über Ihren Besuch auf der Internetseite montegaconnect.de der Airtime Software AG (nachfolgend
                  auch „Airtime“ / „wir“ / „uns“). Der Schutz Ihrer Daten ist uns ein sehr wichtiges Anliegen. Im
                  Folgenden wollen wir Sie darüber informieren, in welchem Umfang und zu welchen Zwecken wir von Ihnen
                  personenbezogene Daten über die Internetseite montegaconnect.de (im Folgenden „Plattform“) erheben und
                  verarbeiten.
                </p>
                <p>
                  <strong>
                    1 Allgemeines / Begriffsbestimmungen
                  </strong>
                  Unsere Datenschutzhinweise beruhen auf den Begrifflichkeiten, die in der Datenschutz-Grundverordnung
                  (im Folgenden „DSGVO“) verwendet werden. Wir verwenden in diesen Datenschutzhinweisen unter anderem
                  die folgenden Begriffe:
                </p>
                <p>
                  <b>Personenbezogene Daten</b> sind alle Informationen, die sich auf eine identifizierte oder
                  identifizierbare natürliche Person (im Folgenden „betroffene Person”) beziehen. Als identifizierbar
                  wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu
                  einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu
                  einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen,
                  psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind,
                  identifiziert werden kann (vgl. Art. 4 Nr. 1 DSGVO).
                </p>
                <p>
                  <b>Verarbeitung</b> ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
                  jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen,
                  die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
                  Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
                  Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung
                  (vgl. Art. 4 Nr. 2 DSGVO).
                </p>
                <p>
                  <b>Verantwortlicher</b> oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische
                  Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
                  und Mittel der Verarbeitung von personenbezogenen Daten entscheidet (vgl. Art. 4 Nr. 3, Halbsatz 1
                  DSGVO).
                </p>
                <p>
                  <b>Auftragsverarbeiter</b> ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
                  andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet (vgl. Art. 4 Nr.
                  8 DSGVO).
                </p>
                <p>
                  <b>Dritter</b> ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
                  außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
                  unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt ist,
                  die personenbezogenen Daten zu verarbeiten (vgl. Art. 4 Nr. 10 DSGVO).
                </p>
                <p>
                  <strong>
                    2 Verantwortlichkeit / Kontakt
                  </strong>
                  Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung personenbezogener Daten im Sinne der
                  DSGVO ist die:
                </p>
                <p>
                  <b>Airtime Software AG</b><br/>
                  Schauenburgerstraße 10<br/>
                  20095 Hamburg<br/>
                  Deutschland<br/>
                  <br/>
                  Telefon: +49 40 573 093 730<br/>
                  E-Mail: info@airtime-software.com<br/>
                  Internet: <a href="https://www.airtime-software.com" target="_blank"
                               rel="noopener noreferrer">www.airtime-software.com</a>
                </p>
                <p>
                  Dementsprechend kommen wir mit diesen Datenschutzhinweisen unseren Informationspflichten hinsichtlich
                  Art, Umfang und Zwecken der Verarbeitung personenbezogener Daten aus Art. 12 - 14 DSGVO nach.
                </p>
                <p>
                  Wenn Sie Einsicht und/oder Aktualisierung Ihrer personenbezogenen Daten wünschen oder Fragen zum
                  Datenschutz auf unserer Plattform haben, wenden Sie sich bitte über unsere E-Mail-Adresse
                    office@airtime-software.com oder postalisch unter der oben angegebenen Adresse jederzeit an uns.
                </p>
                <p>
                  <strong>
                    3 Datenverarbeitung beim Besuch unserer Plattform
                  </strong>
                  Umfang und Art der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unterscheiden sich
                  danach, ob Sie sich über unsere Plattform in Ihrem persönlichen Kundenbereich für ein Event
                  interessieren oder anmelden, eine Research-Anfrage stellen oder Ihren persönlichen Kundenbereich
                  unserer Plattform rein informatorisch nutzen. Im Hinblick auf die nachstehend beschriebenen
                  Datenverarbeitungsprozesse können Sie jederzeit Ihre Betroffenenrechte (hierzu unter Ziffer 6) geltend
                  machen.
                </p>
                <p>
                  <strong>
                    3.1 Erhebung von Daten unter Ihrer Mitwirkung
                  </strong>
                </p>
                <p>
                  <strong>
                    3.1.1 Interesse bzw. Teilnahme an einem Event
                  </strong>
                  Wenn Sie sich über unseren persönlichen Kundenbereich für ein lokales oder virtuelles Event
                  interessieren oder daran teilnehmen möchten, verarbeiten wir in diesem Zusammenhang personenbezogene
                  Daten von Ihnen. Hierzu gehören in Abhängigkeit des gewählten Events insbesondere Ihre bei der
                  Registrierung zum Kundenkonto hinterlegten Stammdaten. Sofern Sie nicht auf unserer Plattform
                  registriert sind und sich für ein Event anmelden, ist die Verarbeitung folgender personenbezogener
                  Daten erforderlich:
                  <ul>
                    <li>
                      Vor- und Nachname
                    </li>
                    <li>
                      Institution
                    </li>
                    <li>
                      E-Mail-Adresse
                    </li>
                    <li>
                      Telefonnummer.
                    </li>
                  </ul>
                </p>
                <p>
                  Diese personenbezogenen Daten werden ausschließlich zum Zweck der Organisation und Durchführung des
                  jeweiligen Events verarbeitet (Rechtsgrundlage: Art. 6 Abs. 1 b) DSGVO) sowie für die
                  darüberhinausgehende Bereitstellung Ihrer Informationen an die Emittenten auch im Anschluss an das
                  durchgeführte Event (siehe hierzu unter Ziffer 3.1.2.1).
                </p>
                <p>
                  Weitere Informationen zur Verarbeitung Ihrer bei der Registrierung angegebenen personenbezogenen Daten
                  finden Sie auch unter: <a href="https://airtime-software.com/datenschutz/"
                                            target="_blank"
                                            rel="noopener noreferrer">https://airtime-software.com/datenschutz/</a>.
                </p>
                <p>
                  Die Sie betreffenden Daten werden gelöscht, sofern sie für den jeweiligen Zweck nicht mehr
                  erforderlich sind oder Sie der Datenverarbeitung widersprochen haben (Art. 21 DSGVO) und wir nicht
                  gesetzlich dazu verpflichtet oder berechtigt sind, Ihre personenbezogenen Daten darüber hinaus zu
                  speichern.
                </p>
                <p>
                  <strong>
                    3.1.2 Organisation und Durchführung eines Events
                  </strong>
                  Im Rahmen der Durchführung und Organisation eines Events ist es erforderlich, dass wir Ihre
                  personenbezogenen Daten als angemeldeter Teilnehmer dieses Events mit Dritten teilen.
                </p>
                <p>
                  <strong>
                    3.1.2.1 Übermittlung von Informationen an Emittenten
                  </strong>
                  Im Rahmen der Bereitstellung der Plattform sowie der Durchführung von Events werden wir Ihre
                  personenbezogenen Daten auch mit Emittenten teilen. Emittenten sind vertraglich dazu verpflichtet, die
                  von Ihnen erhaltenen Informationen vertraulich zu behandeln und nur im Rahmen des Vertragszwecks, d.h.
                  im Rahmen der Nutzung der Plattform und zur Durchführung des vom Emittenten beauftragten Events, zu
                  nutzen. Gleichzeitig erhalten Sie alle vom Emittenten bereitgestellten Informationen zum Emittenten
                  und dem von ihm beauftragten Event.
                </p>
                <p>
                  Folgende personenbezogene Daten teilen wir mit Emittenten:
                </p>
                <p>
                  Sofern Sie sich über unsere Plattform als Teilnehmer eines Events eines Emittenten anmelden und / oder
                  Research-Publikationen eines Emittenten abrufen, werden Ihre personenbezogenen Daten, die Sie in Ihrem
                  Profil hinterlegt haben (z.B. Profilfoto, Vor- und Nachname, Unternehmen, Position, E-Mail-Adresse,
                  Telefonnummer sowie Informationen zum Event, an dem Sie teilgenommen haben) über die Plattform auch
                  mit dem jeweiligen Emittenten des Events bzw. dem der Research-Publikation zugrundeliegenden
                  Emittenten geteilt. Dem Emittenten werden insbesondere in Form von Historien auch Informationen
                  darüber übermittelt, an welchen sonstigen Events des Emittenten Sie bereits teilgenommen haben bzw.
                  welche Research-Publikationen des Emittenten Sie abgerufen haben. Sofern Sie sich für ein Event
                  anmelden, ohne auf unserer Plattform registriert zu sein, werden nur die von Ihnen angegebenen
                  personenbezogenen Daten (siehe hierzu Ziffer 3.1.1.) mit den Emittenten geteilt und auch nach
                  Durchführung des Events in Form von Historien für den Emittenten abrufbar bereitgestellt.
                </p>
                <p>
                  Die Weitergabe Ihrer personenbezogenen Daten im Rahmen der Durchführung und Vorbereitung von Events
                  sowie zur Bereitstellung unserer Plattformdienste erfolgt somit zur Erbringung der
                  vertragsgegenständlichen Leistung (Art. 6 Abs. 1 b) DSGVO). Nach Durchführung des Events erfolgt die
                  Speicherung und Bereitstellung Ihrer personenbezogenen Daten auf Grundlage unseres berechtigten
                  Interesses (Rechtsgrundlage: Art. 6 Abs. 1 f) DSGVO), den Emittenten und Ihnen einen ganzheitlichen
                  Service anbieten zu können. Hierzu gehört auch die Darstellung der durchgeführten Events und
                  Speicherung der Teilnehmerlisten. Der jeweilige Emittent hat so die Möglichkeit, mit Ihnen als
                  Teilnehmer ggf. zu einem späteren Zeitpunkt erneut in Kontakt zu treten. Sollten Sie die Speicherung
                  Ihrer personenbezogenen Daten auf der Plattform nach Durchführung des Events nicht wünschen, können
                  Sie der Verarbeitung jederzeit, z.B. per E-Mail an office@airtime-software.com, widersprechen (Art. 21 DSGVO).
                </p>
                <p>
                  <strong>
                    3.1.2.2 Dienstleister zur Durchführung von Events
                  </strong>
                  Darüber hinaus kann es erforderlich sein, dass wir Ihre personenbezogenen Daten sowie
                  Unternehmensdaten an Dienstleister weitergeben (z.B. Betreiber der Location bei lokalen Events,
                  Dienstanbieter für die Umsetzung eines virtuellen Events etc.), die uns bei der Durchführung des
                  Events, an welchem Sie teilnehmen möchten, unterstützen.
                </p>
                <p>
                  Die Weitergabe der Daten an die jeweiligen Dienstleister ist für die Erbringung der
                  vertragsgegenständlichen Leistung erforderlich und erfolgt ausschließlich im hierfür notwendigen Maß.
                  Rechtsgrundlage der Weitergabe der Daten ist Art. 6 Abs. 1 b) DSGVO.
                </p>
                <p>
                  <strong>
                    3.1.2.3 Dienstleister zur Durchführung von virtuellen Events
                  </strong>
                  Wir nutzen zur Durchführung von virtuellen Events das Videokonferenzsystem „Zoom“ des Anbieters Zoom
                  Video Communications Inc., 55 Almaden Boulevard, 6th Floor, San Jose, CA 95113, USA („Zoom“). Das
                  Videokonferenzsystem wird dabei u.a. unmittelbar über iFrames auf unserer Plattform eingebettet.
                  Alternativ können Sie einen Link auf unserer Plattform nutzen. In diesem Fall werden Sie auf die
                  Plattform von Zoom weitergeleitet und Zoom ist unmittelbar für die Verarbeitung Ihrer Daten zuständig.
                </p>
                <p>
                  Bei der Nutzung von Zoom werden verschiedene Datenarten verarbeitet. Der Umfang der Daten hängt dabei
                  auch davon ab, welche Angaben zu Daten Sie vor bzw. bei der Teilnahme an virtuellen Events machen.
                </p>
                <p>
                  Folgende personenbezogene Daten können unter Umständen bei der Nutzung Gegenstand der Verarbeitung
                  durch Zoom sein:
                  <ul>
                    <li>
                      Angaben zum Benutzer: Vorname, Nachname, Telefon (optional), E-Mail-Adresse, Passwort, Profildaten
                      wie Nutzername (optional)
                    </li>
                    <li>
                      Meeting-Metadaten: Thema, Beschreibung (optional), Teilnehmer-IP-Adressen,
                      Geräte-/Hardware-Informationen
                    </li>
                    <li>
                      bei Aufzeichnungen (optional): MP4-Datei aller Video-, Audio- und Präsentationsaufnahmen,
                      M4A-Datei aller Audioaufnahmen, Textdatei des Online-Meeting-Chats
                    </li>
                    <li>
                      bei Einwahl mit dem Telefon: Angabe zur eingehenden und ausgehenden Rufnummer, Ländername, Start-
                      und Endzeit; Gegebenenfalls können weitere Verbindungsdaten wie z.B. die IP-Adresse des Geräts
                      gespeichert werden.
                    </li>
                    <li>
                      Text-, Audio- und Videodaten: Sie haben ggf. die Möglichkeit, in einem virtuellen Event Chat-,
                      Frage- oder Umfragefunktionen zu nutzen. Insoweit werden die von Ihnen gemachten Texteingaben
                      verarbeitet, um diese im virtuellen Event anzuzeigen und ggf. zu protokollieren. Um die Anzeige
                      von Video und die Wiedergabe von Audio zu ermöglichen, werden entsprechend während der Dauer des
                      Events die Daten vom Mikrofon Ihres Endgeräts sowie von einer etwaigen Videokamera des Endgeräts
                      verarbeitet. Sie können die Kamera oder das Mikrofon jederzeit selbst über die
                      „Zoom“-Applikationen abschalten bzw. stummstellen.
                    </li>
                  </ul>
                </p>
                <p>
                  Um an einem virtuellen Event via Zoom teilzunehmen, müssen Sie regelmäßig zumindest Angaben zu Ihrem
                  Namen machen. Unter Umständen kann es erforderlich sein, dass Sie sich ein Nutzerkonto bei dem
                  jeweiligen Anbieter erstellen müssen. In diesem Fall werden die Kundendaten regelmäßig durch den
                  Anbieter auch für eigene Zwecke genutzt.
                </p>
                <p>
                  Wenn wir virtuelle Events aufzeichnen wollen, werden wir Ihnen das im Vorwege transparent mitteilen
                  und – soweit erforderlich – um Ihre Einwilligung (Rechtsgrundlage Art. 6 Abs. 1 a) DSGVO) bitten. Die
                  Tatsache der Aufzeichnung wird Ihnen zudem im virtuellen Event angezeigt. Dem Emittenten der
                  Veranstaltung wird das aufgezeichnete virtuelle Event im Anschluss zur Nutzung und Veröffentlichung
                  auf der Plattform zur Verfügung gestellt.
                </p>
                <p>
                  Im Falle von virtuellen Events können wir für Zwecke der Aufzeichnung und Nachbereitung dieser
                  Veranstaltung auch die gestellten Fragen von Teilnehmenden verarbeiten.
                </p>
                <p>
                  Rechtsgrundlage für die Datenverarbeitung bei der Durchführung von virtuellen Events ist Art. 6 Abs. 1
                  b) DSGVO, soweit die Veranstaltungen im Rahmen von Vertragsbeziehungen durchgeführt werden.
                </p>
                <p>
                  Da es sich bei Zoom um einen Anbieter aus den USA handelt, findet eine Verarbeitung Ihrer
                  personenbezogenen Daten regelmäßig auch in einem Drittland statt. Wir haben mit Zoom aus diesem Grund
                  einen Auftragsverarbeitungsvertrag, der den Anforderungen von Art. 28 DSGVO entspricht, und zur
                  Gewährleistung eines der EU entsprechenden Datenschutzniveaus EU-Standardvertragsklauseln (Art. 46
                  Abs. 2 c) DSGVO) geschlossen. Danach hat sich Zoom zur Einhaltung des europäischen Datenschutzes
                  verpflichtet und ein angemessenes Datenschutzniveau garantiert.
                </p>
                <p>
                  Die kommunikationsbezogenen Meta-Daten werden gelöscht, sobald die Speicherung nicht mehr für die
                  Erbringung oder Aufrechterhaltung des Dienstes erforderlich ist.
                </p>
                <p>
                  Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch Zoom finden
                  Sie in der Datenschutzerklärung von Zoom unter: <a href="https://zoom.us/de-de/privacy.html"
                                                                     target="_blank"
                                                                     rel="noopener noreferrer">https://zoom.us/de-de/privacy.html</a>.
                </p>
                <p>
                  <strong>
                    3.1.3 Kontaktaufnahme durch Airtime im Nachgang Ihrer Anmeldung
                  </strong>
                  Wenn Sie an einem Event teilgenommen und hierbei Ihre E-Mail-Adresse hinterlegt haben, kann diese
                  durch uns auch für den Versand von E-Mails zur Einholung von Feedback oder Einladung zu weiteren
                  Events verwendet werden. Rechtsgrundlage für den Versand dieser E-Mails ist § 7 Abs. 3 UWG sowie Art.
                  6 Abs. 1 f) DSGVO. In diesem Fall haben Sie jederzeit die Möglichkeit, sich von der Verteilerliste zum
                  Versand dieser E-Mails abzumelden (Art. 21 DSGVO). Hierfür folgen Sie entweder dem Abmeldelink in der
                  jeweiligen E-Mail oder schicken uns eine E-Mail an office@airtime-software.com.
                </p>
                <p>
                  <strong>
                    3.2 Erhebung von Daten ohne Ihre Mitwirkung
                  </strong>
                  Wir erfassen und verwenden durch Ihren Besuch auf unserer Plattform automatisch generierte
                  personenbezogene Daten zur technischen Bereitstellung unserer Plattform. Bei der Nutzung unserer
                  Plattform und dem Login in den persönlichen Kundenbereich notiert unser Server vorübergehend die
                  folgenden personenbezogenen Daten in sog. Log-Files:
                  <ul>
                    <li>
                      providerseitig zugewiesene IP-Adresse
                    </li>
                    <li>
                      Dateianfrage des Clients (Dateiname und URL)
                    </li>
                    <li>
                      http-Statuscode
                    </li>
                    <li>
                      die Internetseite, von der aus Sie uns besuchen.
                    </li>
                  </ul>
                </p>
                <p>
                  Diese Daten sind technisch für die Funktionsweise und den Betrieb unserer Plattform notwendig. Zudem
                  ist die kurzfristige Speicherung von Log-Files sachdienlich, um nachträglich Angriffsversuche auf
                  Webserver oder etwaigen Missbrauch aufzuklären. Unser berechtigtes Interesse an dieser
                  Datenverarbeitung besteht demnach darin, Ihnen unser Angebot auf Basis dieser Gegebenheiten anbieten
                  zu können (Rechtsgrundlage: Art. 6 Abs. 1 f) DSGVO). Die Daten werden gelöscht, wenn sie hierfür nicht
                  mehr erforderlich sind.
                </p>
                <p>
                  Darüber hinaus verwenden wir auf unserer Plattform an mehreren Stellen sog. „Cookies“, die dazu
                  dienen, unser Angebot nutzerfreundlicher und effektiver zu machen. Cookies sind kleine Textdateien,
                  die unsere Plattform auf Ihrem Computer bzw. anderen internetfähigen Geräten wie Tablets oder
                  Smartphones platzieren möchte, sofern Ihre Browser-Einstellungen Cookies akzeptieren.
                </p>
                <p>
                  Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Bei den auf der
                  Plattform eingesetzten Cookies handelt es sich überwiegend um sog. Session-Cookies, die automatisiert
                  gelöscht werden, wenn Sie den Browser schließen. Diese legen für Ihren Browser eine sogenannte
                  Session-ID an, mit welcher sich verschiedene Anfragen Ihres Browsers einer Sitzung zuordnen lassen,
                  sodass Sie wiedererkannt werden, wenn Sie mit dem Browser auf unsere Plattform zurückkehren. Die
                  Session-Cookies werden gelöscht, wenn Sie den Browser schließen. Sie haben die Möglichkeit, Ihren
                  Browser so einzustellen, dass diese Cookies gar nicht erst gespeichert werden oder dass die Cookies am
                  Ende Ihrer Internetsitzung gelöscht werden. Bitte beachten Sie dabei aber, dass Sie in diesem Fall
                  gegebenenfalls nicht sämtliche Funktionen unserer Plattform nutzen können.
                </p>
                <p>
                  Sie können der Verarbeitung Ihrer Daten mittels Cookies jederzeit für die Zukunft widersprechen (Art.
                  21 DSGVO). Bitte berücksichtigen Sie aber, dass Sie dann ggf. nicht alle Funktionalitäten unserer
                  Plattform nutzen können.
                </p>
                <p>
                  <strong>
                    4 Weitergabe von Daten an Auftragsverarbeiter
                  </strong>
                  Einige Daten müssen wir unter strenger Einhaltung des geltenden Datenschutzrechts an Dritte
                  weitergeben.
                </p>
                <p>
                  <strong>
                    4.1 Zur inhaltlich-technischen Betreuung und Gestaltung unseres Online-Auftritts
                  </strong>
                  Zur inhaltlichen und technischen Betreuung unserer Plattform ist es erforderlich, dass externe
                  Dienstleister Zugriff auf personenbezogene Daten erhalten (insbesondere IT-Servicedienstleister).
                </p>
                <p>
                  In diesem Fall erfolgt der Umgang mit Ihren personenbezogenen Daten ausschließlich nach unserer
                  ausdrücklichen Weisung und auf Basis einer Vereinbarung über die Auftragsverarbeitung gemäß Art. 28
                  DSGVO. Mit dieser Vereinbarung garantiert uns der Dienstleister, dass die Serviceerbringung in
                  Einklang mit geltendem Datenschutzrecht steht. Die Hinzuziehung professioneller Anbieter
                  entsprechender Services ist gesetzlich ausdrücklich vorgesehen und dient unserem berechtigten
                  Interesse, unser Angebot für Sie zu professionalisieren und betriebswirtschaftlich sinnvoll anbieten
                  zu können (Rechtsgrundlage: Art. 6 Abs. 1 f) DSGVO). Wir bleiben auch in diesem Fall für den Schutz
                  Ihrer Daten verantwortlich.
                </p>
                <p>
                  In diesem Zusammenhang leiten wir Ihre Daten an folgende Dienstleister weiter:
                  <ul>
                    <li>
                      Zur inhaltlichen Gestaltung unserer Plattform:<br/>
                      Backslash Seven GmbH, Großbeerenstr. 12, 10963 Berlin (Deutschland)<br/>
                      Weitere Informationen zum Datenschutz der Backslash Seven GmbH finden Sie hier: <a
                      href="https://backslashseven.de/datenschutz" target="_blank"
                      rel="noopener noreferrer">https://backslashseven.de/datenschutz</a>
                    </li>
                    <li>
                      Zur Bereitstellung unserer Management Plattform und Speicherung Ihrer Daten:<br/>
                      Citrix Systems Inc., 851 West Cypress Creek Road Fort Lauderdale, FL 33309 USA<br/>
                      Weitere Informationen zum Datenschutz der Citrix Systems Inc. finden Sie hier: <a
                      href="https://www.citrix.com/de-de/about/legal/privacy/" target="_blank"
                      rel="noopener noreferrer">https://www.citrix.com/de-de/about/legal/privacy/</a>
                    </li>
                    <li>
                      Zum Hosting unserer Plattform:<br/>
                      domainfactory GmbH, Oskar-Messter-Str. 33, 85737 Ismaning (Deutschland)<br/>
                      Weitere Informationen zum Datenschutz der domainfactory GmbH finden Sie hier: <a
                      href="https://www.df.eu/de/datenschutz/" target="_blank"
                      rel="noopener noreferrer">https://www.df.eu/de/datenschutz/</a> <br/>
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>
                    4.2 Weitergabe aufgrund gesetzlicher Verpflichtung
                  </strong>
                  Wir behalten uns außerdem vor, Ihre personenbezogenen Daten offenzulegen, wenn wir gesetzlich hierzu
                  nachweislich verpflichtet sind oder deren Herausgabe seitens Behörden oder Strafverfolgungsorganen von
                  uns in rechtskonformer Weise verlangt wird.
                </p>
                <p>
                  <strong>
                    5 Ort der Datenverarbeitung und Datensicherheit
                  </strong>
                  Die Verarbeitung Ihrer Daten findet überwiegend in Deutschland statt. Ihre Daten werden nur dann in
                  ein Land außerhalb der Europäischen Union oder des europäischen Wirtschaftsraums übermittelt, wenn für
                  dieses ein angemessenes Schutzniveau im Sinne der Art. 45 ff. DSGVO gegeben ist oder wenn geeignete
                  Garantien im Sinne von Art. 46 Abs. 2 DSGVO vorliegen. Auf unserer Seite erfasste Daten werden auf
                  dieser Basis gegebenenfalls in die Vereinigten Staaten (z.B. Citrix Systems Inc.) weitergeleitet. Zum
                  Schutz Ihrer Daten vor unberechtigtem Zugriff und Missbrauch haben wir umfangreiche, dem Stand der
                  Technik entsprechende technische und organisatorische Sicherheitsvorkehrungen nach Maßgabe des
                  europäischen Datenschutzrechts (Art. 32 DSGVO) getroffen.
                </p>
                <p>
                  <strong>
                    6 Betroffenenrechte
                  </strong>
                  Zur Ausübung der nachstehenden Rechte können Sie sich jederzeit per E-Mail (office@airtime-software.com) an uns
                  wenden.
                </p>
                <p>
                  Recht auf Auskunft: Sie können jederzeit unentgeltlich Auskunft über den Umfang, die Herkunft und die
                  Empfänger der gespeicherten Daten sowie den Zweck der Speicherung verlangen (Art. 15 DSGVO). Wenn Sie
                  Ihr Auskunftsrecht in Anspruch nehmen möchten, können Sie sich hierzu jederzeit an einen Mitarbeiter
                  von Airtime wenden.
                </p>
                <p>
                  Recht auf Datenübertragbarkeit: Sie können die Sie betreffenden personenbezogenen Daten, die Sie uns
                  bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format erhalten (Art. 20
                  DSGVO), sofern (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 a) DSGVO oder Art. 9
                  Abs. 2 a) DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 b) DSGVO beruht und (2) die Verarbeitung
                  mithilfe automatisierter Verfahren erfolgt.
                </p>
                <p>
                  Recht auf Berichtigung: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                  Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu
                  verlangen (Art. 16 DSGVO). Ferner steht der betroffenen Person unter Berücksichtigung der Zwecke der
                  Verarbeitung das Recht zu, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.
                </p>
                <p>
                  Recht auf Löschung (Recht auf Vergessenwerden): Jede von der Verarbeitung personenbezogener Daten
                  betroffene Person hat das Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden
                  personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und
                  soweit die Verarbeitung nicht erforderlich ist (Art. 17 DSGVO): (1) Die personenbezogenen Daten wurden
                  für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig
                  sind. (2) Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung stützte,
                  und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung. (3) Die betroffene Person
                  legt Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für
                  die Verarbeitung vor. (4) Die personenbezogenen Daten wurden unrechtmäßig verarbeitet. (5) Die
                  Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich.
                </p>
                <p>
                  Recht auf Widerspruch: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                  Recht, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten Widerspruch
                  einzulegen (Art. 21 DSGVO).
                </p>
                <p>
                  Wir verarbeiten die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir
                  können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die gegenüber den Interessen,
                  Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der
                  Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Verarbeiten wir personenbezogene
                  Daten, um Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch
                  gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen.
                </p>
                <p>
                  Recht auf Widerruf einer datenschutzrechtlichen Einwilligung: Jede von der Verarbeitung
                  personenbezogener Daten betroffene Person hat das Recht, eine Einwilligung zur Verarbeitung
                  personenbezogener Daten jederzeit zu widerrufen (Art. 7 Abs. 3 DSGVO).
                </p>
                <p>
                  Recht auf Beschwerde bei einer Aufsichtsbehörde: Unbeschadet eines anderweitigen
                  verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer
                  Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder
                  des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                  betreffenden personenbezogenen Daten gegen die DSGVO verstößt (Art. 77 DSGVO).
                </p>
                <p>
                  Bei der Geltendmachung Ihrer Ansprüche uns gegenüber werden wir diesen Anspruch prüfen und, soweit
                  keine anderweitige gesetzliche Grundlage für die Weiterverarbeitung vorliegt, dem nachkommen. Über das
                  Ergebnis werden wir Sie in Kenntnis setzen.
                </p>
                <p>
                  Die Einhaltung einer besonderen Form ist für die Geltendmachung Ihrer Betroffenenrechte in aller Regel
                  nicht erforderlich; schreiben Sie uns zum Beispiel per E-Mail unter office@airtime-software.com.
                </p>
                <p>
                  <strong>
                    7 Aktualisierung und Änderungen
                  </strong>
                  Teile der Datenschutzhinweise können von uns geändert oder aktualisiert werden, ohne dass Sie vorher
                  von uns benachrichtigt werden. Bitte überprüfen Sie jeweils die Datenschutzhinweise, bevor Sie unser
                  Angebot nutzen, um bei möglichen Änderungen oder Aktualisierungen auf dem neuesten Stand zu sein.
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <h3>
                  Data Privacy
                </h3>
                <p>
                  As of: July 2024
                </p>
                <p>&nbsp;</p>
                <p>
                  Thank you for visiting the website montegaconnect.de of Airtime Software AG (hereinafter also "Airtime" /"we"
                  /"us"). The protection of your data is very important to us. In the following, we would like to inform
                  you to what extent and for what purposes we collect and process your personal data via the website
                  montegaconnect.de (hereinafter "platform").
                </p>
                <p>
                  <strong>
                    1 General / Definition
                  </strong>
                  Our data privacy statement is based on the terms used in the General Data Protection Regulation
                  (hereinafter "GDPR"). We use the following terms, among others, in this data privacy statement:
                </p>
                <p>
                  <b>Personally identifiable information</b> is any information relating to an identified or
                  identifiable natural person (hereinafter referred to as "data subject"). A natural person is
                  considered to be identifiable if, directly or indirectly, in particular by means of assignment to an
                  identifier such as a name, an identification number, location data, an online identifier, or to one or
                  more distinctive characteristics, the expression of the physical, physiological, genetic,
                  psychological, economic, cultural, or social identity of this natural person can be identified (cf.
                  Art. 4 No. 1 GDPR).
                </p>
                <p>
                  <b>Processing</b> is any process carried out with or without the help of automated procedures or any
                  such series of processes in connection with personal data, such as collecting, recording, organizing,
                  arranging, storing, adapting or changing, reading out, querying, using, disclosure through
                  transmission, distribution or any other form of provision, comparison or linking, restriction,
                  deletion, or destruction (cf. Art. 4 No. 2 GDPR).
                </p>
                <p>
                  <b>The responsible</b> party for processing is the natural person or legal entity, authority,
                  institution, or other body that, alone or jointly with others, decides on the purposes and means of
                  processing personal data (cf. Art. 4 No. 3, clause 1 GDPR ).
                </p>
                <p>
                  <b>A processor</b> is a natural person or legal entity, authority, institution, or other body that
                  processes personal data on behalf of the responsible party (cf. Art. 4 No. 8 GDPR).
                </p>
                <p>
                  <b>A third party</b> is a natural person or legal entity, public authority, agency, or body other than
                  the data subject, the controller, the processor, and the persons who, under the direct responsibility
                  of the controller or the processor, are authorized to process the personal data (cf. Art 4 No. 10
                  GDPR).
                </p>
                <p>
                  <strong>
                    2 Responsible party / Contact
                  </strong>
                  The responsible authority for the collection, processing, and use of personal data within the meaning
                  of the GDPR is:
                </p>
                <p>
                  <b>Airtime Software AG</b><br/>
                  Schauenburgerstraße 10<br/>
                  20095 Hamburg<br/>
                  Germany<br/>
                  <br/>
                  Telephone: +49 40 573 093 730<br/>
                  Email: info@airtime-software.com<br/>
                  Internet: <a href="https://www.airtime-software.com" target="_blank"
                               rel="noopener noreferrer">www.airtime-software.com</a>
                </p>
                <p>
                  Accordingly, with this data privacy statement, we comply with our information obligations regarding
                  the type, scope, and purposes of the processing of personal data as laid out in Art. 12 - 14 GDPR.
                </p>
                <p>
                  If you would like to view and/or update your personal data or have any questions about data privacy on
                  our platform, please contact us at any time via our email address, office@airtime-software.com or by mail at the
                  address above.
                </p>
                <p>
                  <strong>
                    3 Data processing when visiting our platform
                  </strong>
                  The scope and type of collection, processing, and use of your personal data differ depending on
                  whether you are interested in or register for an event via our platform in your personal customer
                  area, make a research request, or use your personal customer area on our platform purely for
                  information. With regard to the data processing processes described below, you can assert your rights
                  as a data subject at any time (see section 6).
                </p>
                <p>
                  <strong>
                    3.1 Collection of data with your participation
                  </strong>
                </p>
                <p>
                  <strong>
                    3.1.1 Interest in or participation in an event
                  </strong>
                  If you are interested in or would like to participate in a local or virtual event via our personal
                  customer area, we will process your personal data in this context. Depending on the selected event,
                  this includes, in particular, your master data stored when you registered for the customer account. If
                  you are not registered on our platform and register for an event, the processing of the following
                  personal data is required:
                  <ul>
                    <li>
                      First and last name
                    </li>
                    <li>
                      Institution
                    </li>
                    <li>
                      Email address
                    </li>
                    <li>
                      Telephone number
                    </li>
                  </ul>
                </p>
                <p>
                  This personal data is processed exclusively for the purpose of organizing and conducting the
                  respective event (legal basis: Art. 6 Para. 1 (b) GDPR) and for the provision of your information to
                  the issuers also after the event (see section 3.1.2.1).
                </p>
                <p>
                  Further information on processing your personal data provided during the registration process can also
                  be found at: <a
                  href="https://airtime-software.com/privacy" target="_blank"
                  rel="noopener noreferrer">https://airtime-software.com/privacy</a>.
                </p>
                <p>
                  The data concerning you will be deleted if it is no longer required for the respective purpose or if
                  you have objected to the data processing (Art. 21 GDPR), and we are not legally obliged or entitled to
                  store your personal data.
                </p>
                <p>
                  <strong>
                    3.1.2 Organization and implementation of an event
                  </strong>
                  As part of the organization and implementation of an event, it is necessary for us to share your
                  personal data as a registered participant of this event with third parties.
                </p>
                <p>
                  <strong>
                    3.1.2.1 Transmission of information to issuers
                  </strong>
                  As hosts of the platform and event organizer, we will also share your personal data with issuers.
                  Issuers are contractually obliged to treat the information they receive confidentially and to use it
                  only within the scope of the contractual purpose, i.e. within the scope of using the platform and to
                  conduct the events commissioned by the issuer. At the same time, you will receive all the information
                  provided by the issuer about the issuer and the event they have commissioned.
                </p>
                <p>
                  We share the following personal data with issuers:
                </p>
                <p>
                  If you register via our platform as a participant in an issuer's event and/or access research
                  publications from an issuer, the personal data that you have stored in your profile (e.g. profile
                  photo, first and last name, company, position, email address, telephone number, and information about
                  the event you participated in) is also shared via the platform with the respective issuer of the event
                  or the issuer of the research publication. In the form of histories, the issuer also receives
                  information about which other events of the issuer you have already taken part in or which research
                  publications of the issuer you have accessed. If you register for an event without being registered on
                  our platform, only the personal data you provide (see section 3.1.1.) will be shared with the issuer,
                  and after the event has taken place, histories are also made available for the issuer.
                </p>
                <p>
                  The disclosure of your personal data in the context of preparing and implementing events and providing
                  our platform services is therefore carried out to provide the contractual service (Art. 6 Para. 1 (b)
                  GDPR). After the event, your personal data will be stored and made available on the basis of our
                  legitimate interest (legal basis: Art. 6 Para. 1 (f) GDPR) in being able to offer the issuer and you a
                  comprehensive service. This also includes the presentation of the events that have taken place and the
                  storage of the participant lists. The respective issuer thus has the opportunity to contact you as a
                  participant again at a later point in time. If you do not want your personal data to be stored on the
                  platform after the event has taken place, you can object to the processing at any time, e.g. by
                  sending an email to office@airtime-software.com (Art. 21 GDPR).
                </p>
                <p>
                  <strong>
                    3.1.2.2 Service providers for the implementation of events
                  </strong>
                  In addition, it may be necessary for us to disclose your personal data and company data to service
                  providers (e.g. operators of the location for local events, service providers for the implementation
                  of a virtual event, etc.) who help organize the event in which you would like to participate.
                </p>
                <p>
                  The disclosure of the data to the respective service provider is necessary for the provision of the
                  contractual service and is only carried out to the extent necessary for this purpose. The legal basis
                  for the disclosure of data is Art. 6 Para. 1 (b) GDPR.
                </p>
                <p>
                  <strong>
                    3.1.2.3 Service providers for the implementation of virtual events
                  </strong>
                  We use the "Zoom" video conferencing system from Zoom Video Communications Inc., 55 Almaden Boulevard,
                  6th Floor, San Jose, CA 95113, USA ("Zoom") to hold virtual events. The video conference system is
                  embedded directly on our platform via iFrames, among other things. Alternatively, you can use a link
                  on our platform. In this case, you will be forwarded to the Zoom platform, which is directly
                  responsible for processing your data.
                </p>
                <p>
                  Various types of data are processed when using Zoom. The scope of the data also depends on what
                  information you provide before or when participating in virtual events.
                </p>
                <p>
                  The following personal data may be the subject of processing by Zoom when used:
                  <ul>
                    <li>
                      User information: first name, last name, telephone (optional), email address, password, profile
                      data such as user name (optional)
                    </li>
                    <li>
                      Meeting metadata: topic, description (optional), participant IP addresses, device/hardware
                      information
                    </li>
                    <li>
                      for recordings (optional): MP4 file of all video, audio, and presentation recordings, M4A file of
                      all audio recordings, text file of the online meeting chat
                    </li>
                    <li>
                      when dialling in by telephone: details of the incoming and outgoing number, country name, start
                      and end time; If necessary, further connection data such as the IP address of the device can be
                      stored.
                    </li>
                    <li>
                      Text, audio and video data: You may have the opportunity to use chat, question, or poll functions
                      during a virtual event. In this context, the text you enter will be processed to display in the
                      virtual event and logged if necessary. To enable the display of video and the playback of audio,
                      the data from your end device's microphone and any video camera on the end device are processed
                      accordingly for the duration of the event. You can switch off or mute the camera or microphone
                      anytime via the "Zoom" application.
                    </li>
                  </ul>
                </p>
                <p>
                  To participate in a virtual event via Zoom, you must regularly provide at least your name. You may
                  need to create a user account with the respective provider. In this case, the provider regularly uses
                  the customer data for their own purposes.
                </p>
                <p>
                  If we want to record virtual events, we inform you transparently in advance and – if required – ask
                  for your consent (legal basis Art. 6 Para. 1 (a) GDPR). The fact that the virtual event is recorded
                  will also be displayed to you during the event. The recorded virtual event is then made available to
                  the issuer for use and publication on the platform.
                </p>
                <p>
                  In the case of virtual events, we can also process the questions asked by participants for the purpose
                  of recording and following up on this event.
                </p>
                <p>
                  The legal basis for data processing when holding virtual events is Art. 6 Para. 1 (b) GDPR, insofar as
                  the events are held within the framework of contractual relationships.
                </p>
                <p>
                  Since Zoom is a provider from the USA, your personal data is regularly processed in a third country.
                  For this reason, we have concluded an order processing contract with Zoom that meets the requirements
                  of Art. 28 GDPR and ensures a data protection level corresponding to the EU standard contractual
                  clauses (Art. 46 Para. 2 (c) GDPR). According to this contract, Zoom has committed itself to comply
                  with European data protection regulations and guarantees an appropriate level of data protection.
                </p>
                <p>
                  The communication-related metadata will be deleted as soon as the storage is no longer required to
                  provide or maintain the service.
                </p>
                <p>
                  Further information on the purpose and scope of data collection and its processing by Zoom can be
                  found in Zoom's data privacy statement at: <a href="https://zoom.us/de-de/privacy.html"
                                                                target="_blank"
                                                                rel="noopener noreferrer">https://zoom.us/de-de/privacy.html</a>.
                </p>
                <p>
                  <strong>
                    3.1.3 Contact initiation on the part of Airtime after your registration
                  </strong>
                  If you participated in an event and provided your email address, we can also use it to send emails to
                  obtain feedback or to extend invitations to future events. The legal basis for sending these emails is
                  Art. 7 Para. 3 Unfair Competition Act (UC) and Art. 6 Para. 1 (f) GDPR. In this case, you can
                  unsubscribe from the distribution list for these emails at any time (Art. 21 GDPR). To do this, either
                  click the unsubscribe link in the respective email or send us an email to office@airtime-software.com.
                </p>
                <p>
                  <strong>
                    3.2 Collection of information without your participation
                  </strong>
                  We collect and use personal data generated automatically by your visit to our platform for the
                  technical provision of our platform. When using our platform and logging into the personal customer
                  area, our server temporarily lists the following personal data in so-called log files:
                  <ul>
                    <li>
                      IP address assigned by the provider
                    </li>
                    <li>
                      File request from the client (file name and URL)
                    </li>
                    <li>
                      HTTP status code
                    </li>
                    <li>
                      the website from which you are visiting us.
                    </li>
                  </ul>
                </p>
                <p>
                  This data is technically necessary for the functioning and operation of our platform. In addition, the
                  short-term storage of log files is useful to subsequently track attempts to attack the web server or
                  any misuse. Our legitimate interest in this data processing is, therefore, the provision of our offer
                  to you on the basis of these circumstances (legal basis: Art. 6 Para. 1 (f) GDPR). The data will be
                  deleted when it is no longer required for this.
                </p>
                <p>
                  In addition, we use so-called "cookies" in several places on our platform, which serve to make our
                  offer more user-friendly and effective. Cookies are small text files our platform places on your
                  computer or other internet-enabled devices, such as tablets or smartphones, provided your browser
                  settings accept cookies.
                </p>
                <p>
                  Cookies do not damage your computer and do not contain viruses. Most of the cookies used on the
                  platform are so-called session cookies, which are automatically deleted once you close your browser.
                  They create a so-called session ID for your browser, with which various requests from your browser can
                  be assigned to a session so that you are recognized when you return to our platform with the browser.
                  The session cookies are deleted when you close the browser. You can set your browser so that these
                  cookies are not saved in the first place or that the cookies are deleted at the end of your internet
                  session. Please note, however, that in this case, you may not be able to use all the functions of our
                  platform.
                </p>
                <p>
                  You can object to the future processing of your data using cookies at any time (Art. 21 GDPR). Please
                  note, however, that you may no longer be able to use all the functionalities of our platform.
                </p>
                <p>
                  <strong>
                    4 Sharing data with processors
                  </strong>
                  We must share some data with third parties in strict compliance with applicable data protection laws.
                </p>
                <p>
                  <strong>
                    4.1 For content-technical support and design of our online presence
                  </strong>
                  For the content and technical support of our platform, external service providers must have access to
                  personal data (in particular, IT service providers).
                </p>
                <p>
                  In this case, your personal data will be handled exclusively in accordance with our express
                  instructions and on the basis of an agreement on order processing in accordance with Art. 28 GDPR.
                  With this agreement, the service provider guarantees that the service provision is in accordance with
                  applicable data protection laws. The involvement of professional providers of corresponding services
                  is expressly provided for by law and serves our legitimate interest in professionalizing our offer for
                  you and being able to offer it in an economically sensible way (legal basis: Art. 6 Para. 1 (f) GDPR).
                  In this case, too, we remain responsible for the protection of your data.
                </p>
                <p>
                  In this context, we disclose your data to the following service providers:
                  <ul>
                    <li>
                      Regarding the content of our platform:<br/>
                      Backslash Seven GmbH, Großbeerenstr. 12, 10963 Berlin (Deutschland)<br/>
                      Further information on data protection at Backslash Seven GmbH can be found here: <a
                      href="https://backslashseven.de/datenschutz" target="_blank"
                      rel="noopener noreferrer">https://backslashseven.de/datenschutz</a>
                    </li>
                    <li>
                      To provide our management platform and store your data:<br/>
                      Citrix Systems Inc., 851 West Cypress Creek Road Fort Lauderdale, FL 33309 USA<br/>
                      Further information on data protection at Citrix Systems Inc. can be found here: <a
                      href="https://www.citrix.com/de-de/about/legal/privacy/" target="_blank"
                      rel="noopener noreferrer">https://www.citrix.com/de-de/about/legal/privacy/</a>
                    </li>
                    <li>
                      For hosting our platform:<br/>
                      domainfactory GmbH, Oskar-Messter-Str. 33, 85737 Ismaning (Deutschland)<br/>
                      Further information on data protection at domainfactory GmbH can be found here: <a
                      href="https://www.df.eu/de/datenschutz/" target="_blank"
                      rel="noopener noreferrer">https://www.df.eu/de/datenschutz/</a> <br/>
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>
                    4.2 Disclosure due to legal obligation
                  </strong>
                  We also reserve the right to disclose your personal data if we are demonstrably legally obliged to do
                  so or if the authorities or law enforcement agencies require us to release it in a legally compliant
                  manner.
                </p>
                <p>
                  <strong>
                    5 Place of data processing and data security
                  </strong>
                  The processing of your data takes place mainly in Germany. Your data will only be transferred to a
                  country outside the European Union or the European Economic Area if there is an appropriate level of
                  protection within the meaning of Art. 45 GDPR et seq. or if there are suitable guarantees within the
                  meaning of Art. 46 Para. 2 GDPR. Data collected on our site may be forwarded to the United States
                  (e.g. Citrix Systems Inc.) on this basis. To protect your data from unauthorized access and misuse, we
                  have taken extensive, state-of-the-art technical and organizational security precautions in accordance
                  with European data protection law (Art. 32 GDPR).
                </p>
                <p>
                  <strong>
                    6 Data subject rights
                  </strong>
                  You can contact us by email (office@airtime-software.com) anytime to exercise the following rights.
                </p>
                <p>
                  Right to information: You can request information about the scope, origin, and recipient of the stored
                  data and the purpose of storage at any time, free of charge (Art. 15 GDPR). If you wish to exercise
                  your right to information, you can contact a Airtime employee anytime.
                </p>
                <p>
                  Right to data portability: You can receive the personal data that you have provided to us in a
                  structured, common, and machine-readable format (Art. 20 GDPR), provided that (1) the processing is
                  based on consent in accordance with Art. 6 Para. 1 (a) GDPR or Art. 9 Para. 2 (a) GDPR or on a
                  contract under Art. 6 Para. 1 (b) GDPR and (2) the processing is conducted using automated procedures.
                </p>
                <p>
                  Right to rectification: Every person affected by the processing of personal data has the right to
                  demand the immediate correction of incorrect personal data concerning them (Art. 16 GDPR).
                  Furthermore, the data subject has the right, taking into account the purposes of the processing, to
                  request the completion of incomplete personal data.
                </p>
                <p>
                  Right to delete (right to be forgotten): Every person affected by the processing of personal data has
                  the right to demand that the person responsible delete the personal data concerning them immediately
                  if one of the following reasons applies and if the processing is not necessary (Art. 17 GDPR): (1) The
                  personal data was collected or otherwise processed for purposes for which they are no longer
                  necessary. (2) The data subject revokes their consent on which the processing was based, and there is
                  no other legal basis for the processing. (3) The data subject objects to the processing, and there are
                  no overriding legitimate grounds for the processing. (4) The personal data was processed unlawfully.
                  (5) The deletion of personal data is necessary to fulfil a legal obligation.
                </p>
                <p>
                  Right to objection: Any person affected by the processing of personal data has the right to object at
                  any time to the processing of personal data concerning them (Art. 21 GDPR).
                </p>
                <p>
                  We no longer process the personal data in the event of an objection unless we can demonstrate
                  compelling legitimate grounds for the processing that outweigh the interests, rights, and freedoms of
                  the data subject, or the processing serves to assert, exercise, or defend legal claims. If we process
                  personal data to operate direct advertising, the person concerned has the right to object to the
                  processing of personal data for the purpose of such advertising at any time.
                </p>
                <p>
                  Right to revoke consent under data protection law: Any person affected by the processing of personal
                  data has the right to revoke consent to the processing of personal data at any time (Art. 7 Para. 3
                  GDPR).
                </p>
                <p>
                  Right to lodge a complaint with a supervisory authority: Without prejudice to any other administrative
                  or judicial remedy, you have the right to lodge a complaint with a supervisory authority, in
                  particular in the member state where you live, work, or where the alleged infringement took place if
                  you believe that the processing of your personal data violates the GDPR (Art. 77 GDPR).
                </p>
                <p>
                  When asserting your claims against us, we will examine this claim and comply with it if there is no
                  other legal basis for further processing. We will inform you about the result.
                </p>
                <p>
                  As a rule, compliance with a certain form is not required to assert your rights as a data subject;
                  write to us, for example, by email at office@airtime-software.com.
                </p>
                <p>
                  <strong>
                    7 Updates and changes
                  </strong>
                  We may change or update parts of the data privacy statement without informing you in advance. Please
                  review the data privacy statement in each case before using our offer to be up to date with possible
                  changes or updates.
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default withLocalize(DataPrivacy);
