import axios from 'axios';

class LandingPageApi {
    static get = (token) => {
        return axios.get(`${process.env.REACT_APP_API_URL}landingPage/${token}`);
    }

    static apply = (token, firstName, lastName, position, institution, email, phone, language, registerInvitations) => {
        return axios.post(`${process.env.REACT_APP_API_URL}landingPage/apply`, {
            token,
            firstName,
            lastName,
            position,
            institution,
            email,
            phone,
            language,
            registerInvitations
        });
    }

    static validateEmail = (email, token) => {
        return axios.post(`${process.env.REACT_APP_API_URL}landingPage/validateEmail`, {
            email,
            token
        });
    }
}

export default LandingPageApi;
