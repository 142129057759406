import React from 'react';
import {Route, Switch} from "react-router";
import {ROUTE_DEPRECATED_LANDING_PAGE, ROUTE_LANDING_PAGE} from "../../config/routes";
import LandingPage from "./LandingPage";

class Landing extends React.Component {
    render() {
        return (
            <div className="Landing">
                <Switch>
                    <Route exact path={ROUTE_LANDING_PAGE} component={LandingPage}/>
                    <Route exact path={ROUTE_DEPRECATED_LANDING_PAGE} component={LandingPage}/>
                </Switch>
            </div>
        );
    }
}

export default Landing;
