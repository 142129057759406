import {
  PROFILE_LOAD_SUCCESS, PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_EVENTS_SET_FILTER_TYPE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_SET_FILTER_TYPE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_ADD_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_ADD_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_REMOVE_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_REMOVE_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_ADD_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_REMOVE_ACTIVE
} from "../../config/actions";

let defaultState = {
  loaded: false,
  name: '',
  institution: '',
  position: '',
  email: '',
  itemId: null,
  mailingLists: [],
  companyFilters: null,
  companyFiltersEvents: null,
  views: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        name: action.name,
        institution: action.institution,
        position: action.position,
        email: action.email,
        itemId: action.itemId,
        mailingLists: action.mailingLists,
        companyFilters: action.companyFilters,
        companyFiltersEvents: action.companyFiltersEvents,
        views: action.views
      }
    case PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_ADD_ACTIVE:
      return {
        ...state,
        mailingLists: {
          ...state.mailingLists,
          active: [...state.mailingLists.active, action.ml]
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_REMOVE_ACTIVE:
      return {
        ...state,
        mailingLists: {
          ...state.mailingLists,
          active: state.mailingLists.active?.filter(ml => ml.id !== action.ml.id)
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_ADD_ACTIVE:
      return {
        ...state,
        companyFilters: {
          ...state.companyFilters,
          companies: {
            ...state.companyFilters.companies,
            active: [...state.companyFilters.companies.active, action.c]
          }
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_REMOVE_ACTIVE:
      return {
        ...state,
        companyFilters: {
          ...state.companyFilters,
          companies: {
            ...state.companyFilters.companies,
            active: state.companyFilters.companies.active?.filter(c => c.id !== action.c.id)
          }
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_SET_FILTER_TYPE:
      return {
        ...state,
        companyFilters: {
          ...state.companyFilters,
          filterTypes: {
            ...state.companyFilters.filterTypes,
            active: [action.ft]
          }
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_ADD_ACTIVE:
      return {
        ...state,
        companyFiltersEvents: {
          ...state.companyFiltersEvents,
          companies: {
            ...state.companyFiltersEvents.companies,
            active: [...state.companyFiltersEvents.companies.active, action.c]
          }
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_REMOVE_ACTIVE:
      return {
        ...state,
        companyFiltersEvents: {
          ...state.companyFiltersEvents,
          companies: {
            ...state.companyFiltersEvents.companies,
            active: state.companyFiltersEvents.companies.active?.filter(c => c.id !== action.c.id)
          }
        }
      }
    case PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_EVENTS_SET_FILTER_TYPE:
      return {
        ...state,
        companyFiltersEvents: {
          ...state.companyFiltersEvents,
          filterTypes: {
            ...state.companyFiltersEvents.filterTypes,
            active: [action.ft]
          }
        }
      }
    default:
      return state;
  }
}
