import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import NotificationSettingsMailingLists from "./NotificationSettingsMailingLists";
import NotificationSettingsFilteredCompanies from "./NotificationSettingsFilteredCompanies";
import {useParams} from "react-router";
import NotificationSettingsFilteredCompaniesEvents from "./NotificationSettingsFilteredCompaniesEvents";
import {useSelector} from "react-redux";

const NotificationSettings = () => {

  const profileState = useSelector(state => state.profile);

  const [open, setOpen] = useState(false);
  const [openedByUrl, setOpenedByUrl] = useState(false);
  const [selectedTab, setSelectedTab] = useState('mailingLists');

  const handleOpen = () => {
    setSelectedTab('mailingLists');
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  let {popup} = useParams();

  useEffect(() => {
    if (!open && !openedByUrl && popup && popup === 'notificationSettings') {
      setOpenedByUrl(true);
      setOpen(true);
    }
  }, [open, openedByUrl, popup]);

  return (
    <Fragment>
      <button className="NotificationSettings-button" onClick={handleOpen}>
        <Translate id="profile.notificationSettings.button"/>
      </button>
      <Overlay open={open} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
        <div className="NotificationSettings-dialog-outer">
          <div className="NotificationSettings-dialog">
            <div className="header header-close text-right d-md-none">
              <button className="close-button close-button-sm" onClick={handleClose}>
                <Translate id="profile.notificationSettings.dialog.close"/>
              </button>
            </div>
            <div className="header">
              <button className="tab-button" onClick={() => setSelectedTab('mailingLists')} disabled={selectedTab === 'mailingLists'}>
                <Translate id="profile.notificationSettings.dialog.tab.mailingLists"/>
              </button>
              {profileState?.views?.viewResearch && (
                <button className="tab-button" onClick={() => setSelectedTab('filteredCompanies')}
                        disabled={selectedTab === 'filteredCompanies'}>
                  <span className="d-md-none">
                    <Translate id="profile.notificationSettings.dialog.tab.filteredCompaniesShort"/>
                  </span>
                  <span className="d-none d-md-inline">
                    <Translate id="profile.notificationSettings.dialog.tab.filteredCompanies"/>
                  </span>
                </button>
              )}
              {profileState?.views?.viewEvents && (
                <button className="tab-button" onClick={() => setSelectedTab('filteredCompaniesEvents')} disabled={selectedTab === 'filteredCompaniesEvents'}>
                  <span className="d-md-none">
                    <Translate id="profile.notificationSettings.dialog.tab.filteredCompaniesEventsShort"/>
                  </span>
                  <span className="d-none d-md-inline">
                    <Translate id="profile.notificationSettings.dialog.tab.filteredCompaniesEvents"/>
                  </span>
                </button>
              )}
              <button className="close-button d-none d-md-flex" onClick={handleClose}>
                <Translate id="profile.notificationSettings.dialog.close"/>
              </button>
            </div>
            <div className="NotificationSettings-content">
              {selectedTab === 'mailingLists' && (
                <NotificationSettingsMailingLists/>
              )}
              {profileState?.views?.viewResearch && selectedTab === 'filteredCompanies' && (
                <NotificationSettingsFilteredCompanies/>
              )}
              {profileState?.views?.viewEvents && selectedTab === 'filteredCompaniesEvents' && (
                  <NotificationSettingsFilteredCompaniesEvents/>
              )}
            </div>
          </div>
        </div>
      </Overlay>
    </Fragment>
  )
};

export default NotificationSettings;