import React from 'react';

const Issuer = ({issuer}) => {

    const imagePlaceholderForName = (name) => {
        if (!name || name.length < 2) return '';
        const parts = name.split(' ');
        if (parts.length >= 2) {
            return `${parts[0][0]}${parts[parts.length - 1][0]}`
        } else {
            return `${parts[0][0]}${parts[0][1]}`
        }
    }

    return (
        <div className="Issuer">
            <div className="row">
                <div className="col-auto pr-0">
                    {issuer.image ? (
                        <img src={`${process.env.REACT_APP_API_URL}landingPage/image?uuid=${issuer.image.uuid}`} alt="" className="image"/>
                    ) : (
                        <div className="imagePlaceholder">
                            {imagePlaceholderForName(issuer.name)}
                        </div>
                    )}
                </div>
                <div className="col pl-3 d-flex justify-content-center flex-column">
                    <h4>
                        {issuer.name}
                    </h4>
                    <div className="position">
                        {issuer.position}
                    </div>
                </div>
            </div>
            {issuer.text ? (
                <div className="text" dangerouslySetInnerHTML={{__html: issuer.text}}/>
            ) : null}
        </div>
    )
}

export default Issuer;