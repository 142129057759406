import React, {Fragment} from 'react';
import {Translate} from "react-localize-redux";
import {Overlay} from "react-portal-overlay";
import EventsApi from "../../../api/EventsApi";
import {connect} from "react-redux";
import {eventsLoadSuccess} from "../../../store/events/eventsAction";
import moment from "moment";
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import EventBus from "../../../elements/EventBus";

class ParticipateButton extends React.Component {

    handleCloseDialogTimeout = null;

    state = {
        dialogOpen: false,
        successLoading: false,
        successDialogOpen: false,
        singleMeetings: [],
        groupMeetings: [],
        viewType: null,
        selectedSlot: null,
        companies: [],
        selectedCompanies: [],
        flow: null,
        navigationIsOpen: false,
        sendToStateAt: moment()
    };

    componentDidMount() {
        EventBus.on('brandClick', this.closeDialog);
    }

    componentWillUnmount() {
        EventBus.remove('brandClick', this.closeDialog);
    }

    getFlow = () => {
        const {event, isCounterProposal} = this.props;
        let flow = 'simple';
        if (event.type === 'roadshow') {
            if(isCounterProposal) flow = 'simple';
            else flow = 'roadshow';
        }
        if (event.type === 'conference') flow = 'conference';
        return flow;
    }

    startParticipating = () => {
        const {event, isCounterProposal, appId, itemId} = this.props;
        let flow = this.getFlow();
        this.setState({flow});
        if (flow === 'simple') {
            this.setState({successLoading: true})
            EventsApi.participate(isCounterProposal ? appId : event.appId, isCounterProposal ? itemId : event.itemId)
                .then((response) => {
                    if (response.data.success) {
                        this.setState({successDialogOpen: true, successLoading: false, sendToStateAt: moment().add(5, 'seconds')});
                        this.loadEventsFromApi();
                        this.handleCloseDialogTimeout = setTimeout(this.closeSuccessDialog, 5000);
                    }
                });
        } else if (flow === 'roadshow') {
            let singleMeetings = event.slots.filter(el => !el.groupMeeting);
            let groupMeetings = event.slots.filter(el => el.groupMeeting);
            this.setState({
                dialogOpen: true,
                singleMeetings,
                groupMeetings,
                viewType: singleMeetings && singleMeetings.length > 0 ? 'singleMeetings' : 'groupMeetings'
            })
        }  else if (flow === 'conference') {
            this.setState({
                dialogOpen: true,
                companies: event.companies,
                viewType: (event.userApplyingCompanies && event.userApplyingCompanies.length > 0) ? 'singleMeetings' : 'groupMeetings',
                selectedCompanies: event.userApplyingCompanies ? event.userApplyingCompanies.map(el => el.itemId): []
            })
        }
    };

    doParticipate = () => {
        const {event} = this.props;
        let flow = this.getFlow();
        if (flow === 'roadshow') {
            this.setState({successLoading: true})
            EventsApi.participate(event.slots[0].appId, this.state.selectedSlot)
                .then((response) => {
                    if (response.data.success) {
                        this.closeDialog();
                        this.setState({
                            successDialogOpen: true,
                            successLoading: false,
                            sendToStateAt: moment().add(5, 'seconds')
                        });
                        this.loadEventsFromApi();
                        this.handleCloseDialogTimeout = setTimeout(this.closeSuccessDialog, 5000);
                    }
                });
        } else if (flow === 'conference') {
            this.setState({successLoading: true})
            if(this.state.viewType === 'singleMeetings') {
                EventsApi.participate(event.companies[0].appId, this.state.selectedCompanies?.join(','))
                    .then((response) => {
                        if (response.data.success) {
                            this.closeDialog();
                            this.setState({
                                successDialogOpen: true,
                                successLoading: false,
                                sendToStateAt: moment().add(5, 'seconds')
                            });
                            this.loadEventsFromApi();
                            this.handleCloseDialogTimeout = setTimeout(this.closeSuccessDialog, 5000);
                        }
                    });
            } else {
                EventsApi.participate(event.appId, event.itemId)
                    .then((response) => {
                        if (response.data.success) {
                            this.closeDialog();
                            this.setState({
                                successDialogOpen: true,
                                successLoading: false,
                                sendToStateAt: moment().add(5, 'seconds')
                            });
                            this.loadEventsFromApi();
                            this.handleCloseDialogTimeout = setTimeout(this.closeSuccessDialog, 5000);
                        }
                    });
            }
        }
    }

    closeDialog = () => {
        this.setState({
            dialogOpen: false,
            successLoading: false,
            singleMeetings: [],
            groupMeetings: [],
            viewType: null,
            selectedSlot: null,
            companies: [],
            selectedCompanies: [],
            flow: null
        });
    }

    closeSuccessDialog = () => {
        this.setState({successDialogOpen: false, sendToStateAt: moment()});
    }

    async loadEventsFromApi() {
        EventsApi.get()
            .then(async response => {
                if (response.status === 200) {
                    if(this.state.sendToStateAt) {
                        while (moment().isBefore(this.state.sendToStateAt)) {
                            await new Promise(resolve => setTimeout(resolve, 100));
                        }
                    }
                    this.props.eventsLoadSuccess(response.data.data);
                }
            })
    };

    toggleNavigation = () => this.setState({ navigationIsOpen: !this.state.navigationIsOpen });

    toggleCompany = (itemId) => {
        let {selectedCompanies} = this.state;
        if(selectedCompanies.includes(itemId)) {
            this.setState({
                selectedCompanies: selectedCompanies.filter(el => el !== itemId)
            });
        } else {
            this.setState({
                selectedCompanies: [...selectedCompanies, itemId]
            });
        }
    }

    render() {
        const {event, isCounterProposal} = this.props;
        return (
            <Fragment>
                <button className={`${this.props.className}${event.closed ? ' btn-disabled' : ''}${this.state.successLoading ? ' loading' : ''}`}
                        disabled={event.closed} onClick={() => !event.closed ? this.startParticipating() : null}>
                    {event.closed ? (
                        <Translate id="meeting.participate.closed"/>
                    ) : (
                        this.props.children
                    )}
                </button>
                <Overlay open={this.state.dialogOpen || this.state.successDialogOpen} animation={{duration: 100, easing: 'ease'}} className="overlay-container" onClick={() => this.state.successDialogOpen ? this.closeSuccessDialog() : null}>
                    {this.state.dialogOpen ? (
                        <div className="participate-dialog-outer">
                            <div className="participate-dialog">
                                <button onClick={this.closeDialog}>
                                    <Translate id="meeting.participate.dialog.cancel"/>
                                </button>
                                <div className={`participate-content ${this.state.flow === 'conference' ? 'conference' : ''}`}>
                                    {(this.state.flow === 'roadshow' && !isCounterProposal) ? (
                                        <Fragment>
                                            <h3>
                                                <Translate id="meeting.participate.dialog.roadshow.title"/>
                                            </h3>
                                            <h4>
                                                {event.title}
                                            </h4>
                                            {this.state.singleMeetings && this.state.singleMeetings.length > 0 ? (
                                                <button onClick={() => this.setState({viewType: 'singleMeetings', selectedSlot: null})} className={`${this.state.viewType === 'singleMeetings' ? 'active' : ''}`}>
                                                    <Translate id="meeting.participate.dialog.roadshow.viewType.singleMeetings"/>
                                                </button>
                                            ) : null}
                                            {this.state.groupMeetings && this.state.groupMeetings.length > 0 ? (
                                                <button onClick={() => this.setState({viewType: 'groupMeetings', selectedSlot: null})} className={`${this.state.viewType === 'groupMeetings' ? 'active' : ''}`}>
                                                    <Translate id="meeting.participate.dialog.roadshow.viewType.groupMeetings"/>
                                                </button>
                                            ) : null}
                                            <div className="slots">
                                                <div className="d-none d-md-inline-block w-100">
                                                    {(this.state.viewType === 'singleMeetings' ? this.state.singleMeetings : this.state.groupMeetings).map((slot, sI) => (
                                                        <Fragment key={sI}>
                                                            <div onClick={() => this.setState({selectedSlot: slot.closed ? this.state.selectedSlot : (this.state.selectedSlot === slot.itemId ? null : slot.itemId)})} className={`slot ${this.state.selectedSlot === slot.itemId ? 'selected' : ''}${slot.closed ? ' disabled' : ''}`} data-tip={slot.closed ? 'React-tooltip' : false} data-for={slot.closed ? `tooltip-${sI}` : null}>
                                                                <Translate id="meeting.from"/>&nbsp;{moment(slot.date.start).format('LT')}<br/>
                                                                <Translate id="meeting.until"/>&nbsp;{moment(slot.date.end).format('LT')}<br/>
                                                            </div>
                                                            {slot.closed ? (
                                                                <ReactTooltip id={`tooltip-${sI}`}>
                                                                    <Translate id="meeting.participate.dialog.slotClosed"/>
                                                                </ReactTooltip>
                                                            ) : null}
                                                        </Fragment>
                                                    ))}
                                                </div>
                                                <div className="dropdown d-md-none" style={{marginTop: 30}}>
                                                    <Select options={(this.state.viewType === 'singleMeetings' ? this.state.singleMeetings : this.state.groupMeetings).map(slot => ({
                                                        value: slot.itemId,
                                                        label: `${moment(slot.date.start).format('LT')} - ${moment(slot.date.end).format('LT')}`,
                                                        isDisabled: slot.closed
                                                    }))}
                                                            value={(this.state.viewType === 'singleMeetings' ? this.state.singleMeetings : this.state.groupMeetings).map(slot => ({
                                                                value: slot.itemId,
                                                                label: `${moment(slot.date.start).format('LT')} - ${moment(slot.date.end).format('LT')}`
                                                            })).find(slot => this.state.selectedSlot === slot.value)}
                                                            onChange={(obj) => this.setState({selectedSlot: obj.value})}
                                                            placeholder="Uhrzeit wählen"
                                                            className="c-select-container"
                                                            key={this.state.viewType}
                                                            classNamePrefix="c-select"/>
                                                </div>
                                            </div>
                                            <button onClick={this.doParticipate} disabled={!this.state.selectedSlot} className={this.state.successLoading ? 'loading' : null}>
                                                <Translate id="meeting.participate.dialog.submitApply"/>
                                            </button>
                                        </Fragment>
                                    ) : this.state.flow === 'conference' ? (
                                        <Fragment>
                                            <h3>
                                                <Translate id="meeting.participate.dialog.conference.title"/>
                                            </h3>
                                            <h4>
                                                {event.title}
                                            </h4>
                                            <p className="infoText">
                                                <p>
                                                    <Translate id={`meeting.participate.dialog.conference.intro.${this.state.viewType}`}/>
                                                </p>
                                            </p>
                                            <button onClick={() => this.setState({viewType: 'groupMeetings'})} className={`${this.state.viewType === 'groupMeetings' ? 'active' : ''}`}>
                                                <Translate id="meeting.participate.dialog.conference.viewType.groupMeetings"/>
                                            </button>
                                            {this.state.companies && this.state.companies.length > 0 ? (
                                                <Fragment>
                                                    <button onClick={() => this.setState({viewType: 'singleMeetings'})} className={`${this.state.viewType === 'singleMeetings' ? 'active' : ''}`}>
                                                        <Translate id="meeting.participate.dialog.conference.viewType.singleMeetings"/>
                                                    </button>
                                                    {this.state.viewType === 'singleMeetings' ? (
                                                        <div className="slots">
                                                            {this.state.companies.map((company, cI) => (
                                                                <div key={cI} onClick={() => this.toggleCompany(company.itemId)} className={`slot d-none d-lg-flex ${this.state.selectedCompanies?.includes(company.itemId) ? 'selected' : ''}`}>
                                                                    {company.company.name}{company.representativeInfo ? ` (${company.representativeInfo})` : ''}
                                                                </div>
                                                            ))}

                                                            <div className="dropdown d-lg-none" style={{marginTop: 60}}>
                                                                <Select options={this.state.companies.map(company => ({
                                                                    value: company.itemId,
                                                                    label: `${company.company.name}${company.representativeInfo ? ` (${company.representativeInfo})` : ''}`
                                                                }))}
                                                                        value={this.state.companies.filter(company => this.state.selectedCompanies?.includes(company.itemId))?.map(company => ({
                                                                            value: company.itemId,
                                                                            label: `${company.company.name}${company.representativeInfo ? ` (${company.representativeInfo})` : ''}`
                                                                        }))}
                                                                        onChange={(value) => this.setState({selectedCompanies: value?.map(el => el.value)})}
                                                                        isMulti
                                                                        placeholder="Unternehmen wählen"
                                                                        className="c-select-container"
                                                                        classNamePrefix="c-select"/>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </Fragment>
                                            ) : null}
                                            <button onClick={this.doParticipate} disabled={(this.state.flow === 'roadshow' && !this.state.selectedSlot) || (this.state.flow === 'conference' && this.state.viewType === 'singleMeetings' && (!this.state.selectedCompanies || this.state.selectedCompanies?.length === 0))} className={this.state.successLoading ? 'loading' : null}>
                                                <Translate id="meeting.participate.dialog.submit"/>
                                            </button>
                                        </Fragment>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : this.state.successDialogOpen ? (
                        <div className="participate-success-dialog-outer">
                            <div className="participate-success-dialog">
                                {((event.type === 'roadshow' && !isCounterProposal) || event.type === 'conference') ? (
                                    <Fragment>
                                        <Translate id={`meeting.participate.dialog.success.applying.title`}
                                                   options={{renderInnerHtml: true}}/>
                                        <p className="text">
                                            <Translate id="meeting.participate.dialog.success.applying.text"/>
                                        </p>
                                    </Fragment>
                                ) : (
                                    <Translate id={`meeting.participate.dialog.success.default`}
                                               options={{renderInnerHtml: true}}/>
                                )}
                            </div>
                        </div>
                    ) : null}
                </Overlay>
            </Fragment>
        )
    }
}

export default connect(null, {
    eventsLoadSuccess
})(ParticipateButton);
