import {
  PROFILE_LOAD_SUCCESS, PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_EVENTS_SET_FILTER_TYPE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_SET_FILTER_TYPE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_ADD_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_ADD_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_REMOVE_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_REMOVE_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_ADD_ACTIVE,
  PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_REMOVE_ACTIVE
} from "../../config/actions";

export const profileLoadSuccess = (name, institution, position, email, itemId, mailingLists, companyFilters, companyFiltersEvents, views) => {
  return {
    type: PROFILE_LOAD_SUCCESS,
    name,
    institution,
    position,
    email,
    itemId,
    mailingLists,
    companyFilters,
    companyFiltersEvents,
    views
  }
};

export const profileNotificationSettingsMailingListAddActive = (ml) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_ADD_ACTIVE,
    ml
  }
};

export const profileNotificationSettingsMailingListRemoveActive = (ml) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_REMOVE_ACTIVE,
    ml
  }
};

export const profileNotificationSettingsFilteredCompanyAddActive = (c) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_ADD_ACTIVE,
    c
  }
};

export const profileNotificationSettingsFilteredCompanyRemoveActive = (c) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_REMOVE_ACTIVE,
    c
  }
};

export const profileNotificationSettingsFilteredCompaniesSetFilterType = (ft) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_SET_FILTER_TYPE,
    ft
  }
}

export const profileNotificationSettingsFilteredCompanyEventsAddActive = (c) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_ADD_ACTIVE,
    c
  }
};

export const profileNotificationSettingsFilteredCompanyEventsRemoveActive = (c) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_REMOVE_ACTIVE,
    c
  }
};

export const profileNotificationSettingsFilteredCompaniesEventsSetFilterType = (ft) => {
  return {
    type: PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_EVENTS_SET_FILTER_TYPE,
    ft
  }
}
