import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import gfx_logoMontega from '../../../gfx/logo_montega.svg';
import {Translate, withLocalize} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import PublicationsApi from "../../../api/PublicationsApi";

const PublicationWebView = ({activeLanguage}) => {
  let { token, fileLanguage } = useParams();
  let [publication, setPublication] = useState(null);

  useEffect(() => {
    if (token && !publication) {
      PublicationsApi.getWebView(token, fileLanguage)
        .then(response => {
          if (response.status === 200 && response.data.success) {
            setPublication(response.data.data);
          }
        })
    }
  }, [token, publication, activeLanguage]);

  return (
    <div className="PublicationWebView">
      <div className="header">
        <img src={gfx_logoMontega} alt="montega"/>
        <h2>
          <Translate id="publicationWebView.header.title"/>
        </h2>
      </div>
      <div className="content">
        {publication && (
          <div className="content-box">
            <h3 className="title">
              {publication.title}
            </h3>
            <div className="line-container">
              <BorderWithDot/>
            </div>
            <div className="content-text" dangerouslySetInnerHTML={{__html: publication.html}}/>
            <div className="content-footer" dangerouslySetInnerHTML={{__html: publication.footer}}/>
          </div>
        )}
      </div>
    </div>
  )
};

export default withLocalize(PublicationWebView);