import React, {Fragment} from 'react';
import iconWebTurquoise from "../../../gfx/web_icon_turquoise.svg";
import {Translate} from "react-localize-redux";
import GoogleMapsHelper from "../../../config/GoogleMapsHelper";
import {Overlay} from "react-portal-overlay";
import {connect} from "react-redux";
import EventBus from "../../../elements/EventBus";

class JoinButton extends React.Component {
  state = {
    dialogOpen: false
  };

  componentDidMount() {
    EventBus.on('brandClick', this.closeDialog);
  }

  componentWillUnmount() {
    EventBus.remove('brandClick', this.closeDialog);
  }

  showMapsLink = (event) => {
    if (event.type === 'fieldTrip') return true;
    if (['roundtable', 'conference', 'earningsCall', 'roadshow'].includes(event.type) && event.online !== true) return true;
    return false;
  };

  startJoin = () => {
    this.setState({dialogOpen: true});
  };

  closeDialog = () => {
    this.setState({dialogOpen: false});
  }

  render() {
    const {event, profile} = this.props;
    return (
      <Fragment>
        {/*{this.showMapsLink(event) ? (
          event.location && event.location.formatted ? (
            <a className={this.props.className}
               href={GoogleMapsHelper.getRouteLinkToAddress(event.location.formatted)} target="_blank"
               rel="noopener noreferrer">
              <Translate id="meeting.join.button.offline"/>
            </a>
          ) : null
        ) : event.type !== 'conference' ? (
          <button className={this.props.className} onClick={this.startJoin}>
            <img src={iconWebTurquoise} alt=""/>
            <Translate id="meeting.join.button.online"/>
          </button>
        ) : null}*/}
        {this.showMapsLink(event) || event.type !== 'conference' ? (
            <button className={this.props.className} onClick={this.startJoin}>
              {this.showMapsLink(event) ? (
                  <Translate id="meeting.join.button.offline"/>
              ) : (
                  <Fragment>
                    <img src={iconWebTurquoise} alt="" className="d-none d-lg-inline"/>
                    <Translate id="meeting.join.button.online"/>
                  </Fragment>
              )}
            </button>
        ) : null}
        <Overlay open={this.state.dialogOpen} animation={{duration: 100, easing: 'ease'}} className="overlay-container">
          <div className="join-dialog-outer">
            <div className="join-dialog">
              <button onClick={this.closeDialog}>
                <Translate id="meeting.join.dialog.back"/>
              </button>
              <div className="join-content">
                <h3>
                  <Translate id={`meeting.join.dialog.title1${this.showMapsLink(event) ? 'Address' : ''}`}/>&nbsp;<Translate
                  id={`meeting.join.dialog.title2${event.type}`}/>:
                </h3>
                <h4>
                  {event.title}
                </h4>
                <div className="join-content-info">
                  {this.showMapsLink(event) ? (
                      <div className="left">
                        <a href={GoogleMapsHelper.getRouteLinkToAddress(event.location.formatted)} target="_blank"
                           rel="noopener noreferrer" className="wider isMaps">
                          <Translate id="meeting.join.dialog.mapsLink"/>
                        </a>
                      </div>
                  ) : (
                      <div className="left">
                        {(/*event.zoomMeeting?.meetingNumber || */ event.zoomMeeting?.videoLink) && (
                            /*
                            <a href={`${process.env.REACT_APP_ZOOM_URL}${event.itemId}/${profile.itemId}`} target="_blank"
                               rel="noopener noreferrer">
                              <Translate id="meeting.join.dialog.videoLink"/>
                            </a>
                            */
                            <a href={`${event.zoomMeeting.videoLink}`} target="_blank" rel="noopener noreferrer">
                              <Translate id="meeting.join.dialog.videoLink"/>
                            </a>
                        )}
                      </div>
                  )}
                  {this.showMapsLink(event) ? (
                      <Fragment>
                        <div>
                          <p>
                            <div className="right isLocation" dangerouslySetInnerHTML={{__html: event.addressInformation}}/>
                          </p>
                          <p>
                            <div className="right isLocation"
                                 dangerouslySetInnerHTML={{__html: event.location.formatted?.replaceAll(',', '<br/>')}}/>
                          </p>
                        </div>
                      </Fragment>
                  ) : (
                      event.zoomMeeting?.accessData ? (
                          <div className="right" dangerouslySetInnerHTML={{__html: event.zoomMeeting?.accessData}}/>
                      ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
        </Overlay>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, null)(JoinButton);
