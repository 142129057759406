import React, {useEffect, useState} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {
  profileNotificationSettingsFilteredCompaniesEventsSetFilterType,
  profileNotificationSettingsFilteredCompanyEventsAddActive,
  profileNotificationSettingsFilteredCompanyEventsRemoveActive,
} from "../../../store/profile/profileAction";
import ProfileApi from "../../../api/ProfileApi";
import gfx_arrow from '../../../gfx/profile/arrow.svg';
import gfx_arrow_active from '../../../gfx/profile/arrow-active.svg';

const NotificationSettingsFilteredCompaniesEvents = ({translate}) => {
  const profileState = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const [showSuccessText, setShowSuccessText] = useState(false);
  const [showSuccessTextTimeout, setShowSuccessTextTimeout] = useState(null);
  const [visibleCompaniesEvents, setVisibleCompaniesEvents] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    let companies = profileState.companyFiltersEvents?.companies?.available;
    if (searchValue && searchValue.length > 0) {
      companies = companies.filter(c => c.name?.toLowerCase()?.includes(searchValue.toLowerCase()));
    }
    companies = companies.sort((a, b) => {
      if (sortDirection === 'desc') {
        let c = a;
        a = b;
        b = c;
      }
      if (sortColumn === 'name') {
        return a.name?.localeCompare(b.name);
      } else if (sortColumn === 'sector') {
        return a.sector?.map(s => s.name)?.join(', ')?.localeCompare(b.sector?.map(s => s.name)?.join(', '))
      } else if (sortColumn === 'marketCap') {
        return Number(a.marketCap?.amount) - Number(b.marketCap?.amount);
      } else {
        return 0;
      }
    })
    setVisibleCompaniesEvents([...companies]);
  }, [profileState.companyFiltersEvents?.companies?.available, searchValue, sortColumn, sortDirection])

  const toggleFilteredCompany = (c) => {
      if (!!profileState.companyFiltersEvents.companies?.active?.find(ac => ac.id === c.id)) {
        updateApi(profileState.companyFiltersEvents.companies?.active?.filter(ac => ac.id !== c.id)?.map(ac => ac.id));
        dispatch(profileNotificationSettingsFilteredCompanyEventsRemoveActive(c));
      } else {
        updateApi([...profileState.companyFiltersEvents.companies.active?.map(ac => ac.id), c.id]);
        dispatch(profileNotificationSettingsFilteredCompanyEventsAddActive(c));
      }
  }

  const updateApi = (activeCompanies) => {
    ProfileApi.updateNotificationSettings(profileState.mailingLists.active?.map(ml => ml.id), profileState.companyFilters.filterTypes.active?.map(ft => ft.id), profileState.companyFilters.companies.active?.map(c => c.id), profileState.companyFiltersEvents.filterTypes.active?.map(ft => ft.id), activeCompanies)
      .then(response => {
        setShowSuccessText(true);
        if (showSuccessTextTimeout) clearTimeout(showSuccessTextTimeout);
        let newShowSuccessTextTimeout = setTimeout(() => setShowSuccessText(false), 4000);
        setShowSuccessTextTimeout(newShowSuccessTextTimeout);
      })
  }

  const setFilterType = (ft) => {
    ProfileApi.updateNotificationSettings(profileState.mailingLists.active?.map(ml => ml.id), profileState.companyFilters.filterTypes.active?.map(ft => ft.id), profileState.companyFilters.companies.active?.map(c => c.id), [ft.id], profileState.companyFiltersEvents.companies.active?.map(c => c.id))
      .then(response => {
        setShowSuccessText(true);
        if (showSuccessTextTimeout) clearTimeout(showSuccessTextTimeout);
        let newShowSuccessTextTimeout = setTimeout(() => setShowSuccessText(false), 4000);
        setShowSuccessTextTimeout(newShowSuccessTextTimeout);
      })
    dispatch(profileNotificationSettingsFilteredCompaniesEventsSetFilterType(ft));
  }

  const toggleSort = (name) => {
    if (sortColumn === name) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(name);
      setSortDirection('asc');
    }
  }

  return (
    <div className="filteredCompanies">
      {!profileState.mailingLists?.active?.find(aml => aml.title === 'Events') ? (
            <div className={`researchDisabledText`}>
              <Translate id="profile.notificationSettings.dialog.researchDisabledTextEvents"/>
            </div>
        ) : null }
      <div className="filters">
        {profileState.companyFiltersEvents.filterTypes.available?.sort((a, b) => a.id - b.id)?.map((ft, ftI) => (
          <button key={ftI} type="button" className="button-filter" disabled={!!profileState.companyFiltersEvents.filterTypes.active.find(aft => aft?.id === ft.id)} onClick={() => setFilterType(ft)}>
            <Translate id={`profile.notificationSettings.dialog.filteredCompaniesEvents.filterType.${ft.name}`}/>
          </button>
        ))}
        <input type="text" value={searchValue} onChange={e => setSearchValue(e.target.value)}
               placeholder={translate('profile.notificationSettings.dialog.filteredCompaniesEvents.textSearchPlaceholder')}/>
      </div>
      <p className={`successText${!showSuccessText ? ' hidden' : ''}`}>
        <Translate id="profile.notificationSettings.dialog.filteredCompaniesEvents.successText"/>
      </p>
      <table>
        <thead>
        <tr>
          <th/>
          <th className={`sortable${sortColumn === 'name' ? ' active' : ''}${sortColumn === 'name' && sortDirection === 'desc' ? ' up' : ''}`} onClick={() => toggleSort('name')}>
            <Translate id="profile.notificationSettings.dialog.filteredCompaniesEvents.col.name"/>
            <img src={sortColumn === 'name' ? gfx_arrow_active : gfx_arrow} alt=""/>
          </th>
          <th className={`sortable${sortColumn === 'sector' ? ' active' : ''}${sortColumn === 'sector' && sortDirection === 'desc' ? ' up' : ''}`} onClick={() => toggleSort('sector')}>
            <Translate id="profile.notificationSettings.dialog.filteredCompaniesEvents.col.sector"/>
            <img src={sortColumn === 'sector' ? gfx_arrow_active : gfx_arrow} alt=""/>
          </th>
          <th className={`sortable${sortColumn === 'marketCap' ? ' active' : ''}${sortColumn === 'marketCap' && sortDirection === 'desc' ? ' up' : ''}`} onClick={() => toggleSort('marketCap')}>
            <Translate id="profile.notificationSettings.dialog.filteredCompaniesEvents.col.marketCap"/>
            <img src={sortColumn === 'marketCap' ? gfx_arrow_active : gfx_arrow} alt=""/>
          </th>
        </tr>
        </thead>
        <tbody>
        {visibleCompaniesEvents?.map((c) => (
          <tr key={c.id}>
            <td className="col-checkbox">
              <input type="checkbox" id={`c-${c.id}-cb`}
                     checked={!!profileState.companyFiltersEvents?.filterTypes?.active?.find(ft => ft.id === 1) ? !profileState.companyFiltersEvents?.companies?.active?.find(ac => ac.id === c.id) : !!profileState.companyFiltersEvents?.companies?.active?.find(ac => ac.id === c.id)}
                     onChange={() => toggleFilteredCompany(c)}/>
              <label htmlFor={`c-${c.id}-cb`}>
                <span className="off">
                  <Translate id="profile.notificationSettings.dialog.checkbox.off"/>
                </span>
                <span className="on">
                  <Translate id="profile.notificationSettings.dialog.checkbox.on"/>
                </span>
              </label>
            </td>
            <td className="col-text">
              {c.name}
              <div className="d-md-none">
                <div>
                  {c.sector?.map(s => s.name)?.join(', ')}
                </div>
                <div>
                  {c.marketCap?.formatted}
                </div>
              </div>
            </td>
            <td className="col-text col-sector d-none d-md-table-cell">
              {c.sector?.map(s => s.name)?.join(', ')}
            </td>
            <td className="col-text col-marketCap d-none d-md-table-cell">
              {c.marketCap?.formatted}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
};

export default withLocalize(NotificationSettingsFilteredCompaniesEvents);
