import axios from 'axios';

class ProfileApi {

    static get = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}profile/get`)
    };

    static updatePassword = (oldPassword, password, password2) => {
        return axios.put(`${process.env.REACT_APP_API_URL}profile/password`, {
            oldPassword,
            password,
            password2
        });
    };

    static updateNotificationSettings = (mailingListIds, filterTypeId, filteredCompanyIds, filterTypeEventIds, filteredCompaniesEventIds) => {
        return axios.post(`${process.env.REACT_APP_API_URL}profile/settings`, {
            mailingLists: mailingListIds,
            filterType: filterTypeId,
            filteredCompanies: filteredCompanyIds,
            filterTypeEvents: filterTypeEventIds,
            filteredCompaniesEvents: filteredCompaniesEventIds
        });
    }
}

export default ProfileApi;
