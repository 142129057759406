import React from 'react';
import {Route, Switch} from "react-router";
import {ROUTE_FORGOT_PASSWORD, ROUTE_LOGIN, ROUTE_RESET_PASSWORD} from "../../config/routes";
import Login from "./login";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";
import LanguageToggle from "../../elements/LanguageToggle";

class Unauthorized extends React.Component {

    render() {
        return (
            <div className="Unauthorized">
                <LanguageToggle/>
                <Switch>
                    <Route exact path={ROUTE_FORGOT_PASSWORD} component={ForgotPassword}/>
                    <Route exact path={ROUTE_RESET_PASSWORD} component={ResetPassword}/>
                    <Route path={ROUTE_LOGIN} component={Login}/>
                </Switch>
            </div>
        )
    }
}

export default Unauthorized;
