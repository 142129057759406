import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import ContactWidgetLink from "../meetings/ContactWidgetLink";
import Publication from "./Publication";
import gfx_search from '../../../gfx/publications/search.svg';
import EntriesPerPageDropdown from "./EntriesPerPageDropdown";
import gfx_arrowLeft from '../../../gfx/publications/arrow_left.svg';
import gfx_arrowRight from '../../../gfx/publications/arrow_right.svg';
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import {useParams} from "react-router";
import Arrow from "../../../gfx/publications/Arrow";

const Publications = () => {
  const publicationsState = useSelector(state => state.publications);
  const profileState = useSelector(state => state.profile);

  const [publications, setPublications] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [didScrollToCompany, setDidScrollToCompany] = useState(false);
  const [charSelectOpen, setCharSelectOpen] = useState(false);

  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  let {companyId} = useParams();

  useEffect(() => {
    if (publicationsState.loaded && publicationsState.items) {
      new Promise((resolve) => {
        let publicationsToShow = publicationsState.items;
        publicationsToShow = publicationsToShow.sort((a, b) => a.company?.name?.localeCompare(b.company?.name));
        if (searchString && searchString.length > 0) publicationsToShow = publicationsToShow.filter(p => p.company?.name?.toLowerCase()?.includes(searchString?.toLowerCase()));
        resolve(publicationsToShow);
      }).then(publicationsToShow => {
        setPublications(publicationsToShow);
        window.scroll({
          left: 0,
          top: 0,
          behavior: 'smooth'
        });
      })
    }
  }, [publicationsState, searchString]);

  useEffect(() => {
    setCurrentPage(0);
  }, [entriesPerPage, searchString]);

  useEffect(() => {
    if (!companyId || didScrollToCompany) {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!didScrollToCompany && companyId && publications.length > 0) {
      setDidScrollToCompany(true);
      scrollToCompany(companyId);
    }
  }, [didScrollToCompany, companyId, publications])

  const scrollToChar = (char) => {
    let charIndex = publications.findIndex(p => char !== '#' ? p.company?.name?.substr(0, 1)?.toUpperCase() === char : numbers.includes(p.company?.name?.substr(0, 1)));
    if (charIndex > -1) {
      let charPage = Math.floor(charIndex / entriesPerPage);
      if (charPage !== currentPage) {
        setCurrentPage(charPage);
        setTimeout(() => doScrollToChar(char), 500);
      } else {
        doScrollToChar(char);
      }
    }
  }

  const scrollToCompany = (companyId) => {
    let companyIndex = publications.findIndex(p => p.company?.id === parseInt(companyId));
    if (companyIndex > -1) {
      let charPage = Math.floor(companyIndex / entriesPerPage);
      if (charPage !== currentPage) {
        setCurrentPage(charPage);
        setTimeout(() => doScrollToCompany(companyId), 500);
      } else {
        doScrollToCompany(companyId);
      }
    }
  }

  const doScrollToChar = (char) => {
    const element = document.getElementById(`char-${char}`);
    const topBar = document.getElementById('TopBar');
    const containerTopLine = document.getElementById('containerTopLine');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.scroll({
      left: 0,
      top: element.getBoundingClientRect().top + scrollTop - topBar.getBoundingClientRect().height - containerTopLine.getBoundingClientRect().height,
      behavior: 'smooth'
    });
  }

  const doScrollToCompany = (companyId) => {
    let element = document.getElementsByClassName(`company-${companyId}`);
    const topBar = document.getElementById('TopBar');
    const containerTopLine = document.getElementById('containerTopLine');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (element && element.length > 0) {
      element = element[0];
      window.scroll({
        left: 0,
        top: element.getBoundingClientRect().top + scrollTop - topBar.getBoundingClientRect().height - containerTopLine.getBoundingClientRect().height,
        behavior: 'smooth'
      });
    }
  }

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = ['#', ...alpha.map((x) => String.fromCharCode(x))];

  return (
    <div className="Publications">
      <ContactWidgetLink/>
      {profileState.loaded ? (
          profileState.views?.viewResearch ? (
              <Fragment>
                <div className="container-top-line container" id="containerTopLine">
                  <div className="row">
                    <div className="col-4 offset-2 offset-md-0 col-md-6 offset-lg-1 col-lg-4 pl-lg-0 d-md-flex text-right flex-md-wrap">
                      <div className="alphabet-container flex-grow-1 d-none d-md-block text-left">
                        <div className="alphabet d-inline-block">
                          {alphabet.map((char, cI) => (
                              <span
                                  className={`char${publications?.find(p => char !== '#' ? p.company?.name?.substr(0, 1)?.toLowerCase() === char?.toLowerCase() : numbers.includes(p.company?.name?.substr(0, 1))) ? ' selectable' : ''}`}
                                  key={cI}
                                  onClick={() => publications?.find(p => char !== '#' ? p.company?.name?.substr(0, 1)?.toLowerCase() === char?.toLowerCase() : numbers.includes(p.company?.name?.substr(0, 1))) ? scrollToChar(char) : null}>
                  {char}
                </span>
                          ))}
                        </div>
                      </div>
                      <div className="d-md-none position-relative">
                        <button className={`dropdown-toggle${charSelectOpen ? ' active' : ''}`} type="button" id="charSelectDropdownButton" data-toggle="dropdown" aria-expanded="false" onClick={() => setCharSelectOpen(!charSelectOpen)}>
                <span>
                  <Translate id="publication.charSelect"/>
                </span>
                          <Arrow/>
                        </button>
                        <div className={`dropdown-menu${charSelectOpen ? ' show' : ''}`} aria-labelledby="charSelectDropdownButton">
                          {alphabet.map((char, cI) => (
                              <span
                                  className={`char${publications?.find(p => char !== '#' ? p.company?.name?.substr(0, 1)?.toLowerCase() === char?.toLowerCase() : numbers.includes(p.company?.name?.substr(0, 1))) ? ' selectable' : ''}`}
                                  key={cI}
                                  onClick={() => {if (publications?.find(p => char !== '#' ? p.company?.name?.substr(0, 1)?.toLowerCase() === char?.toLowerCase() : numbers.includes(p.company?.name?.substr(0, 1))))  {setCharSelectOpen(false); scrollToChar(char);}}}>
                  {char}
                </span>
                          ))}
                        </div>
                      </div>
                      <input type="text" value={searchString} onChange={e => setSearchString(e.target.value)}
                             className="search-input" style={{backgroundImage: `url(${gfx_search})`}}/>
                    </div>
                  </div>
                </div>
                <div className="container-publications">
                  {publications.slice(currentPage * entriesPerPage, (currentPage + 1) * entriesPerPage).map((publication, pI) => (
                      <Publication key={currentPage * entriesPerPage + pI} publication={publication}
                                   hideChar={pI !== 0 && publications[currentPage * entriesPerPage + pI - 1]?.company?.name?.substr(0, 1)?.toLowerCase() === publication?.company?.name?.substr(0, 1)?.toLowerCase() || (numbers.includes(publications[currentPage * entriesPerPage + pI - 1]?.company?.name?.substr(0, 1)) && numbers.includes(publication?.company?.name?.substr(0, 1)))}/>
                  ))}
                </div>
                <div className="container-paginate container">
                  <div className="row">
                    <div className="col-6 col-lg-2 offset-lg-1 pl-lg-0">
                      <EntriesPerPageDropdown value={entriesPerPage} onChange={val => setEntriesPerPage(val)}/>
                    </div>
                    <div className="col-6 col-lg-2">
                      <div className="d-lg-none select-page-small">
                        <button className="page-btn page-btn-left" onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage <= 0}>
                          <img src={gfx_arrowLeft} alt=""/>
                        </button>
                        <Translate id="publication.page"/>: {currentPage + 1}
                        <button className="page-btn page-btn-right" onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage >= Math.ceil(publications?.length / entriesPerPage) - 1}>
                          <img src={gfx_arrowRight} alt=""/>
                        </button>
                      </div>
                      <div className="d-none d-lg-block select-page-big text-right">
                        <div className="pages-row d-flex">
                          <div className="pages-row-inner d-flex">
                            <span className="page-label"><Translate id="publication.page"/>:</span>
                            {publications?.length > 0 && Array.from(Array(Math.ceil(publications?.length / entriesPerPage)).keys()).map(page => (
                                <button key={page} className={page === currentPage ? 'active' : ''}
                                        onClick={() => setCurrentPage(page)}>
                                  <BorderWithDot borderClass="border-with-dot-darkturkuoise"/>
                                  {page + 1}
                                </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
          ) : (
              <div className="not-allowed container mt-5 mt-lg-0">
                <div className="row">
                  <div className="col-4 col-md-5 col-lg-4 offset-lg-1 pl-lg-0">
                    <h3 className="ml-1 mt-md-5">
                      <Translate id={`publication.notAllowed.title`}/>
                    </h3>
                    <p className="ml-1">
                      <Translate id={`publication.notAllowed.text`}/>
                    </p>
                  </div>
                </div>
              </div>
          )
      ): null}
    </div>
  )
}

export default Publications;
