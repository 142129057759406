import React, {Fragment, useState} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import moment from "moment-timezone";
import Select, { components } from 'react-select';
import gfx_search from '../../gfx/search-landing.svg';
import defaultTimezones from './timezones.json';


const EventInfo = ({data, extraClassName, activeLanguage}) => {

    const userTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timezoneObj = Object.entries(defaultTimezones).find(tz => tz[1] === userTimeZoneName);
    if (!timezoneObj) timezoneObj = Object.entries(defaultTimezones).find(tz => tz[1] === 'Europe/Berlin');
    const [selectedTimezone, setSelectedTimezone] = useState({value: userTimeZoneName, label: `(GMT${moment(data.date.start).tz(timezoneObj[1]).format('Z')}) ${timezoneObj[0].substring(timezoneObj[0].indexOf(')') + 1)}`});

    const splitTitle = (title) => {
        if (!title) return;
        let parts = [];
        let match;
        while ((match = /[^a-zA-Z0-9]/.exec(title)) !== null) {
            parts.push(title.substring(0, match.index + 1));
            title = title.substring(match.index + 1);
        }
        if (title?.length > 0) parts.push(title);
        return parts;
    }

    const titleParts = splitTitle(data?.title);

    return (
        <div className={`EventInfo text-center text-lg-left ${extraClassName}`}>
            <h2 className="mb-0">
                {data.company.name}
            </h2>
            <h3 className="mb-0">
                {titleParts?.map((str, sI) => (
                    <Fragment key={sI}>
                        <span className="h3-text">
                            <span dangerouslySetInnerHTML={{__html: str}}/>
                            {sI === titleParts.length - 1 && (
                                <span className={`event-language d-none d-lg-inline-block fi fi-${data.language === 'en' ? 'gb' : 'de'} fis`}/>
                            )}
                        </span>
                        {sI !== titleParts.length - 1 && str.length >= 1 && str?.substring(str.length - 1) === ' ' ? " " : ""}
                    </Fragment>
                ))}
            </h3>
            {data.language && (
                <span className={`event-language d-lg-none fi fi-${data.language === 'en' ? 'gb' : 'de'} fis`}/>
            )}
            <div className="date-info">
                <div className="date-day">
                    <span className="label">
                        <Translate id="landingPage.info.date.label"/>:&nbsp;
                    </span>
                    {moment(data.date.start, null, activeLanguage?.code).tz(selectedTimezone?.value ? selectedTimezone?.value : selectedTimezone).format(activeLanguage?.code === "en" ? "MMMM Do, YYYY" : "DD. MMMM YYYY")}
                </div>
                <div className="date-time">
                    <span className="label">
                        <Translate id="landingPage.info.time.label"/>:&nbsp;
                    </span>
                    {moment(data.date.start).tz(selectedTimezone?.value ? selectedTimezone?.value : selectedTimezone).format(activeLanguage?.code === "en" ? "hh:mm A" : "HH:mm")}&nbsp;
                    <Translate id="landingPage.info.time.text1"/>&nbsp;
                    {moment(data.date.end).tz(selectedTimezone?.value ? selectedTimezone?.value : selectedTimezone).format(activeLanguage?.code === "en" ? "hh:mm A" : "HH:mm")}&nbsp;
                    <Translate id="landingPage.info.time.text2"/>
                </div>
                <Select
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    className="timezone-select"
                    classNamePrefix="select"
                    options={Object.entries(defaultTimezones).sort((a, b) => moment(data.date.start).tz(a[1]).utcOffset() - moment(data.date.start).tz(b[1]).utcOffset())?.map((timezone) => ({
                        value: timezone[1],
                        label: `(GMT${moment(data.date.start).tz(timezone[1]).format('Z')}) ${timezone[0].substring(timezone[0].indexOf(')') + 1)}`
                    }))}
                    components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: (props) => (
                            <components.DropdownIndicator {...props}>
                                <img src={gfx_search} alt=""/>
                            </components.DropdownIndicator>
                        )
                    }}
                    />
            </div>
        </div>
    )
}

export default withLocalize(EventInfo);