import React from 'react';

const Hand = () => (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 122.2 72.2" style={{enableBackground: 'new 0 0 122.2 72.2'}}>
<g>
	<path fill="currentColor" d="M36.1,0C16.2,0,0,16.2,0,36.1v0c0,19.9,16.2,36.1,36.1,36.1c19.9,0,36.1-16.2,36.1-36.1v0
		C72.2,16.2,56.1,0,36.1,0z"/>
	<g>
		<path fill="currentColor" d="M75.1,22.9h-5.4c1.6,4.1,2.5,8.5,2.5,13.2v0c0,3.9-0.7,7.7-1.8,11.3h4.6v-8.5h8.3v-7.5h-8.3V22.9z"/>
	</g>
	<g>
		<path fill="#ffffff" d="M67.4,31.4h-8.2v7.5h8.2v8.5h3.1c1.2-3.5,1.8-7.3,1.8-11.3v0c0-4.7-0.9-9.1-2.5-13.2h-2.3V31.4z"/>
		<path fill="#ffffff" d="M41.4,63.8h11.7c-1.3-4.9-4.2-9-9.1-10.2c6.8-2.9,10.5-9.5,10.5-18.5c0-12.1-6.3-20.2-18.5-20.2
			c-12.4,0-19.1,8.1-19.1,20.2c0,12.3,6,20.1,18.4,20.1c0.8,0,1.5-0.1,2.2-0.1C40,56.4,41.2,60,41.4,63.8z M27,35.2V35
			c0-8.5,3.2-12.5,8.8-12.5c5.5,0,8.3,4.1,8.3,12.5v0.2c0,8.6-2.9,12.5-8.4,12.5C30,47.7,27,43.5,27,35.2z"/>
	</g>
	<path fill="currentColor" d="M110.1,16.4H97.1L84.8,54.5h9.9l2.5-8.6h12.4l2.5,8.6h10.1L110.1,16.4z M99.1,38.6l3.1-11.7l1-5.4h0.5l0.9,5.4
		l3,11.7H99.1z"/>
</g>
</svg>
);

export default Hand;