import React, {useState} from 'react';
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {
    profileNotificationSettingsMailingListAddActive,
    profileNotificationSettingsMailingListRemoveActive
} from "../../../store/profile/profileAction";
import ProfileApi from "../../../api/ProfileApi";

const NotificationSettingsMailingLists = () => {
  const profileState = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const [showSuccessText, setShowSuccessText] = useState(false);
  const [showSuccessTextTimeout, setShowSuccessTextTimeout] = useState(null);

  const toggleMailingList = (ml) => {
      if (!!profileState.mailingLists?.active?.find(aml => aml.id === ml.id)) {
        updateApi(profileState.mailingLists.active?.filter(aml => aml.id !== ml.id)?.map(aml => aml.id));
        dispatch(profileNotificationSettingsMailingListRemoveActive(ml));
      } else {
        updateApi([...profileState.mailingLists.active?.map(aml => aml.id), ml.id]);
        dispatch(profileNotificationSettingsMailingListAddActive(ml));
      }
  }

  const updateApi = (activeMailingLists) => {
    ProfileApi.updateNotificationSettings(activeMailingLists, profileState.companyFilters.filterTypes.active?.map(ft => ft.id), profileState.companyFilters.companies.active?.map(c => c.id), profileState.companyFiltersEvents.filterTypes.active?.map(ft => ft.id), profileState.companyFiltersEvents.companies.active?.map(c => c.id))
      .then(response => {
        setShowSuccessText(true);
        if (showSuccessTextTimeout) clearTimeout(showSuccessTextTimeout);
        let newShowSuccessTextTimeout = setTimeout(() => setShowSuccessText(false), 4000);
        setShowSuccessTextTimeout(newShowSuccessTextTimeout);
      })
  }

  return (
    <div className="mailingLists">
      <h2>
        <Translate id="profile.notificationSettings.dialog.mailingLists.title" options={{renderInnerHtml: true}}/>
      </h2>
      <p className={`successText${!showSuccessText ? ' hidden' : ''}`}>
        <Translate id="profile.notificationSettings.dialog.mailingLists.successText"/>
      </p>
      <table>
        <tbody>
        {profileState.mailingLists?.available?.map((ml, mlI) => (
          <tr key={mlI}>
            <td className="col-checkbox">
              <input type="checkbox" id={`ml-${ml.id}-cb`}
                     checked={!!profileState.mailingLists?.active?.find(aml => aml.id === ml.id)}
                     onChange={() => toggleMailingList(ml)}/>
              <label htmlFor={`ml-${ml.id}-cb`}>
                <span className="off">
                  <Translate id="profile.notificationSettings.dialog.checkbox.off"/>
                </span>
                <span className="on">
                  <Translate id="profile.notificationSettings.dialog.checkbox.on"/>
                </span>
              </label>
            </td>
            <td className="col-text">
              {ml.title}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
};

export default NotificationSettingsMailingLists;
