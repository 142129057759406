import React, {Fragment} from 'react';
import MeetingsNavigation from "./MeetingsNavigation";
import MeetingsTextSearch from "./MeetingsTextSearch";
import MeetingsPaginateRangeSelect from "./MeetingsPaginateRangeSelect";
import {connect} from "react-redux";
import Meeting from "./Meeting";
import moment from 'moment';
import {Translate} from "react-localize-redux";
import BorderWithDot from "../../../elements/border/borderWithDot";
import iconArrowRight from "../../../gfx/arrow-right.svg"
import iconArrowLeft from "../../../gfx/arrow-left.svg"
import ContactWidgetLink from "./ContactWidgetLink";
import {withRouter} from "react-router";
import {eventsSetInvitation} from "../../../store/events/eventsAction";
import Invitation from "./Invitation";
import NewPublicationsTicker from "./NewPublicationsTicker";
import EventBus from "../../../elements/EventBus";

class Meetings extends React.Component {
    state = {
        textSearchIsOpen: false,
        filterType: null,
        textSearch: '',
        rangeSelect: 25,
        pageOffset: 0,
        showInvitation: null
    };

    componentDidMount() {
        const itemId = this.props.match.params.itemId;
        if (itemId) {
            const event = this.props.events.items.find(i => i.itemId === parseInt(itemId));
            if (event) {
                this.setState({textSearch: event.title});
            }
        }
        EventBus.on('brandClick', () => {
            this.onRouteChanged();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged();
        }
        if (this.props.events.invitation && this.props.events.invitation.eventFilterText !== this.state.textSearch) {
            this.setState({
                textSearch: this.props.events.invitation.eventFilterText,
                showInvitation: this.props.events.invitation
            });
            this.props.eventsSetInvitation(null);
        }
    }

    onRouteChanged = () => {
        this.setState({
            textSearchIsOpen: false,
            filterType: null,
            textSearch: '',
            pageOffset: 0,
            showInvitation: null
        });
    }

    onChangeTextSearch = newTextSearchIsOpen => {
        this.setState({textSearchIsOpen: newTextSearchIsOpen, pageOffset: 0});
    };

    onChangeFilter = filterType => {
        this.setState({filterType: filterType, pageOffset: 0});
    };

    prepareEventItems = items => {
        let {schedule} = this.props;
        let {filterType, textSearch} = this.state;
        // filter if schedule
        if (schedule) items = items.filter(item => item.userIsParticipating || item.userIsApplying || (item.userIsDenied && item.alternativeSlots && item.alternativeSlots.length > 0))
        // sort by date asc
        items = items.sort((a, b) => new Date(a.date.start) - new Date(b.date.start));
        // filter by type
        if (filterType && filterType !== 'all') items = items.filter(item => item.type === filterType);
        // filter by text search
        if (textSearch && textSearch.length > 0) items = items.filter(item => !textSearch.split(" ").find(textSearchItem => !`${item.type}${item.title}${item.company ? item.company.name : ''}`.toLowerCase().includes(textSearchItem.toLowerCase())));
        // return items
        return items;
    };

    render() {
        const {events, schedule, profile} = this.props;
        let eventItems = this.prepareEventItems(events.items);

        return (
            <div className="Meetings">
                <ContactWidgetLink/>
                {profile.views ? (
                    profile.views.viewEvents ? (
                        <Fragment>
                            <NewPublicationsTicker/>
                            <div className={`${this.state.textSearchIsOpen ? 'hide' : ''} container container-meetingsNavigation`}>
                                <div className="row">
                                    <div className="col-6 col-lg-5 offset-lg-1 pl-lg-0">
                                        <MeetingsNavigation onChange={(type) => this.onChangeFilter(type)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="container container-filter-row-top">
                                <div className="row">
                                    <div className="col-6 col-lg-1 offset-lg-1 pl-lg-0 filter-row-top">
                                        <MeetingsTextSearch textSearchIsOpen={this.state.textSearchIsOpen}
                                                            onNameChange={this.onChangeTextSearch}
                                                            value={this.state.textSearch}
                                                            onTextSearch={val => this.setState({textSearch: val, pageOffset: 0})}/>
                                    </div>
                                    <div className="col-6 col-lg-3 justify-content-end d-none d-lg-flex">
                                        <MeetingsPaginateRangeSelect value={this.state.rangeSelect} onChange={(rangeSelect) => this.setState({rangeSelect, pageOffset: 0})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="container-meetings">
                                { events.loaded && (
                                    eventItems.length > 0 ? (
                                        eventItems.slice(this.state.pageOffset * this.state.rangeSelect, this.state.rangeSelect === 0 ? eventItems.length : (this.state.pageOffset + 1) * this.state.rangeSelect).map((event, eI) => (
                                            <Meeting event={event} key={`${event.appId}${event.itemId}`}
                                                     hideDay={eI !== 0 && moment(eventItems[(this.state.pageOffset * this.state.rangeSelect + eI) - 1].date.start).format('YYYYMMDD') === moment(event.date.start).format('YYYYMMDD')}/>
                                        ))
                                    ) : (
                                      <div className="no-meetings container">
                                          <div className="row">
                                              <div className="col-4 col-md-5 col-lg-4 offset-lg-1 pl-lg-0">
                                                  <h4 className="ml-1">
                                                      <Translate id={`meetings.noMeetings.${schedule ? 'schedule.' : ''}${this.state.filterType ? this.state.filterType : 'all'}`}/>
                                                  </h4>
                                              </div>
                                          </div>
                                      </div>
                                    )
                                  )
                                }
                                {this.state.textSearch ? (
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-6 col-lg-4 offset-lg-1 pl-lg-0 text-center text-lg-left">
                                                <button type="button" className="btn btn-link btn-reset-textSearch" onClick={() => { this.setState({textSearch: '', pageOffset: 0}); window.scrollTo(0, 0);}}>
                                                    <Translate id="meetings.resetTextSearch"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {this.state.showInvitation && <Invitation invitation={this.state.showInvitation}/>}
                            {eventItems && eventItems.length > 0 ? (
                                <div className="container-bottom container">
                                    <div className="row">
                                        <div className="col-6 col-lg-4 offset-lg-1 pl-lg-0 d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
                                            <MeetingsPaginateRangeSelect value={this.state.rangeSelect} onChange={(rangeSelect) => this.setState({rangeSelect, pageOffset: 0})}/>
                                            <div className="pager">
                                                <div className="btn-pager">
                                                    <button onClick={() => {window.scrollTo(0, 0); this.setState({pageOffset: this.state.pageOffset-1})}} className={`btn-prev d-lg-none ${this.state.pageOffset === 0 ? 'd-none' : 'd-inline-block'}`}>
                                                        <img src={iconArrowLeft} alt=""/></button>
                                                </div>
                                                <div className="pager-helper">
                                                    <Translate id="meetings.page"/>:
                                                </div>
                                                {Array.from(Array(Math.ceil(eventItems.length / (this.state.rangeSelect === 0 ? eventItems.length: this.state.rangeSelect))).keys()).map(pI => (
                                                    <button key={pI} onClick={() => {window.scrollTo(0, 0); this.setState({pageOffset: pI})}} className={`${this.state.pageOffset === pI ? 'active' : ''}`}>
                                                        {pI + 1}
                                                    </button>
                                                ))}
                                                <div className="line d-none d-lg-inline-block" style={{width: 78 + 22 * this.state.pageOffset}}>
                                                    <BorderWithDot/>
                                                </div>
                                                <div className="btn-pager">
                                                    <button onClick={() => {window.scrollTo(0, 0); this.setState({pageOffset: this.state.pageOffset+1})}} className="btn-next d-inline-block d-lg-none">
                                                        <img src={iconArrowRight} alt=""/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </Fragment>
                    ) : (
                        <div className="not-allowed container mt-5 mt-lg-0">
                            <div className="row">
                                <div className="col-4 col-md-5 col-lg-4 offset-lg-1 pl-lg-0">
                                    <h3 className="ml-1 mt-md-5">
                                        <Translate id={`meetings.notAllowed.title`}/>
                                    </h3>
                                    <p className="ml-1">
                                        <Translate id={`meetings.notAllowed.text`}/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    events: state.events,
    profile: state.profile,
});

export default connect(mapStateToProps, {
    eventsSetInvitation
})(withRouter(Meetings));
