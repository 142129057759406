import {EVENTS_LOAD_SUCCESS, EVENTS_SET_INVITATION} from "../../config/actions";

let defaultState = {
    loaded: false,
    items: [],
    invitation: null
};

export default (state = defaultState, action) => {
    switch(action.type) {
        case EVENTS_LOAD_SUCCESS:
            return {
                ...state,
                loaded: true,
                items: action.items
            };
        case EVENTS_SET_INVITATION:
            return {
                ...state,
                invitation: action.invitation
            };
        default:
            return state;
    }
}
