import React from 'react';

const PublicationsTickerArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.603" height="12.416" viewBox="0 0 17.603 12.416">
      <g id="Gruppe_3973" data-name="Gruppe 3973" transform="translate(17.603) rotate(90)">
        <path id="Pfad_1418" data-name="Pfad 1418" d="M3.615,17.6,0,13.988,5.186,8.8,0,3.615,3.615,0l8.8,8.8Z" fill="currentColor"/>
      </g>
    </svg>
  )
}

export default PublicationsTickerArrow;