export const AUTHORIZATION_LOGIN_SUCCESS = 'AUTHORIZATION_LOGIN_SUCCESS';
export const AUTHORIZATION_LOGOUT = 'AUTHORIZATION_LOGOUT';
export const AUTHORIZATION_SET_ACCESS_TOKEN = 'AUTHORIZATION_SET_ACCESS_TOKEN';

export const EVENTS_LOAD_SUCCESS = 'EVENTS_LOAD_SUCCESS';
export const EVENTS_SET_INVITATION = 'EVENTS_SET_INVITATION';

export const PROFILE_LOAD_SUCCESS = 'PROFILE_LOAD_SUCCESS';
export const PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_ADD_ACTIVE = 'PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_ADD_ACTIVE';
export const PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_REMOVE_ACTIVE = 'PROFILE_NOTIFICATION_SETTINGS_MAILING_LIST_REMOVE_ACTIVE';
export const PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_ADD_ACTIVE = 'PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_ADD_ACTIVE';
export const PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_REMOVE_ACTIVE = 'PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_REMOVE_ACTIVE';
export const PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_SET_FILTER_TYPE = 'PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_SET_FILTER_TYPE';
export const PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_ADD_ACTIVE = 'PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_ADD_ACTIVE';
export const PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_REMOVE_ACTIVE = 'PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANY_EVENTS_REMOVE_ACTIVE';
export const PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_EVENTS_SET_FILTER_TYPE = 'PROFILE_NOTIFICATION_SETTINGS_FILTERED_COMPANIES_EVENTS_SET_FILTER_TYPE';

export const PUBLICATIONS_LOAD_SUCCESS = 'PUBLICATIONS_LOAD_SUCCESS';
export const PUBLICATIONS_TICKER_LOAD_SUCCESS = 'PUBLICATIONS_TICKER_LOAD_SUCCESS';
