import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Collapse} from "react-collapse/lib/Collapse";
import {Translate} from "react-localize-redux";
import moment from "moment";
import {generatePath, Link} from "react-router-dom";
import {ROUTE_PUBLICATIONS_DIRECT_ITEM} from "../../../config/routes";
import PublicationsTickerArrow from "../../../gfx/PublicationsTickerArrow";

const NewPublicationsTicker = () => {
  const publicationsTickerState = useSelector(state => state.publicationsTicker)
  const [recentPublicationItems, setRecentPublicationTickerItems] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let latestPublications = publicationsTickerState.items;
    latestPublications = latestPublications?.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
    setRecentPublicationTickerItems(latestPublications);
  }, [publicationsTickerState.items]);

  /*
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return function cleanup() {
      document.body.style.overflow = '';
    }
  })
   */

  const renderPublication = (publicationItem, index) => {
    return (
      <div className={`publication-item ${!open ? (index === 0 ? 'd-none d-lg-block' : 'd-none') : ''}`} key={index}>
        <div className="date">
          {moment(publicationItem.releaseDate).format('LLL')}
        </div>
        <div>
            <div className="text">
                <div>
                    <strong>{publicationItem.company.name}</strong>
                </div>
                <div>
                    {publicationItem.title}
                </div>
                <div>
                    <Link to={generatePath(ROUTE_PUBLICATIONS_DIRECT_ITEM, {companyId: publicationItem.company.id, publicationItemId: publicationItem.itemId})}>
                        <Translate id="publicationTicker.view"/>
                    </Link>
                </div>
            </div>
        </div>
      </div>
    )
  }

  return recentPublicationItems && recentPublicationItems.length > 0 ? (
    <div className={`NewPublicationsTicker${open ? ' open' : ''}${recentPublicationItems.length < 2 ? ' only-one-element' : ''}`}>
      <Collapse isOpened={true}>
        <div>
          <div className={`publication-items ${open ? 'd-block' : 'd-none d-lg-block'}`}>
            <h5>
              <Translate id="publicationTicker.newPublications"/>
            </h5>
            {recentPublicationItems.map((publicationItem, piI) => (
              renderPublication(publicationItem, piI)
            ))}
          </div>
        </div>
      </Collapse>
      <button onClick={() => setOpen(open => !open)} type="button" className={recentPublicationItems.length < 2 ? 'd-lg-none' : ''}>
        {!open ? (
          <Fragment>
            <span className="d-lg-none">
              <Translate id="publicationTicker.button.new"/>
            </span>
            <span className="d-none d-lg-inline-block">
              <Translate id="publicationTicker.button.showMore"/>
            </span>
          </Fragment>
        ) : (
          <span>
            <Translate id="publicationTicker.button.showLess"/>
          </span>
        )}
        <PublicationsTickerArrow/>
      </button>
    </div>
  ) : null;
}

export default NewPublicationsTicker;
