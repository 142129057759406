import React from 'react';
import {Translate} from "react-localize-redux";
import TextInput from "../../../elements/form/TextInput";
import iconSearch from "../../../gfx/search.svg";
import iconSearchMobile from "../../../gfx/search_mobile.svg";
import {withRouter} from "react-router";

class MeetingsTextSearch extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged();
        }
    }

    onRouteChanged = () => {
        this.props.onTextSearch('');
    }

    setValue = (e) => {
        let value = e.target.value;
        this.props.onTextSearch(value);
    };

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <div className={`MeetingsTextSearch ${this.props.textSearchIsOpen ? 'show' : ''}`}>
                        <button onClick={() => this.props.onNameChange(!this.props.textSearchIsOpen)} className="d-flex justify-content-center align-items-center">
                            <img alt="" className="logo logo-connect d-md-none" src={iconSearchMobile}/>
                            <img alt="" className="logo logo-connect d-none d-md-inline-block" src={iconSearch}/>
                        </button>
                        <TextInput placeholder={translate("meetings.textSearch.placeholder")} value={this.props.value}
                                   onChange={this.setValue}/>
                    </div>
                )}
            </Translate>
        )
    }
}

export default withRouter(MeetingsTextSearch);
